import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import { db, app } from '../firebase-config';
import { getCheckoutUrl } from '../Integrations/GetCheckoutUrl';
import '../styles/openingsShop.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import KnightHopLoadingScreen from '../Integrations/LoadingScreen';

const TrainChessOpenings = () => {
  const [openings, setOpenings] = useState([]);
  const [redemptionCode, setRedemptionCode] = useState("");
  const { user } = useUser();
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
  if (user) {
    setShowLoginMessage(false); // Hide the login message if the user is logged in
  } else {
    setShowLoginMessage(true);  // Show the login message if no user is logged in
  }
}, [user]);

  const handleCloseMessage = () => {
    setShowLoginMessage(false);
  };

  useEffect(() => {
    fetchOpenings();
  }, []);

  const fetchOpenings = () => {
    const manualOpenings = [
      {
        id: "Thes0PKingaPoss0PGambit",
        title: "The U1000 Elo King's Gambit Training Repertoire",
        price: "$15",
        priceId: "price_1QLybUCWdD1hILCS4OkMeYcv",
        description: (
          <>
            <p>
              Master the King's Gambit with this specially designed repertoire for players under 1000 ELO. This course features <strong>100+ carefully selected lines</strong>, chosen based on their frequency and win rate in over 10 million U1000 games.
            </p>
            <p>
              Proven to be an underexploited and top-performing opening at this level, the King's Gambit boasts an impressive <strong>54% win rate</strong>. This well researched repertoire will give you an early advantage. Explore the companion video series{" "}
              <a href="https://youtube.com/playlist?list=PLLrj4qIWzATi6zdREtY0mHnpU_75bWWXV&si=sa2W_e-kswSYn0Pk" target="_blank" rel="noopener noreferrer">
                here
              </a> for a tour of the opening.
            </p>
          </>
        ),
         imageUrl: process.env.PUBLIC_URL + "/openings/theKingsGambit.png",
      }
    ];
    setOpenings(manualOpenings);
  };

const validateOpeningAccess = async (openingId, openingTitle) => {
  if (!user) {
    console.error("Validation failed: user not logged in.");
    throw new Error("You need to log in to access openings.");
  }

  // Log the openingId and openingTitle being validated
  console.log("Validating access for openingId:", openingId, "with title:", openingTitle);

  try {
    // Step 1: Check if access is already recorded in purchasedItems
    const purchasedItemsRef = doc(db, "users", user.uid, "purchasedItems", openingId);
    const purchasedItemsSnap = await getDoc(purchasedItemsRef);

    if (purchasedItemsSnap.exists() && purchasedItemsSnap.data().hasAccess) {
      console.log("Access validated: existing record found in purchasedItems.");
      return true;
    } else {
      console.log("No existing access record found in purchasedItems for this opening.");
    }

    // Step 2: Query payments for any item that matches the opening title in description
    console.log("Querying payments collection for purchase of:", openingTitle);
    const paymentsRef = collection(db, "users", user.uid, "payments");
    const paymentsQuery = query(paymentsRef);
    const querySnapshot = await getDocs(paymentsQuery);

    let accessGranted = false;

    if (querySnapshot.empty) {
      console.warn("No documents found in payments collection for this user.");
    } else {
      console.log(`Found ${querySnapshot.size} documents in payments collection.`);
    }

    // Step 3: Iterate through payments to find any matching item
    querySnapshot.forEach((doc) => {
      console.log(`Inspecting document ID: ${doc.id} in payments collection.`);
      const paymentData = doc.data();
      console.log("Payment data:", paymentData);

      const items = paymentData.items || [];
      console.log(`Found ${items.length} items in this payment document.`);

      items.forEach((item) => {
        console.log("Inspecting item:", item);
        if (item.description === openingTitle) {
          console.log("Matching payment found for item:", item);
          accessGranted = true;

          // Step 4: Write to purchasedItems to record access
          setDoc(purchasedItemsRef, { hasAccess: true }, { merge: true })
            .then(() => console.log("Access recorded in purchasedItems for:", openingId))
            .catch((error) => console.error("Error writing to purchasedItems:", error));
        }
      });
    });

    if (accessGranted) {
      return true;
    }

    console.warn("Validation complete: no access granted.");
    return false;

  } catch (error) {
    console.error("Error during validation:", error);
    throw new Error("Error validating access for opening.");
  }
};


const handleCopyOpening = async (opening) => {
  console.log("Handling copy opening for:", opening);
  try {
    const hasAccess = await validateOpeningAccess(opening.id, opening.title);
    if (hasAccess) {
      await copyOpening(opening);
    } else {
      alert('You need to purchase access to copy this opening.');
      console.warn("User does not have access to copy this opening.");
    }
  } catch (error) {
    console.error("Error in handleCopyOpening:", error);
  }
};

const initiateCheckout = async (priceId, firestoreCheckId) => {
  try {
    setIsLoading(true);

    const userDocRef = doc(db, "users", user.uid, "purchasedItems", firestoreCheckId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const data = userDocSnap.data(); // Get the document's data
      if (data.hasAccess) {
        alert("You already own this item!");
        setIsLoading(false);
        return; // Exit if the user already owns the item
      }
    }

    // Proceed to initiate the checkout process
    const checkoutData = {
      price: priceId,
      mode: "payment",
      quantity: 1,
      success_url: window.location.origin + "/openingtrainer/browse",
      cancel_url: window.location.origin + "/openingtrainer/browse",
    };

    const checkoutUrl = await getCheckoutUrl(app, checkoutData);
    window.location.href = checkoutUrl;
  } catch (error) {
    console.error("Error initiating checkout:", error.message);
    alert("Unable to initiate checkout. Please try again later.");
  } finally {
    setIsLoading(false);
  }
};

const copyOpening = async (opening) => {
  if (!user) {
    alert('Please log in to copy repertoires.');
    console.warn("User not logged in, aborting copy.");
    return;
  }

  try {
    const openingRef = doc(db, 'trainChessOpenings', opening.id);
    const openingSnap = await getDoc(openingRef);

    if (!openingSnap.exists()) {
      alert('Opening data not found.');
      console.warn("Opening data not found in trainChessOpenings.");
      return;
    }

    const openingData = openingSnap.data();

    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);

    const userMoveTrainer = userDocSnap.exists() ? userDocSnap.data().MoveTrainer || {} : {};
    const newFolderName = opening.id || 'Copied Opening';

    // Check if folder already exists and confirm overwrite
    if (userMoveTrainer.hasOwnProperty(newFolderName)) {
      const overwrite = window.confirm(`A folder named "${newFolderName}" already exists. Do you want to overwrite it?`);
      if (!overwrite) return;
    }

    // Copy the entire opening data (with nested structure) into the user's MoveTrainer
    userMoveTrainer[newFolderName] = openingData;

    // Save the updated MoveTrainer data to Firestore
    await setDoc(userDocRef, { MoveTrainer: userMoveTrainer }, { merge: true });
    alert('Opening copied successfully.');
  } catch (error) {
    console.error('Error copying opening:', error);
    alert('Failed to copy opening. Please try again.');
  }
};

  const redeemCode = async () => {
    if (!user) {
      alert("You need to log in to redeem a code.");
      return;
    }

    if (redemptionCode === "ILOVEe4f4") { // Replace with your actual validation logic
      const purchasedItemsRef = doc(db, "users", user.uid, "purchasedItems", "Thes0PKingaPoss0PGambit");
      try {
        await setDoc(purchasedItemsRef, { hasAccess: true });
        alert("Redemption successful! You now have access to the King's Gambit Opening.");
      } catch (error) {
        console.error("Error redeeming code:", error);
        alert("Failed to redeem code. Please try again.");
      }
    } else {
      alert("Invalid redemption code.");
    }
  };

  return (
    <div className="browse-openings-wrapper">
      <EnableScrollOnPage />
      {isLoading && <KnightHopLoadingScreen />}
      {showLoginMessage && (
        <div className="login-message">
          <button className="close-button" onClick={handleCloseMessage}>×</button>
          <p><a href="/login">Log in</a> to purchase openings.</p>
        </div>
      )}
      <h2>"Done For You" Training Lines</h2>
      <p>Skip the hassle of entering lines in yourself and use one of our ready to go training courses.</p>
      <div className="openings-list">
        {openings.map((opening) => (
          <FlipCard
            key={opening.id}
            opening={opening}
            onCopy={() => handleCopyOpening(opening)}
            onPurchase={() =>
              initiateCheckout(opening.priceId, opening.id)
            }
          />
        ))}
      </div>
      <p>More Coming Soon...</p>
        <div className="redemption-bar">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submission behavior
              redeemCode(); // Call the redeemCode function
            }}
          >
            <input
              type="text"
              placeholder="Enter redemption code"
              value={redemptionCode}
              onChange={(e) => setRedemptionCode(e.target.value)}
              className="redemption-input"
            />
            <button type="submit" className="average-button">
              Redeem
            </button>
          </form>
        </div>
    </div>
  );
};

const FlipCard = ({ opening, onCopy, onPurchase }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

return (
  <div className={`opening-card ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
    <div className="front">
      <img src={opening.imageUrl} alt={`${opening.title} Thumbnail`} className="opening-image" />
    </div>
    <div className="back">
      <div className="price-tag">{opening.price}</div>
      <h3>{opening.title}</h3>
      <p>{opening.description}</p>
      <div className="steps">
        <h4> How to Use:</h4>
        <ol>
          <li>
            <button onClick={(e) => { e.stopPropagation(); onPurchase(); }} className="average-button">
              <strong>Step 1:</strong> Purchase
            </button>
          </li>
          <li>
            <button onClick={(e) => { e.stopPropagation(); onCopy(); }} className="average-button">
              <strong>Step 2:</strong> Copy to My Repertoire
            </button>
          </li>
          <li>
            <button
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = `${window.location.origin}/openingtrainer`;
              }}
              className="average-button"
            >
              <strong>Step 3:</strong> Train in Opening Trainer
            </button>
          </li>
        </ol>
      </div>
    </div>
  </div>
);
};

export default TrainChessOpenings;
