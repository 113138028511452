import React, { Component } from 'react';
import Chessboard from 'chessboardjsx';
import '../styles/styles.css';
import '../styles/chessboard.css';

const isValidSquare = (square) => {
  return /^[a-h][1-8]$/.test(square);
};


class ChessboardCustom extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      isPeeking: false,
      isExtraButtonsVisible: false,
      drawnArrows: [],
      isDrawMode: false,
      eraserSelected: false,
      selectedColor: '',
      blockBoard: this.props.degreeOfBlindness === 'Blindfold',
      allWhite: false,
      drawButton: this.props.drawButton ?? true,
      dragStart: null,
      dragEnd: null,
      chessboardSize: this.calculateChessboardSize(window.innerWidth, window.innerHeight),
    };
      this.handleMouseDown = this.handleMouseDown.bind(this);
      this.handleMouseMove = this.handleMouseMove.bind(this);
      this.handleMouseUp = this.handleMouseUp.bind(this);
      this.toggleExtraButtonsVisibility = this.toggleExtraButtonsVisibility.bind(this);
  }

componentDidMount() {
    const highlightColor = getComputedStyle(document.documentElement)
                          .getPropertyValue('--highlight-color').trim() || '#000000';
    this.setState({ selectedColor: highlightColor });

    const updateSize = this.props.updateChessboardSize || (() => {});
    updateSize(this.state.chessboardSize);
    this.setupCanvas();

    const chessboardElement = document.querySelector('.chessboard-container');

    if (chessboardElement) {
        chessboardElement.addEventListener('mousedown', this.handleMouseDown);
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: false }); // Prevent scrolling
        document.addEventListener('touchmove', this.handleTouchMove.bind(this), { passive: false });
        document.addEventListener('touchend', this.handleTouchEnd.bind(this));
    }
}


componentDidUpdate(prevProps, prevState) {

  if (this.state.isDrawMode && !prevState.isDrawMode) {
    // Entering draw mode
    document.body.classList.add("no-scroll");
  } else if (!this.state.isDrawMode && prevState.isDrawMode) {
    // Exiting draw mode
    document.body.classList.remove("no-scroll");
  }
  // Check if chessboard size has changed
  if (prevState.chessboardSize !== this.state.chessboardSize) {
    this.setupCanvas(); // Re-setup the canvas with new dimensions
    this.props.updateChessboardSize(this.state.chessboardSize);
  }

  // Check if the showQuiz prop has updated
  if (prevProps.showQuiz !== this.props.showQuiz) {
    this.clearArrows(); // Clear the arrows if the showQuiz state has changed
  }
  // Check if the showQuiz prop has updated
  if (prevProps.position !== this.props.position) {
    this.clearArrows(); // Clear the arrows if the showQuiz state has changed
  }
}


componentWillUnmount() {
    const chessboardElement = document.querySelector('.chessboard-container');

    if (chessboardElement) {
        chessboardElement.removeEventListener('mousedown', this.handleMouseDown);
    }

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('touchstart', this.handleTouchStart.bind(this), { passive: false });
    document.removeEventListener('touchmove', this.handleTouchMove.bind(this), { passive: false });
    document.removeEventListener('touchend', this.handleTouchEnd.bind(this));
}


    toggleExtraButtonsVisibility() {
        console.log('extra buttons? ' + this.state.isExtraButtonsVisible)
        this.setState(prevState => ({
            isExtraButtonsVisible: !prevState.isExtraButtonsVisible
        }))};

toggleDrawMode = () => {
  this.setState(prevState => ({
    drawMode: prevState.drawMode === 'right' ? 'left' : 'right',
  }));
};

selectColor = (color) => {
  this.setState({
    selectedColor: color,
    eraserSelected: false, // deselect eraser when a color is chosen
  });
};

toggleEraser = () => {
  this.setState(prevState => ({
    eraserSelected: !prevState.eraserSelected,
    // Optionally reset color when eraser is selected
  }));
};

renderOptionsMenu() {

   if (!this.state.isDrawMode) return null;

    const predefinedColors = [
      { name: 'Green', value: 'rgba(20, 163, 0, 0.8)' },
      { name: 'Red', value: 'rgba(205, 0, 0, 0.8)' },
      { name: 'Blue', value: 'rgba(0, 0, 205, 0.8)' },
      { name: 'Yellow', value: 'rgba(205, 176, 0, 0.9)' },
    ];

  return (
    <div className="options-menu" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <button
      onClick={this.toggleEraser}
      style={{
        alignSelf: 'stretch',
        backgroundColor: this.state.eraserSelected ? 'var(--highlight-color)' : '', // Use default or specific color when not selected
        color: this.state.eraserSelected ? '#fff' : '', // Change text color for better visibility if needed
      }}
    >
      Erase
    </button>
      <button onClick={this.clearArrows} style={{ alignSelf: 'stretch' }}>
        Clear
      </button>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {predefinedColors.map(color => (
          <button
            key={color.name}
            style={{
              backgroundColor: color.value,
              width: '20px',
              height: '20px',
              border: 'none',
              cursor: 'pointer'
            }}
            onClick={() => this.setState({ selectedColor: color.value })}
            title={color.name} // Adding a title for accessibility
          >
          </button>
        ))}
      </div>
        {window.innerWidth > 768 && (
          <input
            type="color"
            value={this.state.selectedColor}
            onChange={(e) => this.setState({ selectedColor: e.target.value })}
            style={{
              gridColumn: '2 / 4',  // Spans the last two columns
              height: '40px',  // Matches the button height
            }}
          />
        )}
    </div>
  );
}

calculateChessboardSize = (windowWidth, windowHeight) => {
  // Define maximum size constraints for the chessboard based on the viewport
  const maxSize = 800; // Maximum size of the chessboard for larger screens

  // If the window width or height is less than 768, make the chessboard fill almost the whole viewport
  if (windowWidth < 768 || windowHeight < 768) {
    const viewportFillRatio = 1; // 99% of the viewport
    const sizeByWidth = windowWidth * viewportFillRatio;
    const sizeByHeight = windowHeight * viewportFillRatio;

    // Return the lesser of the two dimensions to ensure the chessboard fits in the viewport
    return Math.min(sizeByWidth, sizeByHeight, 500);
  } else {
    // For larger screens, use the previous logic
    const maxByWidth = windowWidth * 0.8; // e.g., 80% of window width
    const maxByHeight = windowHeight * 0.8; // e.g., 80% of window height

    // Calculate size based on the lesser of width and height constraints, not exceeding maxSize
    return Math.min(Math.min(maxByWidth, maxByHeight), maxSize);
  }
};


handleResize = () => {
  const newChessboardSize = this.calculateChessboardSize(window.innerWidth, window.innerHeight); // Adjust based on height as well
  this.setState({ chessboardSize: newChessboardSize }, () => {
    this.setupCanvas(); // Call setupCanvas here to adjust canvas size after state update
  });
};

handleMouseDown(event) {
    if ((this.state.isDrawMode && (event.button === 0 || event.button === 2)) ||
        (!this.state.isDrawMode && event.button === 2)) {
        // Only prevent default action in specific cases
        event.preventDefault();
        const fromSquare = this.getSquareFromCoordinates(event.clientX, event.clientY);
        this.setState({ dragStart: fromSquare,
                        keyStates: {
                        shift: event.shiftKey,
                        ctrl: event.ctrlKey,
                        alt: event.altKey,
              }
        });
    }
}


handleMouseMove(event) {
    if (this.state.dragStart) {
        // Same logic as before
        const toSquare = this.getSquareFromCoordinates(event.clientX, event.clientY);
        this.setState({ dragEnd: toSquare });
    }
}

handleMouseUp(event) {
    if (this.state.dragStart) {
        const toSquare = this.getSquareFromCoordinates(event.clientX, event.clientY);
        if (this.state.isDrawMode && !this.state.eraserSelected) {
            // Draw arrow
            this.drawArrow(this.state.dragStart, toSquare, this.state.keyStates);
        } else if (this.state.isDrawMode && this.state.eraserSelected) {
            // Erase arrow
            // Convert toSquare to actual coordinates if necessary
            this.eraseArrow(toSquare);
        } else {
            this.drawArrow(this.state.dragStart, toSquare, this.state.keyStates);
        }

        this.setState({ dragStart: null, dragEnd: null, keyStates: {} });
    }
}

handleTouchStart(event) {
  if(this.state.isDrawMode) {
    // Prevent the window from scrolling
    // Use touches[0] to get the first touch point
    const touch = event.touches[0];
    const fromSquare = this.getSquareFromCoordinates(touch.clientX, touch.clientY);

    this.setState({
      dragStart: fromSquare,
      keyStates: {
        // Note: touch events don't directly support modifier keys like shift, ctrl, alt
        shift: false,
        ctrl: false,
        alt: false,
      }
    });
    } else {
    }
}

handleTouchMove(event) {
    if (this.state.dragStart) {
        // Use touches[0] to get the first touch point
        const touch = event.touches[0];
        const toSquare = this.getSquareFromCoordinates(touch.clientX, touch.clientY);

        this.setState({ dragEnd: toSquare });
    }
}

handleTouchEnd(event) {
    const { dragStart, dragEnd, isDrawMode, eraserSelected } = this.state;
    console.log('touch end from: ' + dragStart + ' to ' + dragEnd);
    if (isDrawMode && dragStart && isValidSquare(dragStart) && isValidSquare(dragEnd)) {
        // Check both dragStart and dragEnd are valid before proceeding
        if (dragEnd) {
            if (!eraserSelected) {
                this.drawArrow(dragStart, dragEnd, {});
                console.log('Arrow drawn from ' + dragStart + ' to ' + dragEnd);
            } else {
                // Additional logic for erasing can be added here
                this.eraseArrow(dragEnd);
                console.log('Arrow erased at ' + dragEnd);
            }
        } else {
//            console.error('No valid end square for touch event');
        }
    } else {
//        console.error('Invalid touch operation: dragStart or dragEnd not valid');
    }
    this.setState({ dragStart: null, dragEnd: null, keyStates: {} });
}

setupCanvas() {
  const canvas = this.canvasRef.current;
  const size = this.state.chessboardSize; // Use state-controlled chessboard size

    if (canvas) {
      canvas.width = size; // Control drawing buffer size
      canvas.height = size;
      canvas.style.width = `${size}px`; // Control display size
      canvas.style.height = `${size}px`;

//    console.log('Canvas dimensions set to:', canvas.width, 'x', canvas.height);

    // Optional: Adjust canvas's drawing or styles as needed
    const ctx = canvas.getContext('2d');
//    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear before filling to see the update
//    ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Example: semi-transparent red
//    ctx.fillRect(0, 0, canvas.width, canvas.height);

//    console.log('Canvas styled and filled');
  } else {
//    console.log('Canvas not found');
  }
}

drawArrow(fromSquare, toSquare, keys = {}, drawnColor = null) {

  if (!isValidSquare(fromSquare) || (!isValidSquare(toSquare))) {
    console.error('invalid arrow squares')
    return;
  }

  console.log('in draw arrow. from square: ' + fromSquare + ' to square: ' + toSquare);
  if (this.props.showQuiz) {
    return;
    }
  if (!this.canvasRef || !this.canvasRef.current) return;
  const ctx = this.canvasRef.current.getContext('2d');
  if (!ctx) return;

  // Determine color based on draw mode or key states
  let color = drawnColor || this.state.selectedColor;
  if (!this.state.isDrawMode) {
    color = 'rgba(20, 163, 0, 0.8)'
    if (keys.shift) color = 'rgba(205, 0, 0, 0.8)';
    else if (keys.ctrl) color = 'rgba(0, 0, 205, 0.8)';
    else if (keys.alt) color = 'rgba(205, 176, 0, 0.9)';
  }

  const canvasSize = this.canvasRef.current.width;
  const squareSize = canvasSize / 8;

  const fromCoords = this.squareToCanvasCoords(fromSquare, squareSize);
  const toCoords = this.squareToCanvasCoords(toSquare, squareSize);
  if (!fromCoords || !toCoords) return;

      let newArrow = {
        from: fromSquare,
        to: toSquare,
        fromCoords: fromCoords,
        toCoords: toCoords,
        color: drawnColor || this.state.selectedColor // Or whatever logic you have for determining color
    };
    // When clicking on the same square to highlight it with a square
    if (fromSquare === toSquare) {
      const squareBorderSize = squareSize / 12; // Adjust the border width as needed

      ctx.beginPath();
      ctx.lineWidth = squareBorderSize;
      ctx.strokeStyle = color;

      // Calculate the top-left corner of the inner square
      const inset = squareBorderSize / 2; // Inset to keep the border inside the square
      const topLeftX = fromCoords.x - squareSize / 2 + inset;
      const topLeftY = fromCoords.y - squareSize / 2 + inset;

      // Draw the square outline inset by the border size
      ctx.rect(topLeftX, topLeftY, squareSize - squareBorderSize, squareSize - squareBorderSize);
      ctx.stroke();

      this.setState(prevState => ({
          drawnArrows: [...prevState.drawnArrows, newArrow]
      }));
      return;
    }

  const headlen = squareSize / 1.5; // Length of the arrow head
  const angle = Math.atan2(toCoords.y - fromCoords.y, toCoords.x - fromCoords.x);
  const offset = headlen * 0.86; // Adjust to get the line end closer to the arrow head
  const stemEndX = toCoords.x - offset * Math.cos(angle);
  const stemEndY = toCoords.y - offset * Math.sin(angle);

  // Draw the arrow stem
  ctx.beginPath();
  ctx.moveTo(fromCoords.x, fromCoords.y);
  ctx.lineTo(stemEndX, stemEndY);
  ctx.strokeStyle = color;
  ctx.lineWidth = squareSize / 4; // Thickness of the arrow
  ctx.stroke();

  // Draw the arrow head
  ctx.beginPath();
  ctx.moveTo(toCoords.x, toCoords.y);
  ctx.lineTo(toCoords.x - headlen * Math.cos(angle - Math.PI / 6), toCoords.y - headlen * Math.sin(angle - Math.PI / 6));
  ctx.lineTo(toCoords.x - headlen * Math.cos(angle + Math.PI / 6), toCoords.y - headlen * Math.sin(angle + Math.PI / 6));
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();

    // Add the new arrow to the drawnArrows state
    this.setState(prevState => ({
        drawnArrows: [...prevState.drawnArrows, newArrow]
    }));
}

eraseArrow(chessSquare) {
  if (typeof chessSquare !== 'string' || chessSquare.length !== 2) {
    console.error('Invalid square provided to squareToCanvasCoords:', chessSquare);
    return null;
  }
  const canvasSize = this.canvasRef.current.width; // Assuming square canvas for simplicity
  const squareSize = canvasSize / 8;

  // Convert the chess square to canvas coordinates, now including squareSize
  const endPoint = this.squareToCanvasCoords(chessSquare, squareSize);
  if (!endPoint) {
    console.error('Invalid chessSquare provided:', chessSquare);
    return;
  }

  const newArrows = this.state.drawnArrows.filter(arrow => {
    // Ensure arrow has valid start and end canvas coordinates
    if (!arrow.fromCoords || !arrow.toCoords) {
      console.warn('Skipping arrow with invalid structure:', arrow);
      return false;
    }

    // Calculate distance to both the start and end points of the arrow
    const threshold = 10; // Pixels for "closeness"
    const distanceToEnd = Math.sqrt(Math.pow(arrow.toCoords.x - endPoint.x, 2) + Math.pow(arrow.toCoords.y - endPoint.y, 2));
    const distanceToStart = Math.sqrt(Math.pow(arrow.fromCoords.x - endPoint.x, 2) + Math.pow(arrow.fromCoords.y - endPoint.y, 2));

    // Consider an arrow for erasing if the mouse up event is close to either its start or end points
    const erase = distanceToEnd > threshold && distanceToStart > threshold;
    return erase;
  });

  // Update state and redraw
  this.setState({ drawnArrows: newArrows }, () => {
    this.redrawArrows();
  });
}

redrawArrows() {

  const ctx = this.canvasRef.current.getContext('2d');
  if (!ctx) return;

  // Clear the canvas first
  ctx.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);
  // Redraw each arrow
  this.state.drawnArrows.forEach(arrow => {
    this.drawArrow(arrow.from, arrow.to, '', arrow.color);
  });
}




squareToCanvasCoords(square, squareSize) {
  // Convert algebraic notation (e.g., "e2") to canvas coordinates
  const file = square.charCodeAt(0) - 'a'.charCodeAt(0); // 'a' -> 0, 'b' -> 1, ...
  const rank = 8 - (square[1] - '0'); // '1' -> 7, '2' -> 6, ...

  return {
    x: file * squareSize + squareSize / 2, // Center of the square horizontally
    y: rank * squareSize + squareSize / 2, // Center of the square vertically
  };
}

clearArrows = () => {
  if (!this.canvasRef || !this.canvasRef.current) return;
  const ctx = this.canvasRef.current.getContext('2d');
  ctx.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);
  this.setState({ drawnArrows: [] });
}


getSquareFromCoordinates(x, y) {
  const rect = this.canvasRef.current.getBoundingClientRect();
  const scale = {
    x: this.canvasRef.current.width / rect.width,
    y: this.canvasRef.current.height / rect.height,
  };
  const adjustedX = (x - rect.left) * scale.x; // Adjusting for potential scaling
  const adjustedY = (y - rect.top) * scale.y;

  const squareSize = this.canvasRef.current.width / 8;
  const file = Math.floor(adjustedX / squareSize);
  const rank = 7 - Math.floor(adjustedY / squareSize);

  const fileLetter = String.fromCharCode('a'.charCodeAt(0) + file);
  const rankNumber = rank + 1;

  return `${fileLetter}${rankNumber}`;
}

togglePeeking = () => {
  this.setState(prevState => ({
    isPeeking: !prevState.isPeeking,
  }));
}

toggleDrawMode = () => {
  this.setState(prevState => ({
    isDrawMode: !prevState.isDrawMode,
    drawnArrows: [],
  })); // Log the new state to ensure it's changing
}

getCSSVariableValue(variableName) {
  let value = getComputedStyle(document.documentElement).getPropertyValue(variableName);
  // Trim whitespace and strip quotes
  value = value.trim().replace(/^['"]+|['"]+$/g, '');
  return value;
}


renderPiece = (pieceCode, allWhite) => {
  const pieceType = this.state.isPeeking ? 'Normal' : this.props.degreeOfBlindness;
  let imageUrl;

 const adjustedPieceCode = allWhite && !this.state.isPeeking && pieceCode.startsWith('b') ? `w${pieceCode[1]}` : pieceCode;


  if (pieceType === 'Normal' || pieceType === 'Normal Colorless') {
    // Normal pieces
    const baseUrl = `${process.env.PUBLIC_URL}/pieces/${this.getCSSVariableValue('--normal-piece')}/`;
    imageUrl = `${baseUrl}${adjustedPieceCode}.png`;
  } else if (pieceType === 'Marker' || pieceType === 'Marker Colorless') {
    // Marker case: URL ends with just 'w' or 'b'
    const pieceColor = adjustedPieceCode.charAt(0); // 'w' or 'b'
    imageUrl = `${process.env.PUBLIC_URL}/pieces/${this.getCSSVariableValue('--marker-piece')}/${pieceColor}.png`;
  } else if (pieceType === 'Board Only') {
    imageUrl = `${process.env.PUBLIC_URL}/pieces/Empty.png`;
  } else {
    // Default to normal pieces if none of the conditions are met
    const baseUrl = `${process.env.PUBLIC_URL}/pieces/normal/`;
    imageUrl = `${baseUrl}${pieceCode}.png`;
  }

  return ({ squareWidth }) => (
    <img
      style={{ width: squareWidth, height: squareWidth }}
      src={imageUrl}
      alt=""
    />
  );
};

renderExtraButtons = () => {
  // If in Board Display mode, don't show the draw mode button
  if (this.props.isBoardDisplay || !this.state.drawButton) {
    return null;
  }

  return (
    <div className="extra-buttons-container">
      <button
        className={`peek-button ${!this.state.isDrawMode ? 'off' : ''}`}
        onClick={this.toggleDrawMode}
      >
        <img src={process.env.PUBLIC_URL + "/buttons/drawMode.png"} alt="Draw Mode"/>
      </button>
      {this.props.degreeOfBlindness !== 'normal' && this.props.degreeOfBlindness !== 'Normal' && (
        <button
          className={`peek-button ${!this.state.isPeeking ? 'off' : ''}`}
          onClick={this.togglePeeking}
        >
          <img src={process.env.PUBLIC_URL + "/buttons/normal.png"} alt="Normal"/>
        </button>
      )}
      {this.renderOptionsMenu()}
    </div>
  );
}


//    renderExtraButtons = () => {
//        if (window.innerWidth > 768) {
//            return (
//                <div className="extra-buttons-container">
//                    <button
//                        className={`peek-button ${!this.state.isDrawMode ? 'off' : ''}`}
//                        onClick={this.toggleDrawMode}
//                    >
//                        <img src={process.env.PUBLIC_URL + "/buttons/drawMode.png"} alt="Draw Mode"/>
//                    </button>
//                    {this.props.degreeOfBlindness !== 'normal' && this.props.degreeOfBlindness !== 'Normal' && (
//                        <button
//                            className={`peek-button ${!this.state.isPeeking ? 'off' : ''}`}
//                            onClick={this.togglePeeking}
//                        >
//                            <img src={process.env.PUBLIC_URL + "/buttons/normal.png"} alt="Normal"/>
//                        </button>
//                    )}
//                    {this.renderOptionsMenu()}
//                </div>
//            );
//        } else {
//            // Compact and collapsible container for smaller screens
//            return (
//                <div className="extra-buttons-container">
//                        <div>
//                            <button
//                                className={`peek-button ${!this.state.isDrawMode ? 'off' : ''}`}
//                                onClick={this.toggleDrawMode}
//                            >
//                                <img src={process.env.PUBLIC_URL + "/buttons/drawMode.png"} alt="Draw Mode"/>
//                            </button>
//                            {this.props.degreeOfBlindness !== 'normal' && this.props.degreeOfBlindness !== 'Normal' && (
//                                <button
//                                    className={`peek-button ${!this.state.isPeeking ? 'off' : ''}`}
//                                    onClick={this.togglePeeking}
//                                >
//                                    <img src={process.env.PUBLIC_URL + "/buttons/normal.png"} alt="Normal"/>
//                                </button>
//                            )}
//                            {this.renderOptionsMenu()} {/* Adjust this for small screen layout if necessary */}
//                        </div>
//                </div>
//            );
//        }
//    }

  render() {
    const { position, onDrop, orientation, squareStyles, onSquareClick, onSquareRightClick, onPieceDragBegin } = this.props;
    const { blockBoard } = this.state;
  const allWhite = this.props.degreeOfBlindness === 'Normal Colorless' || this.props.degreeOfBlindness === 'Marker Colorless';

  const customPieces = {
    'wP': this.renderPiece('wP', allWhite),
    'wR': this.renderPiece('wR', allWhite),
    'wN': this.renderPiece('wN', allWhite),
    'wB': this.renderPiece('wB', allWhite),
    'wQ': this.renderPiece('wQ', allWhite),
    'wK': this.renderPiece('wK', allWhite),
    'bP': this.renderPiece('bP', allWhite),
    'bR': this.renderPiece('bR', allWhite),
    'bN': this.renderPiece('bN', allWhite),
    'bB': this.renderPiece('bB', allWhite),
    'bQ': this.renderPiece('bQ', allWhite),
    'bK': this.renderPiece('bK', allWhite),
  };
  // Determine whether to show the chessboard or the placeholder
  const showPlaceholder = blockBoard && !this.state.isPeeking;


return (
    <div onClick={() => !this.state.isDrawMode && this.clearArrows()}>
    <div>
    {this.renderExtraButtons()}
      {showPlaceholder ? (
          <div className="chessboard-placeholder" style={{
            width: `${this.state.chessboardSize}px`,
            height: `${this.state.chessboardSize}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            background: '#f0f0f0',
            borderRadius: '5px',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            zIndex: 100,
          }}>
            <p style={{ textAlign: 'center', padding: '20px', zIndex: 100}}>The Board is Covered</p>
          </div>
      ) : (
      <div>
        <Chessboard
          width= {this.state.chessboardSize}
          key={this.state.isPeeking ? 'peeking' : 'not-peeking'}
          togglePeeking={this.togglePeeking}
          position={position}
          onDrop={onDrop}
          onSquareClick={onSquareClick}
          onSquareRightClick={onSquareRightClick}
          orientation={orientation}
          squareStyles={squareStyles}
          onPieceDragBegin={onPieceDragBegin}
          boardStyle={{
            borderRadius: '5px',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            display: 'block',
          }}
          darkSquareStyle={{ backgroundColor: 'var(--dark-square-color)' }}
          lightSquareStyle={{ backgroundColor: 'var(--light-square-color)' }}
          pieces={customPieces}
        />
        </div>
      )}
    </div>
    <canvas
      ref={this.canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: this.state.isDrawMode ? 'auto' : 'none',
        width: '100%',
        height: '100%',
        zIndex: 10
      }}
    ></canvas>
  </div>
);

}
}

ChessboardCustom.defaultProps = {
  updateChessboardSize: () => {} // default function
};

export default ChessboardCustom;