const PageEndTag = () => {

  return (
    <div>
      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>If you have more questions or need further assistance, feel free to check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> for additional resources.</p>
      </section>
    </div>
  );
};

export default PageEndTag;
