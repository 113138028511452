import React, { useEffect, useState } from "react";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase-config";
import { useGameState } from "../Integrations/GameStateContext";
import { Chess } from "chess.js";

const LoadGameSidebar = ({ user, onGameLoad }) => {
  const [savedGames, setSavedGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const { gameState } = useGameState();

  useEffect(() => {
    if (user) {
      fetchSavedGames();
    }
  }, [user]);

  const fetchSavedGames = async () => {
    setIsLoading(true);
    try {
      const userUid = user?.uid;
      if (!userUid) throw new Error("User UID not found.");

      const gamesCollection = collection(db, "users", userUid, "reviewedGames");
      const gamesQuery = query(gamesCollection, orderBy("timestamp", "desc"), limit(20));
      const gamesSnapshot = await getDocs(gamesQuery);

      const games = gamesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSavedGames(games);
    } catch (err) {
      console.error("Error fetching saved games:", err);
      setError("Failed to fetch saved games.");
    } finally {
      setIsLoading(false);
    }
  };

const getOpponentUsername = (game) => {
  const { usernames, playerColor } = game;
  if (!usernames || !playerColor) return "Unknown";

  // If player is White, the opponent is Black and vice versa
  return playerColor === "White" ? usernames.black : usernames.white;
};

const determineGameResult = (game) => {
  const { analysis, playerColor } = game;
  if (!analysis || analysis.length === 0) return "Unknown";

  const chess = new Chess();
  analysis.forEach((move) => {
    chess.move(move.move); // Assuming `move` contains the SAN notation
  });

  if (chess.isCheckmate()) {
    return chess.turn() === (playerColor === "White" ? "b" : "w")
      ? "Victory"
      : "Defeat";
  } else if (chess.isDraw()) {
    return "Draw";
  } else if (chess.isStalemate()) {
    return "Draw";
  } else {
    return "In Progress"; // Or handle as appropriate
  }
};

  return (
    <div className="game-list-sidebar">
      <div className="game-list-sidebar-header">Saved Games</div>
      <div className="game-date-section">
      {error && <p className="error-message">{error}</p>}
      {isLoading ? (
        <p>Loading games...</p>
      ) : (
        savedGames.map((game) => (
          <div
            key={game.id}
            className={`game-list-item ${
              game.platform === "Lichess" ? "lichess-game" : "chesscom-game"
            }`}
            onClick={() => onGameLoad(game)}
          >

             <p className="game-result">
              {determineGameResult(game) === "Victory" ? (
                <span className="badge win-badge">
                  Victory VS {getOpponentUsername(game)}
                </span>
              ) : determineGameResult(game) === "Defeat" ? (
                <span className="badge loss-badge">
                  Defeat VS {getOpponentUsername(game)}
                </span>
              ) : (
                <span className="badge draw-badge">
                  Draw VS {getOpponentUsername(game)}
                </span>
              )}
            </p>
            <div className="game-body">
              <p className="game-opponent">
                <strong>{game.id}</strong>
              </p>
            </div>
          </div>
        ))
      )}
    </div>
    </div>
  );
};

export default LoadGameSidebar;
