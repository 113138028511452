//import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, signInWithCredential } from "firebase/auth";
//import { getFirestore, doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";import '../firebase-config';
//import '../styles/Credentials.css';
//import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
//import { gapi } from 'gapi-script';
//import { quotes } from '../Integrations/helperFunctions';
//import { db } from '../firebase-config';
//
//const auth = getAuth();
//
//export default function SignUp() {
//    const [email, setEmail] = useState('');
//    const [password, setPassword] = useState('');
//    const [confirmPassword, setConfirmPassword] = useState('');
//    const [username, setUsername] = useState('');
//    const [message, setMessage] = useState(''); // State for feedback message
//    const navigate = useNavigate();
//    const [quoteIndex, setQuoteIndex] = useState(0);
//    const [isFadingOut, setIsFadingOut] = useState(false);
//    const [shuffledQuotes, setShuffledQuotes] = useState([]);
//    const [usernameAvailable, setUsernameAvailable] = useState(true);
//
//  const initializeGapi = () => {
//    gapi.client.init({
//      clientId: '110322528888-d6fg9pg0tm9gqqrs44o4rhrjulajd9lq',
//      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
//    });
//  };
//
//  useEffect(() => {
//    // Load and initialize Google API
//    gapi.load('client:auth2', initializeGapi);
//  }, []);
//
//
//    useEffect(() => {
//        // Check if the username is available in real-time as the user types
//        if (username.trim().length > 0) {
//            console.log('Checking username');
//            checkUsernameAvailability(username);
//        } else {
//            setUsernameAvailable(true); // reset if input is cleared
//        }
//    }, [username]);
//
//        const checkUsernameAvailability = async (username) => {
//        try {
//            console.log('checking ' + username);
//            const usernamesRef = collection(db, "usernames");
//            const q = query(usernamesRef, where("username", "==", username));
//            const querySnapshot = await getDocs(q);
//
//            if (querySnapshot.empty) {
//                setUsernameAvailable(true); // Username is available
//                setMessage('');
//                console.log('username good')
//            } else {
//                setUsernameAvailable(false); // Username is taken
//                setMessage('Username not available');
//                console.log('username bad')
//            }
//        } catch (error) {
//            console.error("Error checking username availability:", error);
//        }
//    };
//
//    const isPasswordValid = (password) => {
//        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
//        return regex.test(password);
//    };
//
//    const signUp = async (event) => {
//        event.preventDefault();
//
//        if (!username.trim()) {
//            setMessage("Username is required.");
//            return;
//        }
//
//        if (!usernameAvailable) {
//            setMessage("Username is not available. Please choose a different one.");
//            return;
//        }
//
//        if (password !== confirmPassword) {
//            setMessage("Passwords don't match.");
//            return;
//        }
//
//        if (!isPasswordValid(password)) {
//            setMessage("Password must be at least 8 characters long and include a number and a special character.");
//            return;
//        }
//
//        try {
//            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//            const user = userCredential.user;
//            console.log('User created:', user);
//
//            await setDoc(doc(db, "users", user.uid), { username: username });
//            await setDoc(doc(db, "usernames", username), { username: username });
//
//            setMessage('User created successfully!');
//            navigate('/'); // Navigate to another page upon successful signup
//        } catch (error) {
//            setMessage(`Error signing up: ${error.message}`);
//        }
//    };
//
//    useEffect(() => {
//        const shuffled = [...quotes].sort(() => Math.random() - 0.5);
//        setShuffledQuotes(shuffled);
//    }, []);
//
//    // Carousel logic to change the quote every 5 seconds
//    useEffect(() => {
//        if (shuffledQuotes.length === 0) return;
//
//        const interval = setInterval(() => {
//            setIsFadingOut(true);
//            setTimeout(() => {
//                setQuoteIndex((prevIndex) => (prevIndex + 1) % shuffledQuotes.length);
//                setIsFadingOut(false);
//            }, 1000); // Allow some time for the fade-out to occur
//        }, 6000); // Change quote every 5 seconds
//
//        return () => clearInterval(interval); // Clean up the interval on component unmount
//    }, [shuffledQuotes]);
//
//const googleSignUp = async () => {
//  try {
//    const auth2 = gapi.auth2.getAuthInstance();
//    const googleUser = await auth2.signIn();
//
//    // Get the ID token from Google
//    const idToken = googleUser.getAuthResponse().id_token;
//
//    // Use Firebase `signInWithCredential()` to authenticate with Firebase
//    const credential = GoogleAuthProvider.credential(idToken);
//    const result = await signInWithCredential(auth, credential);
//    console.log('Firebase sign-in successful', result.user);
//    navigate('/');
//  } catch (error) {
//    console.error('Error with Google sign-in:', error.message);
//    setMessage(error.message);
//  }};
//
//    return (
//        <div className="split-screen-container">
//            <EnableScrollOnPage />
//            <div className="split-left">
//                <div className="overlay-credentials">
//                    <img src="/checkeredBackground.png" alt="Sign Up Illustration" className="background-img" />
//                    <div className="logo-container">
//                        <img src="/logo.png" alt="Train Chess Logo" className="logo-img" />
//                        {/* Carousel for quotes or fun facts */}
//
//                    </div>
//                    <div className="quote-carousel">
//                        <p className={`quote-text ${isFadingOut ? 'fade-out' : 'fade-in'}`}>
//                            {shuffledQuotes[quoteIndex]}
//                        </p>
//                    </div>
//                </div>
//            </div>
//
//            {/* Right 1/4 Section with Form */}
//            <div className="split-right">
//                <form onSubmit={signUp} className="signup-form">
//                    <div className="signup-container">
//                        <h2>Welcome to <span className="highlight-text">Train Chess</span></h2>
//                        <input
//                            type="text"
//                            value={username}
//                            onChange={(e) => setUsername(e.target.value)}
//                            placeholder="Username"
//                            autoComplete="username"
//                            required
//                        />
//                        <input
//                            type="email"
//                            value={email}
//                            onChange={(e) => setEmail(e.target.value)}
//                            placeholder="E-mail address"
//                            autoComplete="email"
//                            required
//                        />
//                        <input
//                            type="password"
//                            value={password}
//                            onChange={(e) => setPassword(e.target.value)}
//                            placeholder="Password"
//                            autoComplete="new-password"
//                            required
//                        />
//                        <input
//                            type="password"
//                            value={confirmPassword}
//                            onChange={(e) => setConfirmPassword(e.target.value)}
//                            placeholder="Confirm Password"
//                            autoComplete="new-password"
//                            required
//                        />
//                        <button type='submit' className='btn-signup'>Sign up</button>
//
//                        <div className="divider">
//                            <span>--- or ---</span>
//                        </div>
//
//                        <div className="social-login">
//                            <button onClick={googleSignUp} className="btn-google-signup">
//                                <img src="/googleIcon.png" alt="Google Icon" /> Continue with Google
//                            </button>
//                        </div>
//
//                        <p className="signup-link">
//                            Already have an account? <a href="/login">Log in</a>
//                        </p>
//                        {message && <div className="error-message">{message}</div>}
//                    </div>
//                </form>
//            </div>
//        </div>
//    );
//}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, signInWithCredential, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, query, where, getDocs } from "firebase/firestore";import '../firebase-config';
import '../styles/Credentials.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import { gapi } from 'gapi-script';
import { quotes } from '../Integrations/helperFunctions';
import { db } from '../firebase-config';

const auth = getAuth();

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState(''); // State for feedback message
    const navigate = useNavigate();
    const [quoteIndex, setQuoteIndex] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [shuffledQuotes, setShuffledQuotes] = useState([]);
    const [usernameAvailable, setUsernameAvailable] = useState(true);

    // Ensure session persistence is set
    useEffect(() => {
        console.log("Setting session persistence...");
        setPersistence(auth, browserLocalPersistence)
            .then(() => console.log("Session persistence set to local."))
            .catch((error) => console.error("Error setting session persistence:", error.message));
    }, []);

    // Initialize Google API
    useEffect(() => {
        const initializeGapi = () => {
            console.log("Initializing Google API...");
            gapi.load('auth2', () => {
                console.log("Google API loaded. Initializing Auth2...");
                gapi.auth2.init({
                    client_id: '110322528888-d6fg9pg0tm9gqqrs44o4rhrjulajd9lq', // Replace with your Google Client ID
                    redirect_uri: 'https://trainchess.net/login', // Replace with your redirect URI
                }).then(() => console.log("Google Auth2 initialized."))
                  .catch((error) => console.error("Error initializing Google Auth2:", error));
            });
        };
        initializeGapi();
    }, []);


    useEffect(() => {
        // Check if the username is available in real-time as the user types
        if (username.trim().length > 0) {
            console.log('Checking username');
            checkUsernameAvailability(username);
        } else {
            setUsernameAvailable(true); // reset if input is cleared
        }
    }, [username]);

        const checkUsernameAvailability = async (username) => {
        try {
            console.log('checking ' + username);
            const usernamesRef = collection(db, "usernames");
            const q = query(usernamesRef, where("username", "==", username));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                setUsernameAvailable(true); // Username is available
                setMessage('');
                console.log('username good')
            } else {
                setUsernameAvailable(false); // Username is taken
                setMessage('Username not available');
                console.log('username bad')
            }
        } catch (error) {
            console.error("Error checking username availability:", error);
        }
    };

    const isPasswordValid = (password) => {
        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        return regex.test(password);
    };

    const signUp = async (event) => {
        event.preventDefault();

        if (!username.trim()) {
            setMessage("Username is required.");
            return;
        }

        if (!usernameAvailable) {
            setMessage("Username is not available. Please choose a different one.");
            return;
        }

        if (password !== confirmPassword) {
            setMessage("Passwords don't match.");
            return;
        }

        if (!isPasswordValid(password)) {
            setMessage("Password must be at least 8 characters long and include a number and a special character.");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User created:', user);

            await setDoc(doc(db, "users", user.uid), { username: username });
            await setDoc(doc(db, "usernames", username), { username: username });

            setMessage('User created successfully!');
            navigate('/'); // Navigate to another page upon successful signup
        } catch (error) {
            setMessage(`Error signing up: ${error.message}`);
        }
    };

    useEffect(() => {
        const shuffled = [...quotes].sort(() => Math.random() - 0.5);
        setShuffledQuotes(shuffled);
    }, []);

    // Carousel logic to change the quote every 5 seconds
    useEffect(() => {
        if (shuffledQuotes.length === 0) return;

        const interval = setInterval(() => {
            setIsFadingOut(true);
            setTimeout(() => {
                setQuoteIndex((prevIndex) => (prevIndex + 1) % shuffledQuotes.length);
                setIsFadingOut(false);
            }, 1000); // Allow some time for the fade-out to occur
        }, 6000); // Change quote every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [shuffledQuotes]);

    // Handle Google Sign-In with Redirect Flow
    const googleSignUp = async () => {
        try {
            const auth2 = gapi.auth2.getAuthInstance();
            console.log("Starting Google redirect sign-in...");
            auth2.signIn({
                ux_mode: 'redirect',
                prompt: 'select_account',
            });
            console.log("Google redirect sign-in initiated.");
        } catch (error) {
            console.error("Error during Google redirect sign-in:", error.message);
            setMessage(error.message);
        }
    };


    return (
        <div className="split-screen-container">
            <EnableScrollOnPage />
            <div className="split-left">
                <div className="overlay-credentials">
                    <img src="/checkeredBackground.png" alt="Sign Up Illustration" className="background-img" />
                    <div className="logo-container">
                        <img src="/logo.png" alt="Train Chess Logo" className="logo-img" />
                        {/* Carousel for quotes or fun facts */}

                    </div>
                    <div className="quote-carousel">
                        <p className={`quote-text ${isFadingOut ? 'fade-out' : 'fade-in'}`}>
                            {shuffledQuotes[quoteIndex]}
                        </p>
                    </div>
                </div>
            </div>

            {/* Right 1/4 Section with Form */}
            <div className="split-right">
                <form onSubmit={signUp} className="signup-form">
                    <div className="signup-container">
                        <h2>Welcome to <span className="highlight-text">Train Chess</span></h2>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            autoComplete="username"
                            required
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-mail address"
                            autoComplete="email"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            autoComplete="new-password"
                            required
                        />
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                            autoComplete="new-password"
                            required
                        />
                        <button type='submit' className='btn-signup'>Sign up</button>

                        <div className="divider">
                            <span>--- or ---</span>
                        </div>

                        <div className="social-login">
                            <button onClick={googleSignUp} className="btn-google-signup">
                                <img src="/googleIcon.png" alt="Google Icon" /> Continue with Google
                            </button>
                        </div>

                        <p className="signup-link">
                            Already have an account? <a href="/login">Log in</a>
                        </p>
                        {message && <div className="error-message">{message}</div>}
                    </div>
                </form>
            </div>
        </div>
    );
}
