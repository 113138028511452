import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import PromotionModal from '../Integrations/PromotionModal';
import '../styles/puzzles.css';
import QuizComponent from '../Integrations/QuizComponent';
import {fetchPuzzleStats, fetchPuzzle, toggleEndgame} from '../Integrations/helperFunctions';
import { db } from '../firebase-config';
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { tacticsRide } from '../Integrations/Joyrides';
import Countdown from '../Integrations/Countdown';
import { getAccuracyColor } from "../Integrations/helperFunctions";

function TimedPuzzles() {
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [moveHistory, setMoveHistory] = useState([]);
  const [playerColor, setPlayerColor] = useState('white');
  const [lastColor, setLastColor] = useState('white');
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState('');
  const [previousFen, setPreviousFen] = useState(null);
  const [squareStyles, setSquareStyles] = useState({});
  const [pieceSquare, setPieceSquare] = useState("");
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [promotionOpen, setPromotionOpen] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [answeredCorrect, setAnsweredCorrect] = useState(true);
  const { user } = useUser();
  const [puzzleRating, setPuzzleRating] = useState(null);
  const [puzzleAccuracy, setPuzzleAccuracy] = useState(0);
  const [puzzleCount, setPuzzleCount] = useState(0);
  const [readyForMove, setReadyForMove] = useState(false);
  const [currentSquare, setCurrentSquare] = useState(null);
  const [chessboardSize, setChessboardSize] = useState(600);
  const [quizAnswer, setQuizAnswer] = useState("");
  const [boardAwarenessQuiz, setBoardAwarenessQuiz] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [loadingPuzzle, setLoadingPuzzle] = useState(false);
  const loadingPuzzleRef = useRef(false);
  const [timeLimit, setTimeLimit] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit);
  const timerRef = useRef(null);
  const moves = currentPuzzle?.Moves.split(' ');
  const [isEndgame, setIsEndgame] = useState(false);
  const [showSettings, setShowSettings] = useState(false); // Add this to your component
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [selectedTimeLimit, setSelectedTimeLimit] = useState(null); // Tracks the time limit
  const [showCountdown, setShowCountdown] = useState(false);       // Controls the countdown modal
  const [currentPuzzleState, setCurrentPuzzleState] = useState({
    // Initialize with your puzzle data
    currentPuzzle: null,
    progress: 0,
    // Add other fields as necessary
  });
  const navigate = useNavigate();

useEffect(() => {
  if (user) {
    setShowLoginMessage(false); // Hide the login message if the user is logged in
  } else {
    setShowLoginMessage(true);  // Show the login message if no user is logged in
  }
}, [user]);

useEffect(() => {
  if (showCountdown) {
    return;
  };
  const loadUserDataAndPuzzle = async () => {
    // Fetch user data with current time limit and endgame flag
    const fetchedPuzzleRating = await fetchPuzzleStats(user, timeLimit, isEndgame, setPuzzleRating, setPuzzleAccuracy, setPuzzleCount);
    console.log('Fetched puzzle rating:', fetchedPuzzleRating);

    // Once the puzzle rating is fetched, we can fetch the puzzle
    if (fetchedPuzzleRating !== null && fetchedPuzzleRating !== undefined) {
      setCurrentPuzzle(null);
      setCurrentMoveIndex(0);
      fetchPuzzle(fetchedPuzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame);
    }
  };

  loadUserDataAndPuzzle();
}, [user, timeLimit, isEndgame]);
// Dependencies include user, timeLimit, and isEndgame

  useEffect(() => {
    if (currentPuzzle?.FEN) {
      setIsTimedOut(false);
      setGame(new Chess(currentPuzzle.FEN));
      setFen(currentPuzzle.FEN);
      setLastColor(playerColor);
      setPlayerColor(currentPuzzle?.FEN.split(' ')[1] === 'w' ? 'black' : 'white');
      setAnsweredCorrect(true);
    }
  }, [currentPuzzle?.FEN]);

  useEffect(() => {
    if (currentMoveIndex % 2 === 0 && game && moves && moves.length > 0 && currentMoveIndex < moves.length) {

      const move = moves[currentMoveIndex];
      const from = move.substring(0, 2);
      const to = move.substring(2, 4);
      const promotion = move.substring(5) || 'q';

      setTimeout(() => {
        game.move({ from, to, promotion });
        setFen(game.fen());
        setSquareStyles(squareStyling({ pieceSquare: from, history: game.history({ verbose: true }) }));
        setCurrentMoveIndex(prevIndex => prevIndex + 1);
      }, 500);
    }
  }, [game]);

  useEffect(() => {
    if (moves && moves.length > 0 && currentMoveIndex === moves.length) {
      setCurrentMoveIndex(0);
      updateFenWithFirstMove();
      flashBoardGreen().then(() => {
        adjustRatingForTimeLimit(answeredCorrect);
        setCurrentPuzzle(null);
        setCurrentMoveIndex(0);
        fetchPuzzle(puzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame);
      });
    }
  }, [currentMoveIndex, moves]);

  const hasPiece = (square) => {
    const piece = game.get(square);
    return piece;
  };

  const onSquareClick = (square) => {
    setPromotionOpen(false);
    setPromotionDetails(null);
    const piece = game.get(square);
    const historicalStyles = squareStyling({ history: game.history({ verbose: true }) });

    if (square === pieceSquare) {
      setPieceSquare("");
      setSquareStyles(historicalStyles);
      return;
    }

    if (piece && piece.color === playerColor[0]) {
      const moves = game.moves({ square: square, verbose: true });
      const squaresToHighlight = moves.map(move => move.to);

      const darkSquare = 'var(--dark-square-color)';
      const lightSquare = 'var(--light-square-color)';

      const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
        const squareColor = (curr.charCodeAt(0) - 'a'.charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0 ? lightSquare : darkSquare;
        const highlightColor = squareColor === lightSquare ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)';

        if (hasPiece(curr)) {
          return {
            ...acc,
            [curr]: {
              background: `
                linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
                linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
                linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
                linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
              `,
              backgroundSize: "40% 40%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
            },
          };
        } else {
          return {
            ...acc,
            [curr]: {
              background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
              borderRadius: "50%",
            },
          };
        }
      }, {});

      setSquareStyles({
        ...historicalStyles,
        ...highlightStyles,
        [square]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }
      });
      setPieceSquare(square);
    } else {
      setSquareStyles(historicalStyles);
      if (pieceSquare) {
        setCurrentSquare(square);
        setReadyForMove(true);
      }
    }
  };

  useEffect(() => {
    if (readyForMove) {
      if (pieceSquare && currentSquare && pieceSquare !== currentSquare) {
        if (isPromotionMove(pieceSquare, currentSquare)) {
          setPromotionOpen(true);
          const sourceSquare = pieceSquare;
          const targetSquare = currentSquare;
          setPromotionDetails({ sourceSquare, targetSquare });
        } else {
          handleMove(pieceSquare, currentSquare);
        }
      }
      setReadyForMove(false);
      setPieceSquare("");
    }
  }, [readyForMove]);

  const squareStyling = ({ history }) => {
    const sourceSquare = history.length && history[history.length - 1].from;
    const targetSquare = history.length && history[history.length - 1].to;

    return {
      ...(history.length && {
        [sourceSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.3)"
        },
        [targetSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.5)"
        }
      })
    };
  };

  const handlePromotion = (piece) => {
    if (promotionDetails) {
      const { sourceSquare, targetSquare } = promotionDetails;
      handleMove(sourceSquare, targetSquare, piece);
      setPromotionOpen(false);
      setPromotionDetails(null);
    }
  };

  const isPromotionMove = (sourceSquare, targetSquare) => {
    const targetRank = targetSquare[1];
    const pieceOnSource = game.get(sourceSquare);

    return pieceOnSource &&
           pieceOnSource.type === 'p' &&
           ((pieceOnSource.color === 'w' && targetRank === '8') ||
            (pieceOnSource.color === 'b' && targetRank === '1'));
  };

const handleMove = (fromSquare, toSquare, promotion = 'q') => {
  if (isTimedOut) return;
  setBoardAwarenessQuiz(false);

  const legalMoves = game.moves({ verbose: true });
  const expectedMove = moves[currentMoveIndex];
  const expectedFrom = expectedMove.substring(0, 2);
  const expectedTo = expectedMove.substring(2, 4);

  const isLegal = legalMoves.some(move => move.from === fromSquare && move.to === toSquare);

  if (isLegal) {
    const moveResult = game.move({ from: fromSquare, to: toSquare, promotion });

    if (moveResult && (fromSquare === expectedFrom && toSquare === expectedTo || game.isCheckmate())) {
      setGame(new Chess(game.fen()));
      setFen(game.fen());
      setMoveHistory(prevHistory => [...prevHistory, moveResult]);
      setCurrentMoveIndex(prevIndex => prevIndex + 1);

      const newHighlightStyles = {
        [fromSquare]: { backgroundColor: "rgba(255, 255, 0, 0.3)" },
        [toSquare]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
      };
      setSquareStyles(newHighlightStyles);

      // Add extra time to the clock (e.g., 5 seconds)
      setTimeRemaining(prevTime => prevTime + 3);

    } else {
      flashBoardRed();
      setAnsweredCorrect(false);
      game.undo();
    }
  }
};

const encodePuzzleStats = (rating, accuracy = 0, count = 0) => {
  // Ensure rating, accuracy, and count have valid default values
  rating = rating || 1000;
  accuracy = accuracy || 0;
  count = count || 0;

  return `r${Math.round(rating)}a${accuracy.toFixed(1)}c${count}`;
};


  const flashBoardGreen = async () => {
    const allSquares = [];
    for (let row = 1; row <= 8; row++) {
      for (let col of 'abcdefgh') {
        allSquares.push(col + row);
      }
    }

    const flashStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "rgba(0, 255, 0, .5)" };
      return styles;
    }, {});

    const defaultStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "transparent" };
      return styles;
    }, {});

    for (let i = 0; i < 1; i++) {
      setSquareStyles(flashStyle);
      await new Promise(resolve => setTimeout(resolve, 400));
      setSquareStyles(defaultStyle);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  };

  const flashBoardRed = async () => {
    const allSquares = [];
    for (let row = 1; row <= 8; row++) {
      for (let col of 'abcdefgh') {
        allSquares.push(col + row);
      }
    }

    const currentStyles = { ...squareStyles };
    const flashStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "rgba(255, 0, 0, 0.5)" };
      return styles;
    }, {});

    setSquareStyles(flashStyle);
    await new Promise(resolve => setTimeout(resolve, 500));
    setSquareStyles(currentStyles);
  };

  const onDrop = ({ sourceSquare, targetSquare }) => {
    const promotion = 'q';
    const legalMoves = game.moves({ verbose: true });

    const moveIsLegal = legalMoves.some(move => move.from === sourceSquare && move.to === targetSquare);

    if (!moveIsLegal) {
      return 'snapback';
    }

    const piece = game.get(sourceSquare);
    const isPromotion = piece.type === 'p' && (targetSquare[1] === '8' || targetSquare[1] === '1');

    if (isPromotion) {
      setPromotionOpen(true);
      setPromotionDetails({ sourceSquare, targetSquare });
    } else {
      handleMove(sourceSquare, targetSquare);
    }
  };

  const handleGiveUp = async () => {
    setAnsweredCorrect(false);

    game.load(currentPuzzle?.FEN);
    setFen(game.fen());

    for (let index = 0; index < moves.length; index++) {
      const move = moves[index];
      const from = move.substring(0, 2);
      const to = move.substring(2, 4);
      const promotion = move.length > 4 ? move.charAt(4) : undefined;

      await new Promise(resolve => setTimeout(resolve, 1000));

      const result = game.move({ from, to, promotion });
      setFen(game.fen());

      const newHighlightStyles = {
        [from]: { backgroundColor: "rgba(255, 255, 0, 0.3)" },
        [to]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
      };
      setSquareStyles(newHighlightStyles);

      if (result && game.isCheckmate()) {
        break;
      }
    }

    await new Promise(resolve => setTimeout(resolve, 1000));

    await flashBoardGreen();

    adjustRatingForTimeLimit(false);
    setCurrentMoveIndex(0);
    updateFenWithFirstMove();
    setCurrentPuzzle(null);
    fetchPuzzle(puzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame);
  };

  const updateFenWithFirstMove = () => {
    if (currentPuzzle && currentPuzzle.FEN && currentPuzzle.Moves) {
      const game = new Chess(currentPuzzle.FEN);
      const moves = currentPuzzle.Moves.split(' ');

      if (moves.length > 0) {
        const firstMove = moves[0];
        game.move(firstMove);
        const newFen = game.fen();
        setPreviousFen(newFen);
      }
    }
  };

const openInAnalyze = () => {

  const fenFromElse = previousFen;
  const orientationFromElse = lastColor?.charAt(0).toUpperCase() + lastColor?.slice(1);
  const currentPageState = currentPuzzleState; // Preserve your current page's state

  navigate("/analyze", {
    state: {
      fenFromElse,
      orientationFromElse,
      returnPath: "/puzzles", // Current page
    },
  });
};

const openInAnalyze2 = () => {
  const fen = previousFen;
  const orientation = lastColor;

  // Redirect to /analyze with FEN as a query parameter
  window.location.href = `/analyze?fen=${encodeURIComponent(fen)}&orientation=${encodeURIComponent(orientation)}`;
};

  const updateShowQuiz = (show) => {
    setShowQuiz(show);
  };

  const handleQuizRequest = () => {
    setBoardAwarenessQuiz(true);
  };

  const updateQuizAnswer = (answer) => {
    setQuizAnswer(answer);
  };

  const handleCloseMessage = () => {
    setShowLoginMessage(false);
  };

const handleTimeOut = async () => {
  setIsTimedOut(true); // Set to true when timeout occurs
  setAnsweredCorrect(false); // Puzzle is failed if time runs out
  await adjustRatingForTimeLimit(false); // Update the rating as incorrect for the specific time limit
  flashBoardRed(); // Indicate incorrect answer or time-out visually

  game.load(currentPuzzle?.FEN);
  setFen(game.fen());

  for (let index = 0; index < moves.length; index++) {
    const move = moves[index];
    const from = move.substring(0, 2);
    const to = move.substring(2, 4);
    const promotion = move.length > 4 ? move.charAt(4) : undefined;

    // Pause for 1 second between moves
    await new Promise(resolve => setTimeout(resolve, 1000));

    const result = game.move({ from, to, promotion });
    setFen(game.fen());

    const newHighlightStyles = {
      [from]: { backgroundColor: "rgba(255, 255, 0, 0.3)" },
      [to]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
    };
    setSquareStyles(newHighlightStyles);

    if (result && game.isCheckmate()) {
      break;
    }
  }

  // Delay the next puzzle load by 1 second
  setTimeout(() => {
    setCurrentMoveIndex(0);
    setCurrentPuzzle(null);
    fetchPuzzle(puzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame);
  }, 1000); // 1-second delay
};


const adjustRating = async (wasSuccessful, currentPuzzleRating, puzzleAccuracy, puzzleCount) => {
  const newPuzzleCount = puzzleCount + 1;

  const weightedCount = Math.min(newPuzzleCount, 100);
  const previousSuccessfulPuzzles = (puzzleAccuracy / 100) * (weightedCount - 1);
  const successfulPuzzles = wasSuccessful ? previousSuccessfulPuzzles + 1 : previousSuccessfulPuzzles;

  const newPuzzleAccuracy = (successfulPuzzles / weightedCount) * 100;

  let ratingChange;
  const puzzleCountThresholds = [
    { threshold: 10, change: 90 },
    { threshold: 20, change: 50 },
    { threshold: 50, change: 30 },
    { threshold: 100, change: 20 },
    { threshold: Infinity, change: 10 }
  ];

  const foundThreshold = puzzleCountThresholds.find(threshold => newPuzzleCount < threshold.threshold);
  const baseChange = foundThreshold ? foundThreshold.change : 20;

  if (newPuzzleAccuracy > 86.5 && wasSuccessful) {
    ratingChange = baseChange / 2;
  } else if (newPuzzleAccuracy > 86.5 && !wasSuccessful) {
    ratingChange = -2;
  } else if (newPuzzleAccuracy < 83.5 && !wasSuccessful) {
    ratingChange = -baseChange;
  } else if (newPuzzleAccuracy < 83.5 && wasSuccessful) {
    ratingChange = 2;
  } else if (wasSuccessful) {
    ratingChange = 2;
  } else {
    ratingChange = -2;
  }

  let newPuzzleRating = currentPuzzleRating + ratingChange;

  // Clamp the rating between 400 and 3200
  if (newPuzzleRating < 400) {
    newPuzzleRating = 400;
  } else if (newPuzzleRating > 3200) {
    newPuzzleRating = 3200;
  }

  return {
    newPuzzleRating, // The new puzzle rating
    newPuzzleAccuracy, // The updated puzzle accuracy
    newPuzzleCount // The updated puzzle count
  };
};

const adjustRatingForTimeLimit = async (wasSuccessful) => {
  // Function to update rating and save to Firestore
  const updateRatingAndSave = async (currentPuzzleRating, ratingKey, puzzleAccuracy, puzzleCount) => {
    const { newPuzzleRating, newPuzzleAccuracy, newPuzzleCount } = await adjustRating(wasSuccessful, currentPuzzleRating, puzzleAccuracy, puzzleCount);

    // Update the state
    setPuzzleRating(newPuzzleRating);  // Single puzzle rating state
    setPuzzleAccuracy(newPuzzleAccuracy); // Set new accuracy
    setPuzzleCount(newPuzzleCount); // Set new count

    // Save the updated rating to Firestore
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      const puzzleStats = encodePuzzleStats(newPuzzleRating, newPuzzleAccuracy, newPuzzleCount);

      // If the document does not exist, create it
      if (!userDoc.exists()) {
        await setDoc(userRef, { [ratingKey]: puzzleStats });
      } else {
        // If the document exists, update the puzzle stats
        await updateDoc(userRef, {
          [ratingKey]: puzzleStats,
        });
      }
    }
  };

  // Determine which puzzle stats to update
  let ratingKey;
//  if (isEndgame) {
    if (false) {

    // Handle endgame ratings
    ratingKey = getRatingKey(timeLimit, true);
  } else {
    // Handle regular puzzle ratings
    ratingKey = getRatingKey(timeLimit, false);
  }

  // Fetch the current accuracy and count from the state before updating
  await updateRatingAndSave(puzzleRating, ratingKey, puzzleAccuracy, puzzleCount);
};


// Helper function to determine the correct rating key based on time limit and whether it's an endgame
const getRatingKey = (timeLimit, isEndgame) => {
  let ratingKey;
  if (isEndgame) {
    switch (timeLimit) {
      case 5:
        ratingKey = 'fiveSecondEndgameStats';
        break;
      case 10:
        ratingKey = 'tenSecondEndgameStats';
        break;
      case 30:
        ratingKey = 'thirtySecondEndgameStats';
        break;
      case 60:
        ratingKey = 'oneMinuteEndgameStats';
        break;
      default:
        ratingKey = 'noTimeLimitEndgameStats';
    }
  } else {
    switch (timeLimit) {
      case 5:
        ratingKey = 'fiveSecondPuzzleStats';
        break;
      case 10:
        ratingKey = 'tenSecondPuzzleStats';
        break;
      case 30:
        ratingKey = 'thirtySecondPuzzleStats';
        break;
      case 60:
        ratingKey = 'oneMinutePuzzleStats';
        break;
      default:
        ratingKey = 'noTimeLimitPuzzleStats';
    }
  }
  return ratingKey;
};

useEffect(() => {
  if (currentPuzzle) {
    // Clear any existing interval before starting a new one
    clearInterval(timerRef.current);

    // Only set the time limit and timer if timeLimit is not null
    if (timeLimit !== null) {
      setTimeRemaining(timeLimit); // Reset the time limit for the new puzzle

      // Set a new interval that decreases time every second
      timerRef.current = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current); // Clear the interval when time runs out
            handleTimeOut(); // Handle the timeout logic here
            return 0;
          }
          return prevTime - 1; // Decrement the remaining time
        });
      }, 1000); // Run the timer every second
    } else {
      // If no time limit, make sure timeRemaining is set to null or some value
      setTimeRemaining(null);
    }
  }

  // Cleanup the interval on unmount or when the puzzle changes
  return () => clearInterval(timerRef.current);
}, [currentPuzzle, timeLimit]);

    useEffect(() => {
        const checkIfFirstVisit = async () => {
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                if (!data.hasSeenTactics) {
                    setIsTourOpen(true); // Start tour if it’s the user’s first visit
                    await setDoc(userRef, { hasSeenTactics: true }, { merge: true });
                }
            }
        };

        if (user) {
            checkIfFirstVisit();
        }
    }, [user]);

const handleTimeLimitSelection = (time) => {
    setSelectedTimeLimit(time);  // Set the time limit
    setTimeLimit(null); // Directly set time limit to null (infinite mode)
    if (time === null) {
        handleCountdownComplete(null);
    } else {
        setShowCountdown(true);      // Show the countdown modal
    }
};

const handleCountdownComplete = (time) => {
    setTimeLimit(selectedTimeLimit); // Start the selected time limit
    setShowCountdown(false);         // Hide the countdown modal
    if (time === null) {
        setTimeLimit(null);
    };
};

return (
  <div className="game-layout">
    {showCountdown && <Countdown onCountdownComplete={handleCountdownComplete} />}
      <JoyrideWrapper
        steps={tacticsRide}
        run={isTourOpen}
        onFinish={() => setIsTourOpen(false)} // Close tour when finished
      />
      {/* Only show the clock if timeLimit is not null */}
      {timeLimit !== null && (
        <div className="chess-clock">
          <div className="time-display">
            <span className="clock-value">{timeRemaining !== null ? `${timeRemaining}` : '∞'}</span>
          </div>
        </div>
      )}
    {showLoginMessage && (
      <div className="login-message">
        <button className="close-button" onClick={handleCloseMessage}>×</button>
        <p><a href="/login">Log in</a> to save your puzzle data.</p>
      </div>
    )}

    {/* Main container to hold both chessboard and buttons */}
    <div className='chessboard-container'>
      <ChessboardCustom
        position={fen}
        onMove={handleMove}
        degreeOfBlindness='Normal'
        orientation={playerColor}
        squareStyles={squareStyles}
        onSquareClick={onSquareClick}
        onDrop={onDrop}
        updateChessboardSize={setChessboardSize}
        showQuiz={showQuiz}
        updateQuizAnswer={updateQuizAnswer}
      />

      {boardAwarenessQuiz && (
        <QuizComponent
          fen={fen}
          playerColor={playerColor}
          chessboardSize={chessboardSize}
          updateShowQuiz={updateShowQuiz}
          quizAnswer={quizAnswer}
          request="true"
        />
      )}

    </div>

    {promotionOpen && (
      <div className='promotion-modal-container'>
        <PromotionModal
          onPromote={handlePromotion}
          pieces={['q', 'r', 'n', 'b']}
          color={playerColor === 'white' ? 'w' : 'b'}
        />
      </div>
    )}

{/* Info Container */}
<div className="info-container">
    <button onClick={() => setIsTourOpen(true)} className="help-link">
        Help
    </button>
        {/* Rating and Accuracy */}
        <div className="rating-accuracy">
          <div className="rating-container">
            <div
              className="accuracy-box"
              style={{
                boxShadow: `0 0 10px ${getAccuracyColor(puzzleAccuracy)}`,
              }}
            >
              <strong>{puzzleAccuracy ? `${puzzleAccuracy.toFixed(1)}%` : '0.0%'}</strong>
            </div>
            <div className="rating-box">
              {puzzleRating}
            </div>
          </div>
          <div className="accuracy-feedback">
            {puzzleAccuracy > 87.5
              ? 'Increasing Difficulty'
              : puzzleAccuracy < 82.5
              ? 'Decreasing Difficulty'
              : 'Optimal Zone'}
          </div>
        </div>


    <div className="time-controls">
      <div className="time-buttons">
        <button onClick={() => handleTimeLimitSelection(5)}>5s</button>
        <button onClick={() => handleTimeLimitSelection(10)}>10s</button>
        <button onClick={() => handleTimeLimitSelection(30)}>30s</button>
        <button onClick={() => handleTimeLimitSelection(60)}>60s</button>
        <button onClick={() => handleTimeLimitSelection(null)} style={{ fontSize: '24px', padding: '4px 8px' }}>
          &#8734;
        </button>
      </div>
    </div>


  {/* Endgame Filter */}
  <div className="endgame-filter">
    <button
      onClick={() => setIsEndgame(!isEndgame)}
      className={isEndgame ? "active-filter" : "inactive-filter"}
    >
      Endgames
    </button>
  </div>

  {/* Buttons */}
<div className="buttons-container">
  <div className="puzzle-button-row">
    <button className="icon-options-button" onClick={handleGiveUp}>
      <img src={process.env.PUBLIC_URL + "/buttons/resigns.png"} alt="Give Up" />
    </button>
    <button className="icon-options-button" onClick={openInAnalyze}>
      <img src={process.env.PUBLIC_URL + "/buttons/analyze.png"} alt="Analyze" />
    </button>
    <button className="icon-options-button" onClick={handleQuizRequest}>
      <img src={process.env.PUBLIC_URL + "/buttons/eyeball.png"} alt="Awareness Quiz" />
    </button>
  </div>
</div>

</div>
  </div>
);
}

export default TimedPuzzles;
