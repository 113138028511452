import React, { useState } from "react";

const ChatGPTTest = () => {
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const model = 'gpt-3.5-turbo';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setResponse("");

    try {
      const res = await fetch("https://us-central1-train-chess.cloudfunctions.net/chatGPT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, model }),
      });

      if (!res.ok) {
        throw new Error("Failed to fetch the response from ChatGPT.");
      }

      const data = await res.json();
      setResponse(data.reply);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Test ChatGPT</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="message">Enter your message:</label>
        <input
          type="text"
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ margin: "10px", padding: "5px", width: "300px" }}
          required
        />
        <button type="submit" style={{ padding: "5px 10px" }}>
          Send
        </button>
      </form>
      {response && (
        <div style={{ marginTop: "20px", color: "green" }}>
          <h3>Response:</h3>
          <p>{response}</p>
        </div>
      )}
      {error && (
        <div style={{ marginTop: "20px", color: "red" }}>
          <h3>Error:</h3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default ChatGPTTest;
