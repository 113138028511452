import React, { useState, useEffect, useRef } from "react";
import { Chess } from "chess.js";
import "../styles/engineMoves.css";

const EngineMoves = ({ fen, onMoveData, game }) => {
  const [stockfishWorker, setStockfishWorker] = useState(null);
  const [analysisLines, setAnalysisLines] = useState([]);
  const [isEngineReady, setIsEngineReady] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [multiPV, setMultiPV] = useState(2); // Default to 3 lines of analysis
  const chess = new Chess(); // Chess instance to convert moves
  const fenRef = useRef(fen);

useEffect(() => {
  fenRef.current = fen; // Update the ref whenever fen changes
}, [fen]);

    // Initialize Stockfish when the component mounts
    const initializeStockfish = () => {
        const cpuCores = navigator.hardwareConcurrency || 1;
//        const workerName = typeof SharedArrayBuffer !== "undefined" ?
//          "stockfish-nnue-16.js" :
//          "stockfish-nnue-16-single.js";
        const workerName = "stockfish-nnue-16-single.js";

        const worker = new Worker(workerName);
      worker.onmessage = (e) => {
        const message = e.data;
        if (message === "uciok") {
//          console.log("Stockfish initialized and ready.");
          setIsEngineReady(true);
          worker.postMessage("setoption name MultiPV value 2");
          worker.postMessage(`setoption name Threads value ${cpuCores}`);
//          console.log("Threads set to " + cpuCores)

        }

        if (message.startsWith("info depth")) {
          parseEngineOutput(message);
        }
      };

      worker.postMessage("uci");
      return worker;
    };

const parseEngineOutput = (message) => {
//  console.log('Engine: ' + message);
  const currentFen = fenRef.current;
  chess.load(currentFen);
  const isBlackTurn = chess.turn() === "b";

 const depthMatch = message.match(/\bdepth (\d+)\b/);
  if (depthMatch && parseInt(depthMatch[1], 10) < 10) {
    setAnalysisLines(Array(multiPV).fill({ evaluation: "?", moves: "" }));
    onMoveData(Array(multiPV).fill({ from: null, to: null, color: null }));
//    console.log(`Depth is ${depthMatch[1]} which is below 10. Skipping.`);
    return; // Ignore low-depth evaluations
  }

  const lineMatch = message.match(/multipv (\d+) .* pv (.+)/);
  const evalMatch = message.match(/score (cp|mate) (-?\d+)/);

  if (lineMatch) {
    const lineNumber = parseInt(lineMatch[1], 10);
//    if (lineNumber > multiPV) return; // Ignore lines beyond MultiPV

    const moves = lineMatch[2].split(" ");
    if (moves.length > 0) {
      let evaluation = null;
      if (evalMatch) {
        const [type, value] = [evalMatch[1], parseInt(evalMatch[2], 10)];
        evaluation = type === "cp"
          ? ((isBlackTurn ? -value : value) / 100).toFixed(1)
          : `#${isBlackTurn ? -value : value}`;
      }

      const fromSquare = moves[0]?.slice(0, 2);
      const toSquare = moves[0]?.slice(2, 4);
      const colors = [
        "rgba(227, 184, 102, 1)",
        "rgba(227, 184, 102, .7)",
        "rgba(227, 184, 102, .45)",
        "rgba(227, 184, 102, .35)",
        "rgba(227, 184, 102, .2)",
      ];
      const color = colors[lineNumber - 1] || "rgba(0, 255, 0, 0.5)";

      onMoveData((prev) => {
        const updatedData = Array.isArray(prev) ? [...prev] : [];
        updatedData[lineNumber - 1] = { from: fromSquare, to: toSquare, color };
        return updatedData;
      });

      const startingMoveNumber = parseInt(currentFen.split(" ")[5], 10);
      const fullLine = moves.map((move, index) => {
        const allMoves = chess.moves({ verbose: true });
        const matchingMove = allMoves.find((m) => `${m.from}${m.to}` === move);
        if (matchingMove) {
          chess.move(matchingMove.san);
          return chess.turn() === "w"
            ? `${startingMoveNumber + Math.floor(index / 2)}. ${matchingMove.san}`
            : matchingMove.san;
        }
        return "";
      });

      setAnalysisLines((prev) => {
        const updatedLines = [...prev];
        updatedLines[lineNumber - 1] = {
          evaluation: evaluation || "?",
          moves: fullLine.join(" "),
        };
        return updatedLines;
      });
    }
  }
};

const startAnalysis = async () => {
  if (!stockfishWorker || !isEngineReady) {
    const worker = initializeStockfish();
    setStockfishWorker(worker); // No need to await here since setState is synchronous
    // Wait for the worker to initialize
    await new Promise((resolve) => {
      setIsEngineReady(true); // Check every 50ms
      resolve();
    });
  }

  // Clear previous analysis lines and start analyzing
  setAnalysisLines(Array(multiPV).fill({ evaluation: "?", moves: "" }));
  onMoveData(Array(multiPV).fill({ from: null, to: null, color: null }));
  setIsAnalyzing(true);

  // Ensure `stockfishWorker` is available after initialization
  if (stockfishWorker) {
    stockfishWorker.postMessage("ucinewgame");
    stockfishWorker.postMessage(`position fen ${fen}`);
    stockfishWorker.postMessage("go");
  }
};

  const stopAnalysis = () => {
    if (stockfishWorker) {
        stockfishWorker.terminate();
    }
    setIsEngineReady(false);
    setIsAnalyzing(false);
    setAnalysisLines(Array(multiPV).fill({ evaluation: "?", moves: "" }));
    onMoveData(Array(multiPV).fill({ from: null, to: null, color: null }));
    };

  useEffect(() => {
    if (fen && stockfishWorker) {
//      console.log("FEN changed, starting analysis:", fen);

      // Clear previous analysis and start analyzing the new position
      setAnalysisLines(Array(multiPV).fill({ evaluation: "?", moves: "" }));
      onMoveData(Array(multiPV).fill({ from: null, to: null, color: null }));

      stockfishWorker.postMessage("stop"); // Stop any ongoing analysis
      stockfishWorker.postMessage("ucinewgame");
      stockfishWorker.postMessage(`setoption name MultiPV value ${multiPV}`);
      stockfishWorker.postMessage(`position fen ${fen}`);
      stockfishWorker.postMessage("go");
    }
  }, [fen, isEngineReady, multiPV]);

  useEffect(() => {
    startAnalysis();
  }, []);

return (
  <div className="engine-moves-container">
    <div className="control-panel" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <button
        onClick={isAnalyzing ? stopAnalysis : startAnalysis}
        className={`stockfish-button ${isAnalyzing ? "active" : ""}`}
      >
        {isAnalyzing ? "Off" : "Stockfish"}
      </button>
      {isAnalyzing && (
        <div className="multipv-controls" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <label htmlFor="multipv" style={{ display: 'none' }}></label>
          <button
            onClick={() => setMultiPV((prev) => Math.max(1, prev - 1))} // Decrease lines, minimum is 1
            disabled={multiPV <= 1} // Disable button if at minimum
            className="direction-button"
          >
            -
          </button>
          <span>{multiPV}</span> {/* Display current MultiPV value */}
          <button
            onClick={() => setMultiPV((prev) => Math.min(5, prev + 1))} // Increase lines, maximum is 4
            disabled={multiPV >= 5} // Disable button if at maximum
            className="direction-button"
          >
            +
          </button>
        </div>
      )}
    </div>

    <div className="analysis-lines">
      {isAnalyzing
        ? analysisLines.slice(0, multiPV).map((line, index) => (
            <div key={index} className="engine-line">
              <span className="evaluation">
                <strong>
                  {line.evaluation > 0 ? `+${line.evaluation}` : line.evaluation}
                </strong>
              </span>
              <span className="line-content">{line.moves || "Waiting..."}</span>
            </div>
          ))
        : null}
    </div>
  </div>
);
};

export default EngineMoves;
