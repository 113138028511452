//import React from 'react';
//import { Chess } from "chess.js";
//
//export const renderMoveHistory = (component, history, squareStyling) => {
//  const MIN_ROWS = 5; // Minimum number of rows to display initially
//
//const goToStartPosition = () => {
//  if (component.setState) {
//    // Class component case
//    component.setState({
//      displayFen: component.state.initialFen,
//      selectedMoveIndex: -1,
//    });
//  } else if (component.state?.isMoveTrainer) {
//    // Functional component (MoveTrainer) case
//    component.setDisplayFen(component.state.initialFen);
//    component.setSelectedMoveIndex(-1);
//  } else if (component.state?.isGameAnalysis) {
//    component.setDisplayFen('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
//    component.setSelectedMoveIndex(-1);
//  }else if (component.setFen && typeof component.setFen === 'function') {
//    component.setFen(component.state.initialFen);
//    component.setSelectedMoveIndex(-1);
//    component.setSquareStyles();
//  } else {
//    console.error("Component does not have valid state setters. Unable to update.");
//  }
//};
//
//
//  const goToPreviousMove = () => {
//    const selectedMoveIndex = component.state?.selectedMoveIndex ?? component.selectedMoveIndex;
//    if (selectedMoveIndex <= 0) {
//      if (selectedMoveIndex === 0) {
//        goToStartPosition();
//      }
//    } else {
//      const newMoveIndex = selectedMoveIndex - 1;
//      goToMove(component, newMoveIndex, squareStyling);
//    }
//  };
//
//  const goToNextMove = () => {
//    const selectedMoveIndex = component.state?.selectedMoveIndex ?? component.selectedMoveIndex;
//    const historyLength = component.state?.history.length ?? history.length;
//    const newMoveIndex = Math.min(selectedMoveIndex + 1, historyLength - 1);
//    goToMove(component, newMoveIndex, squareStyling);
//  };
//
//const goToMove = (component, moveIndex, squareStyling) => {
//  const historyData = component.state?.history ?? component.history;
//  const initialFen = component.state?.initialFen ?? component.initialFen;
//
//  // Check if history is defined and is an array
//  if (!historyData || !Array.isArray(historyData)) {
//    console.error("History is undefined or not an array.");
//    return;
//  }
//
//  const temporaryGame = new Chess(initialFen);
//
//  // Apply the moves up to the given index
//  historyData.slice(0, moveIndex + 1).forEach(move => {
////    console.log('attempting to play: ' + JSON.stringify(move));
//    temporaryGame.move(move.san);
//  });
//
//  const tempFen = temporaryGame.fen();
//  const updatedHistory = temporaryGame.history({ verbose: true });
//  const newSquareStyles = squareStyling({ pieceSquare: "", history: updatedHistory });
//
//  // Check if component is a functional component (e.g., BoardDisplay)
//  if (component.setFen && typeof component.setFen === 'function') {
//    console.log("Updating functional component (BoardDisplay). ", newSquareStyles);
//    component.setFen(tempFen);
//    component.setSquareStyles(newSquareStyles);
//    component.setSelectedMoveIndex(moveIndex);
//  }
//  // Check if isMoveTrainer is part of component's state (for functional component MoveTrainer)
//  else if (component.state?.isMoveTrainer === true) {
////    console.log("Updating functional component (MoveTrainer). Setting displayFen to:", tempFen);
////    console.log('new square styles: ' + JSON.stringify(newSquareStyles));
//    component.setDisplayFen(tempFen);
//    component.setSquareStyles(newSquareStyles);
//    component.setSelectedMoveIndex(moveIndex);
//    if (component.setIsViewingCurrentPosition) {
//      component.setIsViewingCurrentPosition(false);
//    }
//  }
//  else if (component.state?.isGameAnalysis === true) {
//    component.setDisplayFen(tempFen);
//    component.setSquareStyles(newSquareStyles);
//    component.setSelectedMoveIndex(moveIndex);
//  }
//  // For class components (which use setState)
//  else if (component.setState) {
//    console.log("Updating class component. Setting displayFen to:", tempFen);
//    component.setState({
//      displayFen: tempFen,
//      squareStyles: newSquareStyles,
//      isViewingCurrentPosition: false,
//      selectedMoveIndex: moveIndex,
//    });
//  } else {
//    console.error("Component does not have valid state setters. Unable to update.");
//  }
//};
//
//  const returnToCurrentPosition = (component, moveIndex) => {
//    if (component.setState) {
//      component.setState({
//        displayFen: null,
//        isViewingCurrentPosition: true,
//        selectedMoveIndex: moveIndex,
//      });
//    } else {
//      component.setFen(null);
//      component.setIsViewingCurrentPosition(true);
//      component.setSelectedMoveIndex(moveIndex);
//    }
//  };
//
//  const copyFenToClipboard = () => {
//    const fen = component.state?.fen ?? component.fen;
//    navigator.clipboard.writeText(fen).then(() => {
//      console.log('FEN copied to clipboard:', fen);
//    }).catch(err => {
//      console.error('Failed to copy FEN to clipboard:', err);
//    });
//  };
//
//  // Create empty rows for placeholders if there are less than MIN_ROWS entries
//  const rows = history.reduce((acc, move, index) => {
//    if (index % 2 === 0) {
//      acc.push([move]);
//    } else {
//      acc[acc.length - 1].push(move);
//    }
//    return acc;
//  }, []);
//
//  // Add empty rows to ensure at least MIN_ROWS are displayed
//  while (rows.length < MIN_ROWS) {
//    rows.push([]);
//  }
//
//  // Check if the component is MoveTrainer using the identifier passed in the state
//  const isMoveTrainer = component.state?.isMoveTrainer ?? component.isMoveTrainer;
//  const isGameAnalysis = component.state?.isGameAnalysis ?? component.isGameAnalysis;
//  const mode = component.state?.mode ?? component.mode;
//
//return (
//  <div className="move-history-container">
//    <div className="history-controls">
//      <button onClick={goToStartPosition}>
//        <img src={process.env.PUBLIC_URL + "/buttons/backwardAll.png"} alt="Start Position" />
//      </button>
//      <button onClick={goToPreviousMove}>
//        <img src={process.env.PUBLIC_URL + "/buttons/backward.png"} alt="Previous Move" />
//      </button>
//      <button onClick={goToNextMove}>
//        <img src={process.env.PUBLIC_URL + "/buttons/forward.png"} alt="Next Move" />
//      </button>
//      <button onClick={() => goToMove(component, history.length - 1, squareStyling)}>
//        <img src={process.env.PUBLIC_URL + "/buttons/forwardAll.png"} alt="Last Move" />
//      </button>
//    </div>
//
//    <div className="move-history-scroll" ref={component.moveHistoryRef}>
//      <table>
//        <tbody>
//          {rows.map((pair, pairIndex) => (
//            <tr key={pairIndex}>
//              <td className="move-number">{pairIndex + 1}</td>
//              <td
//                onClick={() => pair[0] && goToMove(component, pairIndex * 2, squareStyling)}
//                className={`${component.state?.selectedMoveIndex === pairIndex * 2 || component.selectedMoveIndex === pairIndex * 2 ? 'selected ' : ''}move`}>
//                {pair[0] ? pair[0].san : ""}
//              </td>
//              <td
//                onClick={() => pair[1] && goToMove(component, pairIndex * 2 + 1, squareStyling)}
//                className={`${pair[1] && (component.state?.selectedMoveIndex === pairIndex * 2 + 1 || component.selectedMoveIndex === pairIndex * 2 + 1) ? 'selected ' : ''}move`}>
//                {pair[1] ? pair[1].san : ""}
//              </td>
//            </tr>
//          ))}
//        </tbody>
//      </table>
//    </div>
//
//    {/* Conditional check for BoardDisplay to hide bottom buttons */}
//    {!(component.setFen && typeof component.setFen === 'function') && (
//      <div className="history-controls bottom-buttons">
//        {isMoveTrainer && (
//          mode === 'addLine' || mode === 'edit' ? (
//            <>
//              <button onClick={component.handleTakeBackMove}>
//                <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
//              </button>
//              <button onClick={() => component.setState ? component.setState({ showSaveModal: true }) : component.setShowSaveModal(true)}>
//                <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
//              </button>
//              <button onClick={component.openInLichess}>
//                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
//              </button>
//            </>
//          ) : (
//            <>
//            <button onClick={component.handleHint}>
//              <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Hint" />
//            </button>
//              <button onClick={component.openInLichess}>
//                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
//              </button>
//            </>
//          )
//        )}
//      {isGameAnalysis && (
//        <div className="history-controls bottom-buttons">
//          {/* Save Button */}
//          <button onClick={() => component.setState ? component.setState({ showSaveModal: true }) : component.setShowSaveModal(true)}>
//            <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
//          </button>
//          {/* Open in Lichess Button */}
//          <button onClick={component.openInLichess}>
//            <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
//          </button>
//          {/* Show Best Move Button */}
//          <button onClick={component.showBestMove}>
//            <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Show Best Move" />
//          </button>
//        </div>
//      )}
//      </div>
//    )}
//  </div>
//);
//};

import React from 'react';
import { Chess } from "chess.js";

export const renderMoveHistory = (component, history, squareStyling, sidelines = [], sidelinesAllowed = false) => {
  const MIN_ROWS = 5; // Minimum number of rows to display initially

  const goToStartPosition = () => {
    if (component.state?.isMoveTrainer || component.state?.isAnalyze) {
      component.setDisplayFen(component.state.initialFen);
      component.setSelectedMoveIndex(-1);
    } else {
      component.setDisplayFen('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
      component.setSelectedMoveIndex(-1);
    }
  };

  const goToMove = (moveIndex) => {
  if (component.setSelectedSidelineMoveIndex) {
    component.setSelectedSidelineMoveIndex(-1);
  }
    const historyData = component.state?.history;
    const initialFen = component.state?.initialFen;

    if (!historyData || !Array.isArray(historyData)) {
      console.error("History is undefined or not an array.");
      return;
    }

    const temporaryGame = new Chess(initialFen);
    historyData.slice(0, moveIndex + 1).forEach((move) => temporaryGame.move(move.san));

    const tempFen = temporaryGame.fen();
    const updatedHistory = temporaryGame.history({ verbose: true });
    const newSquareStyles = squareStyling({ pieceSquare: "", history: updatedHistory });

    component.setDisplayFen(tempFen);
    component.setSquareStyles(newSquareStyles);
    component.setSelectedMoveIndex(moveIndex);
  };

  const goToPreviousMove = () => {
    const selectedMoveIndex = component.state?.selectedMoveIndex ?? -1;
    if (selectedMoveIndex > 0) {
      goToMove(selectedMoveIndex - 1);
    } else {
      goToStartPosition();
    }
  };

  const goToNextMove = () => {
    const selectedMoveIndex = component.state?.selectedMoveIndex ?? -1;
      // Check if moveIndex is within valid range
      if (selectedMoveIndex >= history.length - 1) {
//        console.warn("Move index is out of bounds.");
        return;
      }
    const newMoveIndex = Math.min(selectedMoveIndex + 1, history.length - 1);
    goToMove(newMoveIndex);
  };

  const rows = history.reduce((acc, move, index) => {
    if (index % 2 === 0) {
      acc.push([move]);
    } else {
      acc[acc.length - 1].push(move);
    }
    return acc;
  }, []);

  while (rows.length < MIN_ROWS) {
    rows.push([]);
  }

  const selectSideline = (sidelineIndex) => {
    const selectedSideline = sidelines[component.state?.selectedMoveIndex || 0][sidelineIndex];
    const temporaryGame = new Chess(component.state.initialFen);

    // Replay mainline moves
    history.slice(0, component.state?.selectedMoveIndex + 1).forEach((move) => temporaryGame.move(move.san));

    // Replay sideline moves
    selectedSideline.moves.forEach((move) => temporaryGame.move(move));

    // Update state
    component.setDisplayFen(temporaryGame.fen());
  };

const deleteSideline = (selectedIndex, sidelineIndex) => {
  component.setSidelines((prev) => {
    const updatedSidelines = [...prev];
    updatedSidelines[selectedIndex].splice(sidelineIndex, 1); // Remove the sideline
    if (updatedSidelines[selectedIndex].length === 0) {
      delete updatedSidelines[selectedIndex]; // Clean up if no sidelines are left
    }
    return updatedSidelines;
  });
};

const navigateSideline = (selectedIndex, sidelineIndex, direction) => {
//  console.log("Navigating sideline...");
//  console.log(`Selected Index: ${selectedIndex}`);
//  console.log(`Sideline Index: ${sidelineIndex}`);
//  console.log(`Direction: ${direction}`);

  const sideline = sidelines[selectedIndex]?.[sidelineIndex];
  if (!sideline) {
    console.error("Sideline not found.");
    return;
  }

//  console.log("Current Sideline:", sideline);

  const currentMoveIndex = component.selectedSidelineMoveIndex ?? 0;
//  console.log(`Current Move Index: ${currentMoveIndex}`);

  const nextMoveIndex =
    direction === "next"
      ? Math.min(currentMoveIndex + 1, sideline.moves.length - 1)
      : Math.max(currentMoveIndex - 1, -1);

//  console.log(`Next Move Index: ${nextMoveIndex}`);

  // Use the initial FEN of the sideline for navigation
  const tempGame = new Chess(sideline.initialFen);
//  console.log("Loaded Initial FEN for Sideline:", sideline.initialFen);

  // Apply moves up to the target index
  sideline.moves.slice(0, nextMoveIndex + 1).forEach((move, idx) => {
//    console.log(`Applying move ${idx + 1}: ${move}`);
    tempGame.move(move);
  });

  const updatedFen = tempGame.fen();
//  console.log("Updated FEN after navigation:", updatedFen);

  component.setDisplayFen(updatedFen);
  component.setSelectedSidelineIndex(sidelineIndex);
  component.setSelectedSidelineMoveIndex(nextMoveIndex);

//  console.log("State updated:");
//  console.log(`Selected Sideline Index: ${sidelineIndex}`);
//  console.log(`Selected Sideline Move Index: ${nextMoveIndex}`);
};

const selectSidelineMove = (selectedIndex, sidelineIndex, moveIndex) => {
  const sideline = sidelines[selectedIndex][sidelineIndex];
  if (!sideline) return;

  const tempGame = new Chess(sideline.initialFen);
  sideline.moves.slice(0, moveIndex + 1).forEach((move) => tempGame.move(move));

  component.setDisplayFen(tempGame.fen());
  component.setSelectedSidelineIndex(sidelineIndex);
  component.setSelectedSidelineMoveIndex(moveIndex);
};

const scrollToSelectedMove = (ref, sideline) => {
  if (
    ref &&
    component.state?.selectedSidelineIndex !== undefined &&
    component.state?.selectedSidelineMoveIndex !== undefined &&
    sideline.moves.length > 0
  ) {
    const selectedMove = ref.querySelector(
      `.sideline-move:nth-child(${component.state.selectedSidelineMoveIndex + 1})`
    );
    if (selectedMove) {
      selectedMove.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
};

  const isMoveTrainer = component.state?.isMoveTrainer ?? false;
  const isGameAnalysis = component.state?.isGameAnalysis ?? false;
  const isAnalyze = component.state?.isAnalyze ?? false;

  const mode = component.state?.mode ?? "";

  return (
    <div className="move-history-container">
      <div className="history-controls">
        <button onClick={goToStartPosition}>
          <img src={process.env.PUBLIC_URL + "/buttons/backwardAll.png"} alt="Start Position" />
        </button>
        <button onClick={goToPreviousMove}>
          <img src={process.env.PUBLIC_URL + "/buttons/backward.png"} alt="Previous Move" />
        </button>
        <button onClick={goToNextMove}>
          <img src={process.env.PUBLIC_URL + "/buttons/forward.png"} alt="Next Move" />
        </button>
        <button onClick={() => goToMove(history.length - 1)}>
          <img src={process.env.PUBLIC_URL + "/buttons/forwardAll.png"} alt="Last Move" />
        </button>
      </div>

      <div className="move-history-scroll" ref={component.moveHistoryRef}>
        <table>
          <tbody>
            {rows.map((pair, pairIndex) => (
              <tr key={pairIndex}>
                <td className="move-number">{pairIndex + 1}</td>
                <td
                  onClick={() => pair[0] && goToMove(pairIndex * 2)}
                  className={`${component.state?.selectedMoveIndex === pairIndex * 2 ? "selected " : ""}move`}
                >
                  {pair[0]?.san || ""}
                </td>
                <td
                  onClick={() => pair[1] && goToMove(pairIndex * 2 + 1)}
                  className={`${component.state?.selectedMoveIndex === pairIndex * 2 + 1 ? "selected " : ""}move`}
                >
                  {pair[1]?.san || ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    {sidelinesAllowed &&
      sidelines[component.state?.selectedMoveIndex]?.length > 0 && (
        <div className="sideline-container" ref={component.sidelineRef}>
          {sidelines[component.state?.selectedMoveIndex].map(
            (sideline, index) => (
              <div key={index} className="sideline-entry">
                {/* Header and Navigation */}
                <div className="sideline-header">
                  <button
                    className="nav-arrow"
                    onClick={() =>
                      navigateSideline(
                        component.state?.selectedMoveIndex,
                        index,
                        "prev"
                      )
                    }
                    title="Previous Move"
                  >
                    ←
                  </button>
                  {/* Moves */}
                  <div className="sideline-move-list">
                    {sideline.moves.map((move, moveIndex) => (
                      <span
                        key={moveIndex}
                        className={`sideline-move ${
                          component.selectedSidelineMoveIndex === moveIndex &&
                          component.selectedSidelineIndex === index
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          selectSidelineMove(
                            component.state?.selectedMoveIndex,
                            index,
                            moveIndex
                          )
                        }
                      >
                        {move}
                      </span>
                    ))}
                  </div>

                  <button
                    className="nav-arrow"
                    onClick={() =>
                      navigateSideline(
                        component.state?.selectedMoveIndex,
                        index,
                        "next"
                      )
                    }
                    title="Next Move"
                  >
                    →
                  </button>
                  <button
                    className="delete-sideline-btn"
                    onClick={() =>
                      deleteSideline(component.state?.selectedMoveIndex, index)
                    }
                    title="Delete Line"
                  >
                    –
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        )}

      <div className="history-controls bottom-buttons">
        {isMoveTrainer && (
          mode === "addLine" || mode === "edit" ? (
            <>
              <button onClick={component.handleTakeBackMove}>
                <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
              </button>
              <button onClick={() => component.setShowSaveModal(true)}>
                  <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
                </button>
              <button onClick={component.openInLichess}>
                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
              </button>
            </>
          ) : (
            <>
              <button onClick={component.handleHint}>
                <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Hint" />
              </button>
              <button onClick={component.openInLichess}>
                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
              </button>
            </>
          )
        )}
        {isGameAnalysis && (
          <>
            {component?.state?.checkingWithAi ? (
              <>
                <button onClick={component.flipBoard}>
                  <img src={process.env.PUBLIC_URL + "/buttons/flip.png"} alt="Flip Board" />
                </button>
                {/* Hint Button */}
                <button onClick={component.handleHint}>
                  <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Hint" />
                </button>
                {/* Save Button */}
                <button onClick={() => component.setShowSaveModal(true)}>
                  <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
                </button>
                {/* Open in Lichess Button */}
                <button onClick={component.openInLichess}>
                  <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
                </button>
              </>
            ) : (
              <>
                <button onClick={component.flipBoard}>
                  <img src={process.env.PUBLIC_URL + "/buttons/flip.png"} alt="Flip Board" />
                </button>
                {/* Save Button */}
                <button onClick={() => component.setShowSaveModal(true)}>
                  <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
                </button>
                {/* Open in Lichess Button */}
                <button onClick={component.openInLichess}>
                  <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
                </button>
              </>
            )}
          </>
        )}
        {isAnalyze && (
          <>
            {/* Flip Board Button */}
            <button onClick={component.flipBoard}>
              <img src={process.env.PUBLIC_URL + "/buttons/flip.png"} alt="Flip Board" />
            </button>

            {/* Hint Button */}
              <button onClick={component.handleTakeBack}>
                <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
              </button>

            {/* Open Share */}
            <button onClick={component.openShareModal}>
              <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open Share Modal" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
