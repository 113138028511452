import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler
import PageEndTag from '../../Integrations/pageEndTag'; // Scroll enabler if needed
import DiscussionBox from '../../Integrations/DiscussionBox';

const GameAnalysisHelp = () => {
  const articleId = 'GameAnalysis';
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Game Analysis Tool</h1>

      <section className="how-to-use">
        <h2>Maximizing Your Learning: Attempt Before Reviewing</h2>
        <p>
          Our <a href="/gameanalysis">Game Analysis Tool</a> is designed to help you learn from your games more effectively. Research shows that the strongest learning occurs when you first attempt to solve a problem on your own before being given the solution. <a href="https://medium.com/@HaPi31415/problem-thinking-and-the-problem-with-the-solution-first-education-system-7ccd9d5fb7" target="_blank" rel="noopener noreferrer">A short Medium.com essay</a> highlights the importance of the struggle of solving a problem without a solution (at least one that is available to you) in building long-term memory and understanding. More information on that topic can be found in a study <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC10357100/" target="_blank" rel="noopener noreferrer">here</a>. As you go through the game, you are forced to analyze moves independently before consulting the engine's suggestions.
        </p>
      </section>

      <section className="rating-system">
        <h2>Our Unique Rating System</h2>
        <p>
          To enhance your understanding, our tool includes a simple <strong>move rating system</strong> allowing for user-friendly assessment. After each move, you'll have the opportunity to rate the strength of you move on a scale from 1-5:
        </p>
        <ul>
          <li><strong>1:</strong> Blunder</li>
          <li><strong>5:</strong> An Excellent Move</li>
        </ul>
        <p>
          Once you've rated all the moves, the engine will provide its own evaluation. If your rating deviates significantly from the engine's assessment, the move will be flagged for <strong>further review</strong>. This system helps pinpoint areas where your understanding diverges from optimal play, pin pointing moves for further study.
        </p>
      </section>

      <section className="flow-of-review">
        <h2>How to Use the Game Analysis Tool</h2>
        <h3>Step 1: Choose a Game</h3>
        <p>
          Begin by selecting a previously played game from the sidebar. Games are sorted by date, and you can view basic information such as result, opponent, and platform (Chess.com or Lichess). Click on a game to load it into the analysis tool.
        </p>
        <h3>Step 2: Analyze Move by Move</h3>
        <p>
          Once the game is loaded, you'll enter the <strong>review interface</strong>. Navigate through each move using the provided buttons or by clicking on moves in the move history. For each move:
        </p>
        <ul>
          <li>Rate the strength of the move.</li>
          <li>Optionally, add comments to explain your thought process.</li>
          <li>Compare your rating and reasoning with the engine's evaluation.</li>
        </ul>
        <p>
          If discrepancies arise between your rating and the engine's, the tool will highlight those moves for further reflection.
        </p>

        <h3>Step 3: Reviewing Problematic Moves</h3>
        <p>
          After completing the analysis, the tool will summarize moves where your rating differed significantly from the engine's. Use this opportunity to revisit these moves and strengthen your understanding.
        </p>
      </section>

        <PageEndTag />
        <DiscussionBox articleId={articleId} title='Join the Discussion'/>
    </div>
  );
};

export default GameAnalysisHelp;
