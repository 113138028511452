import React from 'react';
import '../../styles/help.css'; // Adjust the path as per your structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Scroll enabler if needed
import PageEndTag from '../../Integrations/pageEndTag'; // Scroll enabler if needed
import DiscussionBox from '../../Integrations/DiscussionBox';

const RatingMetrics = () => {
  const articleId = 'RatingMetrics';
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Our Unique Rating System to Optimize Learning</h1>

      <section className="intro-section">
        <p>At <strong>Train Chess</strong>, we’ve applied a proven though unique rating system for chess training. Unlike traditional Elo-based systems, which aim for a 50% success rate in competitive games, our system is focused on improving your skills by optimizing the difficulty level to match your needs. The goal of the algorithm is an <strong>85% success rate</strong>—the scientifically supported sweet spot for effective learning.</p>
      </section>

      <section className="rating-accuracy-section">
        <h2>The Elo Rating System</h2>
        <figure className="wrap-image-container-right">
          <img
            src="/help/ArpadElo.jpg"
            alt="Arpad Elo"
            className="wrap-image"
          />
          <figcaption className="image-caption">Arpad Elo, the creator of the Elo rating system.</figcaption>
        </figure>

        <p> Named after Arpad Elo, the Elo rating system was designed to create fair matches between two players based on their relative skill levels. In chess, it became a common tool for comparing the strength of players, even if they have never faced each other directly. The system’s validity and utility in competitive chess are undeniable. However, its widespread use has led to its uncritical adoption in other areas of chess, including training tools and puzzles. </p> <p> Puzzles have long been a cornerstone of chess improvement, helping players hone their tactical vision and pattern recognition. With the advent of computers, vast databases of puzzles derived from real games have made it easier than ever to match players to puzzles tailored to their skill level. The system used for this matching? The same Elo rating system. At first glance, this approach seems reasonable. After all, why not pair players with puzzles of a similar rating to ensure an appropriate level of challenge? However, upon closer inspection, this methodology reveals significant flaws when applied to learning and improvement. </p>
        <h3>Why It's not Ideal for Learning</h3>
        <p> The fundamental issue lies in the inherent design of the Elo system. It aims to create a "fair" match where both sides have an equal chance of success. When applied to puzzles, this results in players having a success rate that stabilizes around 50%. While this balance is essential in competitive settings, it is counterproductive for learning. A puzzle does not "care" whether it stumps the player a proportional half of the time, and this artificially imposed fairness does little to maximize the player’s growth potential. In fact, it may hinder progress by failing to consistently target the subjects that will lead to the most rapid improvement, which is the whole goal of training in the first place. </p>
        <p> The primary goal of learning is not to create a balance of successes and failures but to facilitate improvement by challenging the learner in ways that stretch their capabilities. In chess training, this means presenting players with puzzles that push their limits without overwhelming them. A system based on the Elo rating often fails to achieve this, as it focuses on creating arbitrarily "fair" matchups rather than optimizing puzzle selection for growth. A player working on tactical puzzles might benefit most from a system that dynamically adjusts difficulty to keep them within their realm of understanding though slightly outside their comfort zone. </p>

        <h2>The 85% Rule</h2>
        <p>
        How do we strike the right balance between challenge and success when learning a new skill? Research published in
        <a href="https://www.nature.com/articles/s41467-019-12552-4" target="_blank">Nature Communications</a> suggests that the optimal learning success rate is around 85%. This means learners should aim to successfully complete about 85% of the tasks they undertake. Why? Because this balance—leaning slightly toward success while still leaving room for errors—creates the ideal environment for engagement, growth, and sustained progress.
        </p>

        <h3>Why It’s Ideal for Learning</h3>
        <p>
        The 85% rule hinges on the concept of <strong>progressive learning</strong>, which emphasizes working within your
        “zone of proximal development.” This is the sweet spot where tasks are just beyond your current level of mastery,
        offering enough challenge to promote growth without tipping into frustration or confusion. With an 85% success rate,
        you’re continuously reinforcing concepts you’ve almost mastered while leaving room to encounter and address areas of
        weakness.
        </p>
        <figure className="wrap-image-container-right">
          <img
            src="/help/flowChart.png"
            alt="Zone of Proximal Development"
            className="wrap-image"
          />
        </figure>
        <p>
        In chess, this might mean focusing on simpler patterns or tactics you’re still prone to miss, like capturing hanging
        pieces or avoiding forks. While solving flashy mate-in-five sequences may seem more exciting, they may be less effective if
        you’re still making basic tactical errors 15% of the time. The 85% approach ensures you consistently target the easiest
        and most impactful areas for improvement. By addressing these gaps, you create a foundation for mastering more complex
        skills later on.
        </p>

        <p>
        The key takeaway is that effective learning is not about always aiming for perfection. Instead, it’s about finding the
        right balance between challenge and mastery. The 85% rule offers a framework for pushing your limits while maintaining
        a level of success that keeps you motivated, focused, and primed for long-term improvement.
        </p>

        <p>For a deeper dive into the science behind the 85% rule, check out this <a href="https://www.nature.com/articles/s41467-019-12552-4" target="_blank" rel="noopener noreferrer">research study</a> published in <em>Nature Communications</em>.</p>

        <h3>Our Rating System</h3>
        <p>The rating system used on Train Chess is different from most puzzle to user matching algorithms. Instead of aiming for the arbitrary 50% success rate of the Elo system, our puzzles are designed to adapt dynamically to your skill level and encourage an 85% accuracy rate. This ensures that you’re solving puzzles that are challenging but not overwhelming, to maximize learning and help you reach your potential faster.</p>
        <p>Like most systems, as you successfully solve more challenging puzzles, your rating increases. If you find certain puzzles too difficult and frequently miss the correct solutions, your rating will adjust downward to keep the difficulty appropriate to your current skill level.</p>

        <p>Unlike competitive Elo ratings, this dynamic system allows you to remain within the optimal learning zone—where puzzles are difficult enough to push your thinking but not so hard that you get discouraged. As your rating adapts, you’ll always be tackling puzzles that are within reach but still require focused effort.</p>

        <h3>How is Accuracy Calculated?</h3>
        <p>Accuracy measures your success rate over a period of time using a moving average formula incorporating your last 100 puzzles. It reflects both your short-term performance and long-term consistency, allowing your rating to be dynamic enough to increase as you improve.</p>
      </section>
      <section className="how-to-improve-section">
        <h2>How to Use These Metrics to Improve</h2>
        <p>Really, you don't need to do anything other than attempt puzzles as normal. In the background our algorithm will automatically target puzzles at the correct difficulty for <strong>you!</strong></p>
        <p>Refrain from being offended from a lower puzzle rating than you may see on other sites. Understand that you will be given puzzles easier than you may be used to. Trust the process and give this training strategy a try!</p>
        <p>With this approach, you're continually working on the "low-hanging fruit"—puzzles that are just within reach, giving you a steady stream of wins and challenges that keep you learning.</p>
      </section>
    <PageEndTag />
    <DiscussionBox folder="about" articleId={articleId} title='Join the Discussion'/>
    </div>
  );
};

export default RatingMetrics;

//      <section className="related-topics-section">
//        <h2>Related Topics</h2>
//        <p>Want to dive deeper? Check out these related topics:</p>
//        <ul>
//          <li><a href="/puzzle-solving-tips">Puzzle Solving Strategies</a></li>
//          <li><a href="/chess-tactics-guide">Chess Tactics Guide</a></li>
//          <li><a href="/mental-stamina">Building Mental Stamina</a></li>
//        </ul>
//      </section>