import React, { useState, useEffect } from 'react';
import '../styles/fileStructure.css';
import { getFirestore, doc, getDoc, setDoc, updateDoc, deleteField } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import { db } from '../firebase-config';
import { useNavigate } from 'react-router-dom';

const FileStructure = ({ onFileSelect, onAddLine, handleEditLine, onCurrentPathChange, selectedItem, setSelectedItem, isSidebarCollapsed, setIsSidebarCollapsed, notifier }) => {
  const [files, setFiles] = useState({});
  const [expandedFolders, setExpandedFolders] = useState({});
  const [currentPath, setCurrentPath] = useState(''); // Track the current directory
  const { user } = useUser();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(() => {
    if (user) {
      fetchFiles();
    }
  }, [user, notifier]);

  useEffect(() => {
    // Call the parent function when the currentPath changes
    onCurrentPathChange(currentPath);
  }, [currentPath, onCurrentPathChange]);

  const fetchFiles = async () => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setFiles(data.MoveTrainer || {});
    } else {
      console.log('No such document!');
    }
  };

const handleSelection = (fileData, filePath) => {
    // Check if the selected file or its parent folder has a color property
    const folderColor = fileData.color || 'white';  // Default to white if not set
    setSelectedItem({ data: fileData, path: filePath });  // Store file data and path
    // Do not call onFileSelect here, just handle selection.
};

// Helper function to collect lines from a directory (including nested directories)
const collectLines = (directory) => {
    let lines = [];

    // Traverse through each entry in the directory
    for (const [key, value] of Object.entries(directory)) {
        if (key === 'lines' && Array.isArray(value)) {
            // If we find a 'lines' array, add all lines to our list
            lines = lines.concat(value);
        } else if (typeof value === 'object') {
            // If it's a subdirectory, recursively collect lines within it
            lines = lines.concat(collectLines(value));
        }
    }

    return lines;
};

const handleFileClick = (file, folderColor) => {
    setIsSidebarCollapsed(true);
    if (file.data && (file.data.pgn || file.data.fen)) {
        // If it's a file with `pgn` or `fen`, proceed normally
        console.log('color1: ' + folderColor);
        const fileColor = file.data.color || folderColor || 'white';
        onFileSelect({ ...file.data, color: fileColor });
    } else if (file && typeof file === 'object') {
        // If it's a directory, collect all lines within it
        const lines = collectLines(file);
        console.log('Collected lines:', lines);

        if (lines.length === 0) {
            alert("No lines available in this directory.");
            return;
        }

        // Shuffle the lines randomly
        const shuffledLines = lines.sort(() => Math.random() - 0.5);

        // Track the randomized lines and current line in `MoveTrainer` (handled in the next steps)
        onFileSelect({ lines: shuffledLines, color: folderColor, isDirectory: true });
    } else {
        alert("No valid FEN or PGN found for this line.");
    }
};

  const toggleFolder = (path) => {
    setExpandedFolders((prevState) => {
      const isExpanded = prevState[path];

      // If the folder is already expanded, collapse it and reset the current path
      if (isExpanded) {
        const parentPath = path.substring(0, path.lastIndexOf('/'));
        setCurrentPath(parentPath || ''); // Set to parent path when collapsing
        return { ...prevState, [path]: false };
      }

      // Determine the parent path and collapse all subdirectories
      const parentPath = path.substring(0, path.lastIndexOf('/'));

      // Reset all subdirectories and expand only the selected folder
      const newExpandedFolders = Object.keys(prevState).reduce((acc, key) => {
        // Collapse all subdirectories of the current path
        if (key.startsWith(path)) {
          acc[key] = false;
        } else if (key.startsWith(parentPath) && key.split('/').length === path.split('/').length) {
          acc[key] = false;
        } else {
          acc[key] = prevState[key];
        }
        return acc;
      }, {});

      newExpandedFolders[path] = true;
      setCurrentPath(path); // Set the current directory to the newly opened folder
      return newExpandedFolders;
    });
  };

const encodeFolderName = (folderName) => {
    return folderName.replace(/\./g, 'd0T')
                     .replace(/\s/g, 's0P')
                     .replace(/'/g, 'aPo')
                     .replace(/\//g, 'sLsh');
};

const decodeFolderName = (encodedName) => {
    return encodedName.replace(/d0T/g, '.')
                      .replace(/s0P/g, ' ')
                      .replace(/aPo/g, "'")
                      .replace(/sLsh/g, '/');
};

const handleAddDirectory = async () => {
    console.log('=== Handle Add Directory Triggered ===');

    // Prompt user for new folder name
    const newFolderName = prompt('Enter the name for the new folder:');
    console.log('New folder name entered:', newFolderName);
    if (!newFolderName) {
        console.log('No folder name provided. Exiting...');
        return;
    }

    let userColor = null;

    // Only prompt for color if adding a main folder (when currentPath is empty)
    if (!currentPath) {
        userColor = prompt('Choose your color (white/black):', 'white');
        console.log('User color chosen:', userColor);
        if (userColor !== 'white' && userColor !== 'black') {
            alert('Invalid color choice. Please choose "white" or "black".');
            console.log('Invalid color choice. Exiting...');
            return;
        }
    }

    // Encode the new folder name to avoid Firestore errors
    const encodedFolderName = encodeFolderName(newFolderName);
    const encodedPath = currentPath ? `${currentPath}/${encodedFolderName}` : encodedFolderName;

    console.log('Encoded path for folder:', encodedPath);

    // Update files state and add new directory
    setFiles((prevFiles) => {
        let updatedFiles = { ...prevFiles };
        console.log('Previous files:', JSON.stringify(prevFiles, null, 2));
        console.log('Updated files (before modification):', JSON.stringify(updatedFiles, null, 2));

        const parts = encodedPath.split('/');
        console.log('Path split into parts (encoded):', parts);
        let currentLevel = updatedFiles;

        parts.forEach((part, index) => {
            if (!currentLevel[part]) {
                console.log(`Adding new part '${part}' to current level.`);
                currentLevel[part] = {};
            } else {
                console.log(`Part '${part}' already exists.`);
            }
            currentLevel = currentLevel[part];
        });

        console.log('Current level after addition:', JSON.stringify(currentLevel, null, 2));

        // Only attach the color to the main folder if it's a main folder
        if (!currentPath) {
            currentLevel.color = userColor;
            console.log('Assigned color to main folder:', userColor);
        }

        console.log('Updated files (after modification):', JSON.stringify(updatedFiles, null, 2));

        // Save the updated state to Firestore after the state is updated
        const userId = user?.uid;
        if (!userId) {
            console.error('User ID is undefined. Cannot save to Firestore.');
            return prevFiles; // Return the previous files to avoid breaking state
        }

        const docRef = doc(db, 'users', userId);

        setDoc(docRef, { MoveTrainer: updatedFiles }, { merge: true })
            .then(() => {
                console.log('Files and user color saved to Firestore.');
            })
            .catch((error) => {
                console.error('Error saving files to Firestore:', error);
            });

        // Return updated files for the state update
        return updatedFiles;
    });
};

  const handleDeleteDirectory = async () => {
    if (!currentPath) {
      alert('Please select a directory to delete.');
      return;
    }

    // Confirm with the user before deleting
        // Decode the currentPath before displaying it in the confirmation prompt
        const decodedPath = currentPath.split('/').map(decodeFolderName).join('/');

        if (!window.confirm(`Are you sure you want to delete the directory '${decodedPath}' and all its contents? This action cannot be undone.`)) {
      return;
    }

    // Update the files state to remove the directory from the UI file structure
    let updatedFiles;
    setFiles((prevFiles) => {
      updatedFiles = { ...prevFiles };
      const parts = currentPath.split('/');
      let currentLevel = updatedFiles;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          delete currentLevel[part]; // Delete the specific folder at the current level
        } else {
          currentLevel = currentLevel[part]; // Traverse deeper into the folder structure
        }
      });

      return updatedFiles;
    });

    try {
      const docRef = doc(db, 'users', user.uid);

      // Replace slashes in the path with dots (Firestore uses dots for nested paths)
      const firestorePath = `MoveTrainer.${currentPath.replace(/\//g, '.')}`;

      // Delete the specific folder path in Firestore using deleteField()
      await updateDoc(docRef, {
        [firestorePath]: deleteField() // Use deleteField() to remove the path
      });

      // Notify the user of successful deletion
      // alert(`Directory '${currentPath}' and all its contents have been successfully deleted from Firestore.`);

      // Reset the currentPath after deletion
      setCurrentPath('');

    } catch (error) {
      console.error('Error deleting directory from Firestore:', error);
      alert(`Failed to delete directory '${currentPath}'. Please try again.`);
    }
  };

// The modified handleDeleteItem function
const handleDeleteItem = async () => {
  if (!selectedItem) {
    console.log("No item selected for deletion.");
    return;
  }

  // Confirm with the user before deleting
  const confirmation = window.confirm(
    `Are you sure you want to delete '${selectedItem.data.name}'? This action cannot be undone.`
  );
  if (!confirmation) return;

  // Split the path to locate the item within `files`
  const itemPath = selectedItem.path.split('/');
  console.log("Item path split into parts:", itemPath);

  // Encode the path to avoid issues with special characters
  const encodedItemPath = itemPath.map(encodeFolderName);
  console.log("Encoded item path split into parts:", encodedItemPath);

  // Update the files state locally to remove the item
  setFiles((prevFiles) => {
    const updatedFiles = { ...prevFiles };
    let currentLevel = updatedFiles;

    encodedItemPath.forEach((part, index) => {
      if (index === encodedItemPath.length - 1) {
        // Last part, delete item if it's part of `lines`
        if (Array.isArray(currentLevel.lines)) {
          const itemIndex = parseInt(part.split('-')[1], 10); // Get array index
          console.log(`Deleting item at index ${itemIndex} from lines array`);
          currentLevel.lines.splice(itemIndex, 1);
        } else {
          console.log(`Deleting field: ${part}`);
          delete currentLevel[part];
        }
      } else {
        console.log(`Traversing to part: ${part}`);
        currentLevel = currentLevel[part];
      }
    });

    console.log("Updated files structure after deletion:", JSON.stringify(updatedFiles, null, 2));
    return updatedFiles;
  });

  // Construct the Firestore path
  const arrayParentPath = encodedItemPath.slice(0, -1).join('.');
  const arrayIndex = parseInt(encodedItemPath[encodedItemPath.length - 1].split('-')[1], 10);
  console.log("Firestore path for array replacement:", `MoveTrainer.${arrayParentPath}.lines`);

  // Remove the item from Firestore by replacing the `lines` array
  try {
    const docRef = doc(db, 'users', user.uid);

    // Get the latest data from Firestore to avoid sync issues
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    // Traverse to the correct array in the document using the encoded path
    let currentArray = docData?.MoveTrainer;
    encodedItemPath.slice(0, -1).forEach((part) => {
      if (currentArray && currentArray[part]) {
        currentArray = currentArray[part];
      } else {
        currentArray = null;
      }
    });

    // Make sure the current array exists and remove the item
    if (currentArray?.lines && Array.isArray(currentArray.lines)) {
      currentArray.lines.splice(arrayIndex, 1);

      // Update Firestore with the new array
      await updateDoc(docRef, {
        [`MoveTrainer.${arrayParentPath}.lines`]: currentArray.lines
      });

      console.log(`Deleted '${selectedItem.data.name}' from Firestore.`);
    } else {
      console.log("Array not found in Firestore at specified path.");
      alert("Failed to find the array in Firestore for deletion.");
    }
  } catch (error) {
    console.error("Error deleting item from Firestore:", error);
    alert("Failed to delete item. Please try again.");
  }

  // Clear the selected item after deletion
  setSelectedItem(null);
};

const renderFolders = (folders, path = '', depth = 0) => {
  // Sort the folder entries alphabetically and filter out 'color' entries
  const sortedFolders = Object.entries(folders)
    .filter(([folderName]) => folderName !== 'color')  // Skip 'color' parameter
    .sort(([a], [b]) => a.localeCompare(b));

  return sortedFolders.map(([encodedFolderName, content]) => {
    // Decode the folder name for display
    const folderName = decodeFolderName(encodedFolderName);

    // Check if the current content is the "lines" array
    if (folderName === 'lines' && Array.isArray(content)) {
      return content.map((file, index) => (
        <li
          key={`${path}/line-${index}`}
          onClick={() => handleSelection(file, `${path}/line-${index}`)}  // Pass file data and path
          className={`file-item ${selectedItem?.path === `${path}/line-${index}` ? 'selected' : ''}`}
        >
          {file.name}
        </li>
      ));
    }

    const currentFolderPath = path ? `${path}/${encodedFolderName}` : encodedFolderName;
    const isExpanded = expandedFolders[currentFolderPath] || false;
    const folderClass = depth === 0 ? 'main-folder' : 'sub-folder';

    return (
      <div key={currentFolderPath} className={`folder ${folderClass}`}>
        <h4
          onClick={() => {
            toggleFolder(currentFolderPath);
            handleSelection(content, currentFolderPath);  // Select the folder
          }}
          className={`folder-header ${selectedItem?.path === currentFolderPath ? 'selected-folder' : ''}`}
        >
          {isExpanded ? '▼' : '►'} {folderName}
        </h4>
        {isExpanded && (
          <div className="folder-content">
            {/* Recursively render folders or handle the content */}
            {Array.isArray(content)
              ? content.map((file, index) => (
                  <li
                    key={`${currentFolderPath}/line-${index}`}
                    onClick={() => handleSelection(file, `${currentFolderPath}/line-${index}`)}  // Select the line
                    className={`file-item ${selectedItem?.path === `${currentFolderPath}/line-${index}` ? 'selected' : ''}`}
                  >
                    {file.name}
                  </li>
                ))
              : renderFolders(content, currentFolderPath, depth + 1)}
          </div>
        )}
      </div>
    );
  });
};

const getTestButtonText = () => {
  // Case 1: If an item is selected, use its name
  if (selectedItem?.data?.name) {
    return `${selectedItem.data.name}`;
  }

  // Case 2: If there is a current path but no item, calculate lines in the folder (including subfolders)
  if (currentPath) {
    const pathSegments = currentPath.split('/');
    const encodedSegments = pathSegments.map(encodeFolderName);
    const lastFolder = decodeFolderName(encodedSegments[encodedSegments.length - 1]);

    // Navigate through `files` to find the folder specified by `currentPath`
    let folderContent = files;
    for (const segment of encodedSegments) {
      if (folderContent[segment]) {
        folderContent = folderContent[segment];
      } else {
        // If path is invalid, return the fallback message
        return `${lastFolder} (0 lines)`;
      }
    }

    // Recursive function to count all lines in the folder and subfolders
    const countLinesRecursively = (folder) => {
      let lineCount = 0;

      // Count lines in the current folder if they exist
      if (Array.isArray(folder.lines)) {
        lineCount += folder.lines.length;
      }

      // Recursively count lines in any subdirectories
      for (const key in folder) {
        if (typeof folder[key] === 'object' && key !== 'color') {
          lineCount += countLinesRecursively(folder[key]);
        }
      }

      return lineCount;
    };

    // Get the total line count
    const totalLineCount = countLinesRecursively(folderContent);
    return `${lastFolder} (${totalLineCount} lines)`;
  }

  // Case 3: If neither selectedItem nor currentPath is defined, display the fallback message
  return 'Select a line';
};

const handleEditFolderName = async (oldFolderPath, newFolderName) => {
  if (!user) {
    alert('Please log in to edit folder names.');
    return;
  }

  if (!oldFolderPath || !newFolderName) {
    alert('Invalid folder names provided.');
    return;
  }

  try {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      alert('No user data found. Please check your account.');
      return;
    }

    const userData = docSnap.data();
    const moveTrainerData = userData.MoveTrainer || {};

    // Decode the folder path
    const folderPathParts = oldFolderPath.split('/').map(decodeFolderName);
    const oldFolderName = folderPathParts.pop(); // Decode and extract the folder being renamed
    const parentPath = folderPathParts.map(encodeFolderName).join('/'); // Encode the parent path

    // Traverse to the parent folder
    let currentLevel = moveTrainerData;
    for (const part of folderPathParts.map(encodeFolderName)) {
      if (!currentLevel[part]) {
        alert(`Folder path "${oldFolderPath}" not found.`);
        return;
      }
      currentLevel = currentLevel[part];
    }

    const encodedOldFolderName = encodeFolderName(oldFolderName);
    const encodedNewFolderName = encodeFolderName(newFolderName);

    if (!currentLevel[encodedOldFolderName]) {
      alert(`Folder "${oldFolderName}" not found.`);
      return;
    }

    // Rename the folder
    currentLevel[encodedNewFolderName] = { ...currentLevel[encodedOldFolderName] };
    delete currentLevel[encodedOldFolderName];

    console.log("Updated MoveTrainer data:", moveTrainerData);

    // Save the updated structure back to Firestore
    await setDoc(docRef, { MoveTrainer: moveTrainerData }, { merge: true });

    // Explicitly delete the old folder path from Firestore
    const encodedOldPath = folderPathParts.concat(encodedOldFolderName).join('.');
    await updateDoc(docRef, {
      [`MoveTrainer.${encodedOldPath}`]: deleteField(),
    });

    // Update the `files` state to trigger re-render
    setFiles(moveTrainerData);

    alert(`Folder "${oldFolderName}" successfully renamed to "${newFolderName}".`);
  } catch (error) {
    console.error('Error renaming folder:', error);
    alert('An error occurred while renaming the folder. Please try again.');
  }
};

const handleRenameFolder = async () => {
  if (!selectedItem || !selectedItem.path) {
    alert("Please select a folder to rename.");
    return;
  }

  const oldFolderPath = selectedItem.path; // Full encoded path to the folder
  const oldFolderName = decodeFolderName(oldFolderPath.split('/').pop()); // Decode the folder name for display

  const newFolderName = prompt(
    `Rename Folder "${oldFolderName}"`,
    oldFolderName // Pre-fill with the current name
  );

  if (!newFolderName || newFolderName === oldFolderName) {
    return;
  }

  try {
    await handleEditFolderName(oldFolderPath, newFolderName);
  } catch (error) {
    console.error("Error renaming folder:", error);
    alert("An error occurred while renaming the folder. Please try again.");
  }
};

return (
  <div className={`collapsible-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
    <button className="collapse-button" onClick={toggleSidebar}>
      {isSidebarCollapsed ? '►' : '◄'}
    </button>
    {!isSidebarCollapsed && (
      <div className="file-list">

        {/* Fixed Section */}
        <div className="fixed-section">
          <h3>Opening Repertoire</h3>

          {/* Button row at the top */}
          <div className="button-row">
              <button
                onClick={() => navigate("/openingtrainer/browse")}
                className="icon-button browse-icon"
                title="Go to Store"
              >
                <img src="/buttons/store.png" alt="Go to Browse" />
              </button>
            <button onClick={handleAddDirectory} className="icon-button folder-icon" title="Add Folder">
              <img src="/buttons/folder.png" alt="Add Folder" />
            </button>

            {currentPath && !selectedItem?.data?.name && (
              <>
                <button onClick={handleDeleteDirectory} className="icon-button" title="Delete Folder">
                  <img src="/buttons/trash.png" alt="Delete Folder" />
                </button>
                <button
                  onClick={handleRenameFolder}
                  className="icon-button rename-icon"
                  title="Rename Folder"
                >
                  <img src="/buttons/pencil.png" alt="Rename Folder" />
                </button>
                <button onClick={() => onAddLine(currentPath)} className="icon-button plus-icon" title="Add Line">
                  <img src="/buttons/plus.png" alt="Add Line" />
                </button>
              </>
            )}
            {selectedItem?.data?.name && (
              <>
                <button onClick={handleDeleteItem} className="icon-button" title="Delete Selected Item">
                  <img src="/buttons/trash.png" alt="Delete Selected Item" />
                </button>
                <button onClick={() => handleEditLine(currentPath)} className="icon-button plus-icon" title="Add Line">
                  <img src="/buttons/pencil.png" alt="Edit Line" />
                </button>
              </>
            )}
          </div>

          <button
            onClick={() => {
              if (selectedItem && selectedItem.data) {
                const pathParts = selectedItem.path.split('/');
                let folderData = files;
                let folderColor = 'white';

                for (let i = 0; i < pathParts.length; i++) {
                  const part = pathParts[i];
                  if (folderData[part]) {
                    folderData = folderData[part];
                    if (folderData.color) {
                      folderColor = folderData.color;
                    }
                  }
                }

                handleFileClick(selectedItem, folderColor);
              } else {
                alert("No line selected");
              }
            }}
            className="test-button"
            disabled={!selectedItem || !selectedItem.data}
          >
            <div className="header-text">TRAIN</div> {/* Larger text */}
            <div>{getTestButtonText()}</div> {/* Button text below */}
          </button>
        </div>

        {/* Scrollable Section */}
        <div className="scrollable-section">
          {renderFolders(files)}
        </div>
      </div>
    )}
  </div>
);
};

export default FileStructure;