import React, { createContext, useState, useContext } from "react";

const GameStateContext = createContext();

export const GameStateProvider = ({ children }) => {
    const [gameState, setGameState] = useState({
      analysis: [],
      playerColor: "White",
      usernames: { white: "", black: "" },
      openingPrepEndIndex: null,
      userRatings: {},
      userComments: {},
      revisedComments: {},
      sidelines: [],
      onlyUserMoves: true,
    });


  const updateGameState = (updates) => {
    setGameState((prev) => ({ ...prev, ...updates }));
    console.log("updating game state: " + JSON.stringify(updates));
  };

  return (
    <GameStateContext.Provider value={{ gameState, updateGameState }}>
      {children}
    </GameStateContext.Provider>
  );
};

export const useGameState = () => useContext(GameStateContext);
