import React, { useState, useEffect, useRef } from 'react';
import MatchDetail from '../Integrations/MatchDetail';
import '../styles/clubPage.css';
import EnableScrollOnPage from '../Integrations/EnableScroll';

const ClubPage = () => {
  const [clubData, setClubData] = useState(null);
  const [matchesData, setMatchesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMatchUrl, setSelectedMatchUrl] = useState(null);  // To track selected match
  const [isFinishedOpen, setIsFinishedOpen] = useState(false);  // Dropdown for finished matches

  const clubUrl = 'https://api.chess.com/pub/club/the-commoners-guild';
  const matchesUrl = 'https://api.chess.com/pub/club/the-commoners-guild/matches';

  const matchCardRef = useRef(null);

  useEffect(() => {
    const fetchClubData = async () => {
      try {
        const clubResponse = await fetch(clubUrl);
        const clubResult = await clubResponse.json();
        setClubData(clubResult);

        const matchesResponse = await fetch(matchesUrl);
        const matchesResult = await matchesResponse.json();
        setMatchesData(matchesResult);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch club or match data');
        setLoading(false);
      }
    };
    fetchClubData();
  }, []);

  const handleMatchClick = (matchUrl) => {
    if (selectedMatchUrl === matchUrl) {
      // If the same match is clicked again, deselect it
      setSelectedMatchUrl(null);
    } else {
      // Select the new match
      setSelectedMatchUrl(matchUrl);
    }
  };

  const toggleFinishedMatches = () => {
    setIsFinishedOpen(!isFinishedOpen);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="club-page">
      <EnableScrollOnPage />

    <div className="club-header"
            style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/checkeredBackground.png)`,
                }}>
      <img src={process.env.PUBLIC_URL + "logo.png"} alt="Club Logo" className="club-logo" />
      <div className="club-info">
        <h1>{clubData.name}</h1>
    <div className="members-count">
      <span className="count">{clubData.members_count}</span>
      <strong>&nbsp;&nbsp;&nbsp;Members</strong>
    </div>
    <div className="members-count">
        <span className="count">{clubData.average_daily_rating}</span>
        <strong>&nbsp;&nbsp;&nbsp;Average Rating</strong>
    </div>
    <div className="members-count">
        <a href="https://link.chess.com/club/XH9tiN" target="_blank" rel="noopener noreferrer">
          Join Here!
        </a>

    </div>

      </div>
    </div>

      {/* Registered Matches Section */}
      <h2>Registered Matches</h2>
      <div className="matches-section">
        {matchesData.registered.length > 0 ? (
          matchesData.registered.map((match) => (
            <div
              key={match['@id']}
              className={`match-card ${selectedMatchUrl === match['@id'] ? 'selected' : ''}`}
              onClick={() => handleMatchClick(match['@id'])}
            >
              <p><strong>{match.name}</strong></p>
              <p>Opponent: <span>{match.opponent.split('/').pop().replace(/-/g, ' ')}</span></p>

              {/* Show match details only for the selected match */}
              {selectedMatchUrl === match['@id'] && (
                <MatchDetail matchUrl={selectedMatchUrl} />
              )}
            </div>
          ))
        ) : (
          <p>No registered matches</p>
        )}
      </div>

      {/* In Progress Matches Section */}
      <h2>In Progress Matches</h2>
      <div className="matches-section">
        {matchesData.in_progress.length > 0 ? (
          matchesData.in_progress.map((match) => (
            <div
              key={match['@id']}
              className={`match-card ${selectedMatchUrl === match['@id'] ? 'selected' : ''}`}
              onClick={() => handleMatchClick(match['@id'])}
            >
              <p><strong>{match.name}</strong></p>
              <p>Opponent: <span>{match.opponent.split('/').pop().replace(/-/g, ' ')}</span></p>

              {/* Show match details only for the selected match */}
              {selectedMatchUrl === match['@id'] && (
                <MatchDetail matchUrl={selectedMatchUrl} />
              )}
            </div>
          ))
        ) : (
          <p>No matches in progress</p>
        )}
      </div>

      {/* Finished Matches Section with Dropdown */}
      <h2 onClick={toggleFinishedMatches} className="dropdown-header">
        {isFinishedOpen ? 'Hide Finished Matches' : 'Show Finished Matches'}
      </h2>
      {isFinishedOpen && (
        <div className="matches-section">
          {matchesData.finished.length > 0 ? (
            matchesData.finished.map((match) => (
              <div
                key={match['@id']}
                className={`match-card ${selectedMatchUrl === match['@id'] ? 'selected' : ''}`}
                onClick={() => handleMatchClick(match['@id'])}
              >
                <p><strong>{match.name}</strong></p>
                <p>Opponent: <span>{match.opponent.split('/').pop().replace(/-/g, ' ')}</span></p>
                <p>Result: {match.result}</p>

                {/* Show match details only for the selected match */}
                {selectedMatchUrl === match['@id'] && (
                  <MatchDetail matchUrl={selectedMatchUrl} />
                )}
              </div>
            ))
          ) : (
            <p>No finished matches</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ClubPage;
