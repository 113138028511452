import React, { useState, useEffect, useRef } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import '../styles/puzzles.css';
import PromotionModal from '../Integrations/PromotionModal';
import { db } from '../firebase-config';
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { calculationRide } from '../Integrations/Joyrides';
import { useNavigate, useLocation } from "react-router-dom";
import { getAccuracyColor } from "../Integrations/helperFunctions";

function Puzzles() {
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [displayFEN, setDisplayFEN] = useState('');
  const [highlightedSquares, setHighlightedSquares] = useState({});
  const [selectedSquares, setSelectedSquares] = useState([]);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const { user } = useUser();
  const [puzzleRating, setPuzzleRating] = useState(1000); // Default value
  const [puzzleAccuracy, setPuzzleAccuracy] = useState(0);
  const [puzzleCount, setPuzzleCount] = useState(0);
  const [loadingPuzzle, setLoadingPuzzle] = useState(false);
  const [playerColor, setPlayerColor] = useState('white');
  const [solutionMoves, setSolutionMoves] = useState([]);
  const [leadingMoves, setLeadingMoves] = useState([]);
  const [userLeadingMoves, setUserLeadingMoves] = useState([]);
  const [game, setGame] = useState(new Chess());
  const [nextMove, setNextMove] = useState(null);
  const [puzzleCompleted, setPuzzleCompleted] = useState(false); // Track if puzzle is complete
  const [puzzleSuccess, setPuzzleSuccess] = useState(true); // Track if the user solved it successfully
  const [dummyState, setDummyState] = useState(0);
  const [previousDisplay, setPreviousDisplay] = useState('');
  const [previousSolution, setPreviousSolution] = useState('');
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [selectedPromotionMove, setSelectedPromotionMove] = useState(null); // Store the move needing promotion
  const [showLoginMessage, setShowLoginMessage] = useState(false);  // State to show login message
  const [isTourOpen, setIsTourOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [moveSettings, setMoveSettings] = useState({
    numMovesBack: 2,
    numPliesForward: 12,
    numPliesBack: 4,
  });

  const givenFENRef = useRef('');
  const puzzleFENRef = useRef('');
  const solutionFENRef = useRef('');

  const userGame = useRef(new Chess());

//useEffect(() => {
//  const fetchUserData = async () => {
//    if (user) {
//      const userRef = doc(db, "users", user.uid);
//      const userDoc = await getDoc(userRef);
//
//      if (userDoc.exists()) {
//        const data = userDoc.data();
//
//        // Check if calculationPuzzleStats exists
//        const calculationPuzzleStats = data.calculationPuzzleStats || null;
//
//        if (calculationPuzzleStats) {
//          // Decode calculationPuzzleStats string into individual values
//          const { rating, accuracy, count } = decodecalculationPuzzleStats(calculationPuzzleStats);
//          setPuzzleRating(rating);
//          setPuzzleAccuracy(accuracy);
//          setPuzzleCount(count);
//        } else {
//          // If no calculationPuzzleStats, initialize with default values
//          const initialStats = "r1000a0c0"; // Rating: 1000, Accuracy: 0%, Puzzle Count: 0
//          await setDoc(userRef, { calculationPuzzleStats: initialStats }, { merge: true });
//
//          setPuzzleRating(1000);
//          setPuzzleAccuracy(0);
//          setPuzzleCount(0);
//        }
//
//        setShowLoginMessage(false);
//      } else {
//        // If no userDoc exists, create a new one with default puzzle stats
//        const initialStats = "r1000a0c0"; // Rating: 1000, Accuracy: 0%, Puzzle Count: 0
//        await setDoc(userRef, { calculationPuzzleStats: initialStats }, { merge: true });
//
//        setPuzzleRating(1000);
//        setPuzzleAccuracy(0);
//        setPuzzleCount(0);
//        setShowLoginMessage(false);
//      }
//    } else {
//      setShowLoginMessage(true);
//    }
//  };
//
//  fetchUserData();
//}, [user]);
//
//// Helper function to decode calculationPuzzleStats string into individual values
//const decodecalculationPuzzleStats = (calculationPuzzleStats) => {
//  const rating = parseInt(calculationPuzzleStats.match(/r(\d+)/)[1], 10); // Extract rating
//  const accuracy = parseFloat(calculationPuzzleStats.match(/a([\d.]+)/)[1]); // Extract accuracy
//  const count = parseInt(calculationPuzzleStats.match(/c(\d+)/)[1], 10); // Extract puzzle count
//
//  return { rating, accuracy, count };
//};


useEffect(() => {
  const fetchUserData = async () => {
    if (user && moveSettings.numMovesBack) {
      const collectionKey = getCollectionKey(moveSettings.numMovesBack); // Determine which stats to fetch
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();

        // Check if the relevant stats collection exists
        const statsString = data[collectionKey] || null;

        if (statsString) {
          // Decode the stats string into individual values
          const { rating, accuracy, count } = decodecalculationPuzzleStats(statsString);
          setPuzzleRating(rating);
          setPuzzleAccuracy(accuracy);
          setPuzzleCount(count);
        } else {
          // If no stats exist, initialize with default values
          const initialStats = "r1000a0c0"; // Default: Rating 1000, Accuracy 0%, Puzzle Count 0
          await setDoc(userRef, { [collectionKey]: initialStats }, { merge: true });

          setPuzzleRating(1000);
          setPuzzleAccuracy(0);
          setPuzzleCount(0);
        }

        setShowLoginMessage(false);
      } else {
        // If no user document exists, create a new one with default stats
        const initialStats = "r1000a0c0"; // Default: Rating 1000, Accuracy 0%, Puzzle Count 0
        await setDoc(userRef, { [collectionKey]: initialStats }, { merge: true });

        setPuzzleRating(1000);
        setPuzzleAccuracy(0);
        setPuzzleCount(0);
        setShowLoginMessage(false);
      }
    } else {
      setShowLoginMessage(true);
    }
  };

  fetchUserData();
}, [user, moveSettings.numMovesBack]);

// Helper to determine the Firestore collection key based on moveSettings.numMovesBack
const getCollectionKey = (depth) => {
  if (depth <= 2) return "calculationPuzzleStats";
  if (depth <= 4) return "calculationPuzzleStats2";
  if (depth <= 6) return "calculationPuzzleStats3";
  if (depth <= 8) return "calculationPuzzleStats4";
  return "calculationPuzzleStats"; // Fallback for unexpected depth
};

// Helper to decode stats string into individual values
const decodecalculationPuzzleStats = (statsString) => {
  const rating = parseInt(statsString.match(/r(\d+)/)[1], 10); // Extract rating
  const accuracy = parseFloat(statsString.match(/a([\d.]+)/)[1]); // Extract accuracy
  const count = parseInt(statsString.match(/c(\d+)/)[1], 10); // Extract count
  return { rating, accuracy, count };
};

useEffect(() => {
  if ( moveSettings.numMovesBack >= 1 && moveSettings.numMovesBack <= 8) {
    // Only fetch the puzzle if moveSettings.numMovesBack is a valid number within the expected range
    fetchPuzzle();
  }
}, [moveSettings]);


  useEffect(() => {
    if (puzzleFENRef.current) {
      userGame.current = new Chess(puzzleFENRef.current);
//      console.log('FEN: ' + puzzleFENRef.current)
      setDummyState(dummyState + 1);

      if (userLeadingMoves.length > 0) {
        userLeadingMoves.forEach((move) => {
//          console.log('User Leading Move: ' + move)
          const result = userGame.current.move(move);
          if (!result) {
            console.error("Invalid move encountered while applying user leading moves:", move);
          }
          setDummyState(dummyState + 1);
        });
      }
    }
  }, [puzzleFENRef.current]);

  const getDisplayFEN = (startFEN, numPliesForward, leadingMoves) => {
    const forwardGame = new Chess(startFEN);
    const forwardMoves = [];

    if (!leadingMoves || leadingMoves.length === 0) {
      return { newFen: startFEN, forwardMoves: [] };
    }

    for (let i = 0; i < numPliesForward && i < leadingMoves.length; i++) {
      const move = leadingMoves[i];
      const result = forwardGame.move(move);

      if (result) {
        forwardMoves.push(move);
      } else {
        break;
      }
    }

    const newFen = forwardGame.fen();
    return { newFen, forwardMoves };
  };

  const calculateSolutionFEN = (startFEN, leadingMoves) => {
    const chess = new Chess(startFEN);
    leadingMoves.forEach(move => {
      chess.move(move);
    });
    return chess.fen();
  };

const handleNumMovesBackChange = (newNumMovesBack) => {
  // Allow the input to be empty without triggering updates
  if (newNumMovesBack === "" || isNaN(parseInt(newNumMovesBack, 10))) {
    setMoveSettings((prevSettings) => ({
      ...prevSettings,
      numMovesBack: newNumMovesBack,
    }));
    return;
  }

  const movesBack = parseInt(newNumMovesBack, 10);
  const newNumPliesForward = (8 - movesBack) * 2;
  const newNumPliesBack = movesBack * 2;

  // Update the settings with valid numbers
  setMoveSettings({
    numMovesBack: movesBack,
    numPliesForward: newNumPliesForward,
    numPliesBack: newNumPliesBack,
  });

  const { newFen: updatedDisplayFEN } = getDisplayFEN(givenFENRef.current, newNumPliesForward, leadingMoves);
  setDisplayFEN(updatedDisplayFEN);
};


  const [highlightLayers, setHighlightLayers] = useState({
    correctMove: {},
    computerMove: {},
    lastMove: {},
    userSelection: {},
    historyHover: {},
  });

  const clearSpecificHighlightLayer = (layer) => {
    setHighlightLayers((prevLayers) => ({
      ...prevLayers,
      [layer]: {}
    }));
  };

  const flashCorrectMove = (fromSquare, toSquare) => {
    const highlightStyles = {
      [fromSquare]: { backgroundColor: "rgba(0, 255, 0, 0.5)" },
      [toSquare]: { backgroundColor: "rgba(0, 255, 0, 0.5)" }
    };

    setHighlightLayers((prevLayers) => ({
      ...prevLayers,
      correctMove: highlightStyles
    }));

    setTimeout(() => clearSpecificHighlightLayer("correctMove"), 1000);
  };

  const flashIncorrectMove = (fromSquare, toSquare) => {
    const highlightStyles = {
      [fromSquare]: { backgroundColor: "rgba(255, 0, 0, 0.5)" },
      [toSquare]: { backgroundColor: "rgba(255, 0, 0, 0.5)" }
    };

    setHighlightLayers((prevLayers) => ({
      ...prevLayers,
      correctMove: highlightStyles
    }));

    setTimeout(() => clearSpecificHighlightLayer("correctMove"), 1000);
    setPuzzleSuccess(false); // Mark puzzle as incorrect
//    console.log("User made an incorrect move.");
  };

const makeComputerMove = () => {
  const nextMove = solutionMoves[currentMoveIndex + 1];
  if (nextMove) {
    const from = nextMove.substring(0, 2); // From square
    const to = nextMove.substring(2, 4);   // To square

    // Check if the move includes a promotion (5 characters, e.g., g7g8q)
    const promotion = nextMove.length === 5 ? nextMove.charAt(4) : null;

    setTimeout(() => {
      const result = userGame.current.move({
        from,
        to,
        promotion: promotion ? promotion : undefined // Include promotion if it exists
      });

      if (!result) {
        console.error("Invalid computer move:", nextMove); // Catch invalid computer move
        return;
      }

      const highlightStyles = {
        [from]: { backgroundColor: "rgba(255, 165, 0, 0.5)" },
        [to]: { backgroundColor: "rgba(255, 120, 0, 0.5)" }
      };

      setHighlightLayers((prevLayers) => ({
        ...prevLayers,
        computerMove: highlightStyles
      }));

      setCurrentMoveIndex((prevIndex) => prevIndex + 1);

      setTimeout(() => clearSpecificHighlightLayer("computerMove"), 1000);
    }, 1000);
  }
};

  useEffect(() => {
    if (game && nextMove) {
      const moveResult = game.move(nextMove);
      if (moveResult) {
        setDisplayFEN(game.fen());
        puzzleFENRef.current = game.fen();

        const fromSquare = nextMove.substring(0, 2);
        const toSquare = nextMove.substring(2, 4);
        const lastMoveHighlightStyles = {
          [fromSquare]: { backgroundColor: "rgba(255, 255, 0, 0.5)" },
          [toSquare]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
        };

        setHighlightLayers((prevLayers) => ({
          ...prevLayers,
          lastMove: lastMoveHighlightStyles
        }));
      }
    }
  }, [game, nextMove]);

useEffect(() => {
  if (puzzleCompleted) {
    handlePuzzleCompletion();
  }
}, [puzzleCompleted]);  // Trigger this effect when puzzleCompleted is set to true

const handlePromotionSelection = (promotionPiece) => {
  if (selectedPromotionMove) {
    const { fromSquare, toSquare } = selectedPromotionMove;
    const moveWithPromotion = `${fromSquare}${toSquare}${promotionPiece}`;

    const moveResult = userGame.current.move({ from: fromSquare, to: toSquare, promotion: promotionPiece });
    if (!moveResult) {
      console.error("Invalid promotion move:", moveWithPromotion);
      flashIncorrectMove(fromSquare, toSquare); // Flash incorrect move if invalid
      return;
    }

    setCurrentMoveIndex((prevIndex) => prevIndex + 1);
    flashCorrectMove(fromSquare, toSquare);

    if (currentMoveIndex + 1 === solutionMoves.length) {
      setPuzzleCompleted(true);
    } else if (currentMoveIndex + 1 < solutionMoves.length) {
      makeComputerMove();
    }
  }

  setShowPromotionModal(false); // Close the modal after the selection
};

const handleSquareClick = (square) => {
  if (puzzleCompleted) return; // Prevent further moves if the puzzle is completed

  if (selectedSquares.includes(square)) {
    setSelectedSquares([]);
    clearSpecificHighlightLayer("userSelection");
    return;
  }

  const selected = [...selectedSquares, square];
  if (selected.length === 2) {
    const fromSquare = selected[0];
    const toSquare = selected[1];

    const move = `${fromSquare}${toSquare}`;

    // Ensure solutionMoves and currentMoveIndex are valid
    const expectedMove = solutionMoves[currentMoveIndex];
    if (!expectedMove) {
      console.error("Expected move is undefined. Check if solutionMoves are properly set.");
      return;
    }

    const expectedFrom = expectedMove.substring(0, 2);
    const expectedTo = expectedMove.substring(2, 4);
    const expectedPromotion = expectedMove.length === 5 ? expectedMove[4] : null; // Check if the solution has a promotion piece

    const tempGame = new Chess(userGame.current.fen());
    const moveResult = tempGame.move(move);

    if (!moveResult) {
      console.error("Invalid user move:", move);
      flashIncorrectMove(fromSquare, toSquare); // Flash incorrect move if invalid
      setSelectedSquares([]);
      clearSpecificHighlightLayer("userSelection");
      return;
    }

    const isCheckmate = tempGame.isCheckmate();

    if (isCheckmate) {
        console.log('Game is checkmate, marking correct.');
    };

    if ((fromSquare === expectedFrom && toSquare === expectedTo) || isCheckmate) {
      // Check if promotion is necessary (e.g., if the user moves to the last rank)
      if (expectedPromotion && (toSquare.charAt(1) === '8' || toSquare.charAt(1) === '1')) {
        // Trigger the promotion modal
        setShowPromotionModal(true); // Assuming you have a state for showing the modal
        setSelectedPromotionMove({ fromSquare, toSquare }); // Store the move to apply promotion later
      } else {
        const moveResult = userGame.current.move(move);
        if (!moveResult) {
          console.error("Invalid user move:", move);
          flashIncorrectMove(fromSquare, toSquare); // Flash incorrect move if invalid
          return; // Stop further actions if the move is invalid
        }

        setCurrentMoveIndex((prevIndex) => prevIndex + 1);
        flashCorrectMove(fromSquare, toSquare);

        if (currentMoveIndex + 1 === solutionMoves.length) {
          setPuzzleCompleted(true);
          // Play all the moves made so far
        } else if (currentMoveIndex + 1 < solutionMoves.length) {
          makeComputerMove();
        }
      }
    } else {
      flashIncorrectMove(fromSquare, toSquare);
    }

    setSelectedSquares([]);
    clearSpecificHighlightLayer("userSelection");
  } else {
    setSelectedSquares(selected);
    const highlightStyles = selected.reduce((acc, square) => {
      acc[square] = { backgroundColor: "rgba(0, 255, 0, 0.5)" };
      return acc;
    }, {});
    setHighlightLayers((prevLayers) => ({
      ...prevLayers,
      userSelection: highlightStyles
    }));
  }
};

const getCombinedHighlights = () => {
  const combinedHighlights = {
    ...highlightLayers.lastMove,
    ...highlightLayers.userSelection,
    ...highlightLayers.correctMove,
    ...highlightLayers.computerMove,
    ...highlightLayers.historyHover,
    ...highlightLayers.flash,
  };

    if (highlightLayers.userSelection) {
      Object.keys(highlightLayers.userSelection).forEach((square) => {
        combinedHighlights[square] = highlightLayers.userSelection[square];
      });
    }

    return combinedHighlights;
  };

  const handleHistoryHover = (move, index) => {
    const history = userGame.current.history({ verbose: true });
    const hoverMove = history[index];

    if (hoverMove && hoverMove.from && hoverMove.to) {
      const fromSquare = hoverMove.from;
      const toSquare = hoverMove.to;

      setHighlightLayers((prevLayers) => ({
        ...prevLayers,
        historyHover: {
          [fromSquare]: { backgroundColor: "rgba(255, 165, 0, 0.5)" },
          [toSquare]: { backgroundColor: "rgba(255, 120, 0, 0.5)" }
        }
      }));
    }
  };

  const clearHistoryHover = () => {
    clearSpecificHighlightLayer("historyHover");
  };

//const adjustRating = async (wasSuccessful) => {
//  const newPuzzleCount = puzzleCount + 1;
//  setPuzzleCount(newPuzzleCount);
//
//  const weightedCount = Math.min(newPuzzleCount, 100);
//  const previousSuccessfulPuzzles = (puzzleAccuracy / 100) * (weightedCount - 1);
//  const successfulPuzzles = wasSuccessful ? previousSuccessfulPuzzles + 1 : previousSuccessfulPuzzles;
//
//  const newPuzzleAccuracy = (successfulPuzzles / weightedCount) * 100;
//  setPuzzleAccuracy(newPuzzleAccuracy);
//
//  let ratingChange;
//  const puzzleCountThresholds = [
//    { threshold: 10, change: 90 },
//    { threshold: 20, change: 50 },
//    { threshold: 50, change: 30 },
//    { threshold: 100, change: 20 },
//    { threshold: Infinity, change: 10 }
//  ];
//
//  const foundThreshold = puzzleCountThresholds.find(threshold => puzzleCount < threshold.threshold);
//  const baseChange = foundThreshold ? foundThreshold.change : 20;
//
//  if (newPuzzleAccuracy > 86.5 && wasSuccessful) {
//    ratingChange = baseChange / 2;
//  } else if (newPuzzleAccuracy > 86.5 && !wasSuccessful) {
//    ratingChange = -2;
//  } else if (newPuzzleAccuracy < 83.5 && !wasSuccessful) {
//    ratingChange = -baseChange;
//  } else if (newPuzzleAccuracy < 83.5 && wasSuccessful) {
//    ratingChange = 2;
//  } else if (wasSuccessful) {
//    ratingChange = 2;
//  } else {
//    ratingChange = -2;
//  }
//
//  let newPuzzleRating = puzzleRating + ratingChange;
//
//  // Apply the rating change first
//  setPuzzleRating(newPuzzleRating);
//
//  // Adding a small delay before updating the rating
//  await new Promise((resolve) => setTimeout(resolve, 1500));
//
//  // Clamp the rating if it goes below 400 or above 3200
//  if (newPuzzleRating < 400) {
//    newPuzzleRating = 400;
//  } else if (newPuzzleRating > 3200) {
//    newPuzzleRating = 3200;
//  }
//
//  setPuzzleRating(newPuzzleRating); // Set clamped rating after the change is triggered
//
//  if (user) {
//    try {
//      const userRef = doc(db, "users", user.uid);
//
//      // Encode the new stats into a single string
//      const encodedStats = encodecalculationPuzzleStats(newPuzzleRating, newPuzzleAccuracy, newPuzzleCount);
//
//      await updateDoc(userRef, {
//        calculationPuzzleStats: encodedStats
//      });
//    } catch (error) {
//      console.error("Failed to update Firestore:", error);
//    }
//  }
//};
//
//// Helper function to encode the puzzle stats into a string
//const encodecalculationPuzzleStats = (rating, accuracy, count) => {
//  return `r${Math.round(rating)}a${accuracy.toFixed(1)}c${count}`;
//};

const adjustRating = async (wasSuccessful) => {
  const newPuzzleCount = puzzleCount + 1;
  setPuzzleCount(newPuzzleCount);

  const weightedCount = Math.min(newPuzzleCount, 100);
  const previousSuccessfulPuzzles = (puzzleAccuracy / 100) * (weightedCount - 1);
  const successfulPuzzles = wasSuccessful ? previousSuccessfulPuzzles + 1 : previousSuccessfulPuzzles;

  const newPuzzleAccuracy = (successfulPuzzles / weightedCount) * 100;
  setPuzzleAccuracy(newPuzzleAccuracy);

  let ratingChange;
  const puzzleCountThresholds = [
    { threshold: 10, change: 90 },
    { threshold: 20, change: 50 },
    { threshold: 50, change: 30 },
    { threshold: 100, change: 20 },
    { threshold: Infinity, change: 10 }
  ];

  const foundThreshold = puzzleCountThresholds.find((threshold) => puzzleCount < threshold.threshold);
  const baseChange = foundThreshold ? foundThreshold.change : 20;

  if (newPuzzleAccuracy > 86.5 && wasSuccessful) {
    ratingChange = baseChange / 2;
  } else if (newPuzzleAccuracy > 86.5 && !wasSuccessful) {
    ratingChange = -2;
  } else if (newPuzzleAccuracy < 83.5 && !wasSuccessful) {
    ratingChange = -baseChange;
  } else if (newPuzzleAccuracy < 83.5 && wasSuccessful) {
    ratingChange = 2;
  } else if (wasSuccessful) {
    ratingChange = 2;
  } else {
    ratingChange = -2;
  }

  let newPuzzleRating = puzzleRating + ratingChange;

  // Apply the rating change first
  setPuzzleRating(newPuzzleRating);

  // Adding a small delay before updating the rating
  await new Promise((resolve) => setTimeout(resolve, 1500));

  // Clamp the rating if it goes below 400 or above 3200
  if (newPuzzleRating < 400) {
    newPuzzleRating = 400;
  } else if (newPuzzleRating > 3200) {
    newPuzzleRating = 3200;
  }

  setPuzzleRating(newPuzzleRating); // Set clamped rating after the change is triggered

  if (user) {
    try {
      const userRef = doc(db, "users", user.uid);

      // Use the correct stats key based on the current depth
      const collectionKey = getCollectionKey(moveSettings.numMovesBack);

      // Encode the new stats into a single string
      const encodedStats = encodecalculationPuzzleStats(newPuzzleRating, newPuzzleAccuracy, newPuzzleCount);

      // Update Firestore with the correct stats key
      await updateDoc(userRef, {
        [collectionKey]: encodedStats
      });
    } catch (error) {
      console.error("Failed to update Firestore:", error);
    }
  }
};

// Helper to encode stats into a single string
const encodecalculationPuzzleStats = (rating, accuracy, count) => {
  return `r${Math.round(rating)}a${accuracy.toFixed(1)}c${count}`;
};


const flashBoardGreen = async () => {
  const allSquares = [];
  for (let row = 1; row <= 8; row++) {
    for (let col of 'abcdefgh') {
      allSquares.push(col + row);
    }
  }

  // Create green flash styles for all squares
  const flashStyle = allSquares.reduce((styles, square) => {
    styles[square] = { backgroundColor: "rgba(0, 255, 0, 0.5)" };
    return styles;
  }, {});

  // Capture the current highlight layers to restore later
  const defaultStyle = getCombinedHighlights();

  // Apply green flash to all squares
  setHighlightLayers((prevLayers) => ({
    ...prevLayers,
    flash: flashStyle,
  }));

  await new Promise(resolve => setTimeout(resolve, 400)); // Flash duration

  // Restore the previous highlight layers
  setHighlightLayers(defaultStyle);
};

const flashBoardRed = async () => {
  const allSquares = [];
  for (let row = 1; row <= 8; row++) {
    for (let col of 'abcdefgh') {
      allSquares.push(col + row);
    }
  }

  // Create red flash styles for all squares
  const flashStyle = allSquares.reduce((styles, square) => {
    styles[square] = { backgroundColor: "rgba(255, 0, 0, 0.5)" };
    return styles;
  }, {});

  // Capture the current highlight layers to restore later
  const defaultStyle = getCombinedHighlights();

  // Apply red flash to all squares
  setHighlightLayers((prevLayers) => ({
    ...prevLayers,
    flash: flashStyle,
  }));

  await new Promise(resolve => setTimeout(resolve, 400)); // Flash duration

  // Restore the previous highlight layers
  setHighlightLayers(defaultStyle);
};

const handlePuzzleCompletion = async () => {
  const allMoves = [...userLeadingMoves, ...solutionMoves];
  setPreviousDisplay(displayFEN);
  setPreviousSolution(solutionFENRef.current);
  console.log("Puzzle success status before playing moves:", puzzleSuccess); // Log puzzleSuccess
  try {
    await playMoves(allMoves, 1000, puzzleSuccess); // Wait for playMoves to finish
    await adjustRating(puzzleSuccess); // Adjust rating after moves have been played
    // Trigger puzzle fetch after completion
    fetchPuzzle();
  } catch (error) {
    console.error("Error while playing moves or adjusting rating:", error);
  }
};

const playMoves = (moves, interval = 1000, puzzleSuccess) => {
  return new Promise((resolve, reject) => {
    let index = 0;

    const playNextMove = () => {
      if (index >= moves.length) {
        // Flash the board only after all moves are played and the last move is fully displayed
        setTimeout(() => {
          console.log("Flashing the board based on puzzleSuccess:", puzzleSuccess);
          if (puzzleSuccess) {
            flashBoardGreen().then(resolve); // Flash green and then resolve the promise
          } else {
            flashBoardRed().then(resolve); // Flash red and then resolve the promise
          }
        }, interval); // Ensure the flash happens after the interval of the last move
        return;
      }

      const move = moves[index];
      const from = move.substring(0, 2);  // Extract 'from' square
      const to = move.substring(2, 4);    // Extract 'to' square

      // Check if the move includes a promotion (5 characters, e.g., g7g8q)
      const promotion = move.length === 5 ? move.charAt(4) : null;

      setTimeout(() => {
        const result = game.move({
          from,
          to,
          promotion: promotion ? promotion : undefined // Include promotion if it exists
        });

        if (!result) {
          console.error("Invalid move:", move);
          reject(`Invalid move encountered: ${move}`); // Reject the promise if there's an invalid move
          return;
        }

        // Update the FEN and highlight the move
        setDisplayFEN(game.fen());

        const highlightStyles = {
          [from]: { backgroundColor: "rgba(255, 255, 0, 0.5)" },
          [to]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
        };

        setHighlightLayers((prevLayers) => ({
          ...prevLayers,
          lastMove: highlightStyles,
        }));

        index += 1;
        playNextMove(); // Play the next move after a delay
      }, interval);
    };

    playNextMove(); // Start the animation
  });
};


const fetchPuzzle = async (seedNumber = null) => {

  setLoadingPuzzle(true);
  try {
    const chunkNumber = Math.floor(Math.random() * 722) + 1;
    const url = `${process.env.PUBLIC_URL}/calculationpuzzles/puzzles_chunk_${chunkNumber}.json`;
    console.log('URL: ' + url)
    const response = await fetch(url);
    const puzzles = await response.json();

    let selectedPuzzle;

if (seedNumber) {
  // Fetch puzzle by seedNumber
  console.log('Looking for seed number:', seedNumber);

  selectedPuzzle = puzzles.find(puzzle => String(puzzle.Seed) === String(seedNumber)); // Ensuring both are strings for comparison

  if (!selectedPuzzle) {
    console.error('Puzzle with specified seed number not found. Available seeds:', puzzles.map(p => p.Seed));
    return;
  }

  console.log('Found puzzle with seed number:', selectedPuzzle);
}
else {
      // Fetch random puzzle as fallback
      const filteredPuzzles = puzzles.filter(puzzle => Math.abs(puzzle.Rating - puzzleRating) <= 50);
      if (filteredPuzzles.length > 0) {
        selectedPuzzle = filteredPuzzles[Math.floor(Math.random() * filteredPuzzles.length)];
      }
    }

    if (selectedPuzzle) {
      setCurrentPuzzle(selectedPuzzle);
      setCurrentMoveIndex(0);

      const startFEN = selectedPuzzle.FEN;
      givenFENRef.current = startFEN;

      const color = startFEN.split(" ")[1] === 'w' ? 'black' : 'white';
      setPlayerColor(color);

      let tempSolutionMoves = selectedPuzzle?.Moves.split(' ') || [];
      let tempLeadingMoves = selectedPuzzle?.LeadingMoves.split(' ') || [];

      if (tempSolutionMoves.length > 0) {
        tempLeadingMoves.push(tempSolutionMoves[0]);
        tempSolutionMoves = tempSolutionMoves.slice(1);
      }

      setSolutionMoves(tempSolutionMoves);
      setLeadingMoves(tempLeadingMoves);

      const { newFen: updatedDisplayFEN } = getDisplayFEN(startFEN, moveSettings.numPliesForward, tempLeadingMoves);
      setDisplayFEN(updatedDisplayFEN);

      const tempSolutionFEN = calculateSolutionFEN(startFEN, tempLeadingMoves);
      solutionFENRef.current = tempSolutionFEN;

      setGame(new Chess(updatedDisplayFEN));
      setPuzzleCompleted(false);
      setPuzzleSuccess(true);

      if (moveSettings.numPliesForward < tempLeadingMoves.length) {
        const nextMove = tempLeadingMoves[moveSettings.numPliesForward];
        setUserLeadingMoves(tempLeadingMoves.slice(-moveSettings.numPliesBack));
        setNextMove(nextMove);
      }
    }
  } catch (error) {
    console.error('Failed to load puzzle data:', error);
  } finally {
    setLoadingPuzzle(false);
  }
};

  const handleGiveUp = async () => {
      setPuzzleSuccess(false);  // Update the puzzle success status to false
      setPuzzleCompleted(true);  // Mark the puzzle as completed
    };

const handleAnalyzeOnLichess = (fen) => {
  const lastColor = fen.split(" ")[1] === "w" ? "White" : "Black"; // Determine player color from FEN
  const currentPageState = {
    ...currentPuzzle, // Preserve the current puzzle state
    numMovesBack: moveSettings.numMovesBack, // Add numMovesBack to state
  };

  navigate("/analyze", {
    state: {
      fenFromElse: fen,
      orientationFromElse: lastColor,
      currentPageState: currentPageState, // Pass numMovesBack
      returnPath: "/calculationpuzzles", // Allow return to the puzzles page
    },
  });
};

useEffect(() => {
  console.log('current ' + location.state);
  if (location.state) {
    // Update current page state when returning from another page
    console.log("Updated currentPageState:", JSON.stringify(location.state));

    // Set the numMovesBack from the currentPageState if it exists
    if (location.state.numMovesBack) {
      setMoveSettings((prevSettings) => ({
        ...prevSettings,
        numMovesBack: location.state.numMovesBack, // Update numMovesBack
      }));
    }
  }
}, [location.state]);


    useEffect(() => {
        const checkIfFirstVisit = async () => {
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                if (!data.hasSeenCalculation) {
                    setIsTourOpen(true); // Start tour if it’s the user’s first visit
                    await setDoc(userRef, { hasSeenCalculation: true }, { merge: true });
                }
            }
        };

        if (user) {
            checkIfFirstVisit();
        }
    }, [user]);

return (
  <div className='game-layout'>
      <JoyrideWrapper
        steps={calculationRide}
        run={isTourOpen}
        onFinish={() => setIsTourOpen(false)} // Close tour when finished
      />
    {showPromotionModal && (
      <div className="promotion-modal-container">
        <PromotionModal
          onPromote={handlePromotionSelection}
          pieces={['q', 'r', 'b', 'n']} // Queen, Rook, Bishop, Knight
          color={playerColor === 'white' ? 'w' : 'b'}
        />
      </div>
    )}
    {showLoginMessage && (
      <div className="login-message">
        <button className="close-button" onClick={() => setShowLoginMessage(false)}>×</button>
        <p><a href="/login">Log in</a> to save your puzzle data.</p>
      </div>
    )}
    {/* Chessboard container */}
    <div className='chessboard-container'>
      <ChessboardCustom
        position={displayFEN}
        onSquareClick={handleSquareClick}
        squareStyles={getCombinedHighlights()}
        orientation={playerColor}
        degreeOfBlindness='Normal'
      />
    </div>

    {/* Info Container */}
    <div className="info-container">
        <button onClick={() => setIsTourOpen(true)} className="help-link">
            Help
        </button>
        {/* Rating and Accuracy */}
        <div className="rating-accuracy">
          <div className="rating-container">
            <div
              className="accuracy-box"
              style={{
                boxShadow: `0 0 10px ${getAccuracyColor(puzzleAccuracy)}`,
              }}
            >
              <strong>{puzzleAccuracy ? `${puzzleAccuracy.toFixed(1)}%` : '0.0%'}</strong>
            </div>
            <div className="rating-box">
              {puzzleRating}
            </div>
          </div>
          <div className="accuracy-feedback">
            {puzzleAccuracy > 87.5
              ? 'Increasing Difficulty'
              : puzzleAccuracy < 82.5
              ? 'Decreasing Difficulty'
              : 'Optimal Zone'}
          </div>
        </div>


      {/* History List - Desktop */}
      <div className="history-list desktop-history-list">
        <div className="table-container">
          <table>
            <tbody>
              {userGame.current.history({ verbose: false }).map((move, index) => {
                const isWhiteMove = index % 2 === 0;
                const moveNumber = Math.floor(index / 2) + 1;
                const blackMove = userGame.current.history({ verbose: false })[index + 1] || '';

                if (isWhiteMove) {
                  return (
                    <tr key={index}>
                      <td className="move-number">{moveNumber}.</td>
                      <td
                        className="move-box"
                        onMouseEnter={() => handleHistoryHover(move, index)}
                        onMouseLeave={clearHistoryHover}
                      >
                        {move}
                      </td>
                      <td
                        className="move-box"
                        onMouseEnter={() => handleHistoryHover(blackMove, index + 1)}
                        onMouseLeave={clearHistoryHover}
                      >
                        {blackMove}
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Buttons */}
      <div className="buttons-container">
        <div className="puzzle-button-row">
          <button className="icon-options-button" onClick={handleGiveUp}>
            <img src={process.env.PUBLIC_URL + "/buttons/resigns.png"} alt="Give Up" />
          </button>
          <button className="icon-options-button" onClick={() => handleAnalyzeOnLichess(previousSolution)}>
            <img src={process.env.PUBLIC_URL + "/buttons/analyze.png"} alt="Analyze Last Puzzle" />
          </button>
            {/* Depth Input */}
          <div className="moves-back-container">
            <input
              className="moves-back-input"
              id="numMovesBack"
              type="number"
              min="1"
              max="8"
              value={moveSettings.numMovesBack}
              onChange={(event) => handleNumMovesBackChange(event.target.value)}
            />
          </div>
        </div>
      </div>
    </div>

    {/* Mobile-only History List */}
    <div className="history-list mobile-history-list">
      <div className="table-container">
        <table>
          <tbody>
            {userGame.current.history({ verbose: false }).map((move, index) => {
              const isWhiteMove = index % 2 === 0;
              const moveNumber = Math.floor(index / 2) + 1;
              const blackMove = userGame.current.history({ verbose: false })[index + 1] || '';

              if (isWhiteMove) {
                return (
                  <tr key={index}>
                    <td className="move-number">{moveNumber}.</td>
                    <td
                      className="move-box"
                      onMouseEnter={() => handleHistoryHover(move, index)}
                      onMouseLeave={clearHistoryHover}
                    >
                      {move}
                    </td>
                    <td
                      className="move-box"
                      onMouseEnter={() => handleHistoryHover(blackMove, index + 1)}
                      onMouseLeave={clearHistoryHover}
                    >
                      {blackMove}
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

}

export default Puzzles;