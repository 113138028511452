import React from "react";
import PropTypes from "prop-types";

const ShareModal = ({ isOpen, closeModal, fen, pgn, copyToClipboard }) => {
  if (!isOpen) return null; // Render nothing if the modal is not open

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Export</h3>
        <button
          onClick={() => copyToClipboard(fen, "FEN")}
          className="average-button"
        >
          Copy FEN
        </button>
        <button
          onClick={() => copyToClipboard(pgn, "PGN")}
          className="average-button"
        >
          Copy PGN
        </button>
        <button onClick={closeModal} className="close-button">
          x
        </button>
      </div>
    </div>
  );
};

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Whether the modal is open
  closeModal: PropTypes.func.isRequired, // Function to close the modal
  fen: PropTypes.string.isRequired, // FEN string to copy
  pgn: PropTypes.string.isRequired, // PGN string to copy
  copyToClipboard: PropTypes.func.isRequired, // Function to copy text to clipboard
};

export default ShareModal;
