import React, { useState, useEffect } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, getDoc, setDoc, collection, query, orderBy, limit, addDoc, getDocs, deleteDoc, Timestamp } from 'firebase/firestore'; // Firestore methods
import { useUser } from '../Integrations/UserContext'; // Assuming you have a user context
import { Link } from 'react-router-dom';
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import Modal from '../Integrations/Modal';
import Countdown from '../Integrations/Countdown';
import Leaderboard from '../Integrations/Leaderboard';
//import { FaCalendarAlt, FaTrophy } from 'react-icons/fa'; // Import icons from react-icons
import { findForkRide } from '../Integrations/Joyrides';

const db = getFirestore(); // Initialize Firestore

function FindFork() {
    const [game, setGame] = useState(new Chess());
    const [forkSquare, setForkSquare] = useState('');
    const [kingSquare, setKingSquare] = useState('');
    const [pawnSquare, setPawnSquare] = useState('');
    const [queenSquare, setQueenSquare] = useState('');
    const [knightSquare, setKnightSquare] = useState('');
    const [answerSquare, setAnswerSquare] = useState('');
    const [squareStyles, setSquareStyles] = useState({});
    const [score, setScore] = useState(0); // Track the current score
    const [isGameActive, setIsGameActive] = useState(false); // Track game state
    const [timeLeft, setTimeLeft] = useState(60); // 15-second timer
    const [bestScore, setBestScore] = useState(null); // Store the user's best score
    const [gameOverMessage, setGameOverMessage] = useState(''); // Message after the game ends
    const { user } = useUser(); // Retrieve the current user
    const [rookSquare, setRookSquare] = useState('');
    const [otherPieceSquare, setOtherPieceSquare] = useState('');
    const [bishopSquare, setBishopSquare] = useState('');
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const [showCountdown, setShowCountdown] = useState(false);
    const [leaderboardView, setLeaderboardView] = useState('monthly');
    const [showLoginMessage, setShowLoginMessage] = useState(false);

    useEffect(() => {
        setShowLoginMessage(!user); // Show message if user is not logged in
    }, [user]);

    const handleCloseMessage = () => {
        setShowLoginMessage(false); // Close login message
    };

    // Toggle leaderboard view between 'monthly' and 'allTime'
    const toggleLeaderboardView = () => {
        setLeaderboardView((prevView) => (prevView === 'monthly' ? 'allTime' : 'monthly'));
    };

const getCurrentMonthPath = () => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentYear = new Date().getFullYear();
    return `leaderboard/findthefork/${currentYear}-${currentMonth}`;
};

const saveScoreToLeaderboard = async (score) => {
    if (!user) return; // Ensure the user is logged in
    const entry = {
        username: user.displayName || 'Anonymous',
        score,
        timestamp: Timestamp.now(),
    };

    // Get paths for monthly and all-time leaderboards
    const monthlyPath = getCurrentMonthPath();
    const allTimePath = 'leaderboard/findthefork/allTime';

    // Helper function to update leaderboard (monthly or all-time)
    const updateLeaderboard = async (path) => {
        const scoresCollection = collection(db, path);
        await addDoc(scoresCollection, entry); // Add new score

        // Fetch top 5 scores after the new addition
        const leaderboardQuery = query(scoresCollection, orderBy('score', 'desc'), limit(5));
        const leaderboardSnapshot = await getDocs(leaderboardQuery);

        // Keep only the top 5 scores, deleting extras
        const topScores = leaderboardSnapshot.docs;
        if (topScores.length > 5) {
            const excessScores = topScores.slice(5);
            for (const docSnapshot of excessScores) {
                await deleteDoc(doc(db, path, docSnapshot.id)); // Remove excess scores
            }
        }
    };

    // Update both leaderboards
    await updateLeaderboard(monthlyPath); // Update monthly leaderboard
    await updateLeaderboard(allTimePath); // Update all-time leaderboard
};

    const closeModal = () => {
        setIsModalOpen(false);
        setGameOverMessage(''); // Clear the message when modal closes
    };

    // Function to start the tour
    useEffect(() => {
        const checkIfFirstVisit = async () => {
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                if (!data.hasSeenFindFork) {
                    setIsTourOpen(true); // Start tour if it’s the user’s first visit
                    await setDoc(userRef, { hasSeenFindFork: true }, { merge: true });
                }
            }
        };

        if (user) {
            checkIfFirstVisit();
        }
    }, [user]);

    // Function to generate a valid knight puzzle
    const generateKnightPuzzle = () => {
        const validSquares = [...Array(64).keys()]
            .map(i => {
                const file = 'abcdefgh'[i % 8];
                const rank = (Math.floor(i / 8) + 1).toString();
                return `${file}${rank}`;
            })
            .filter(sq => !['a1', 'a8', 'h1', 'h8'].includes(sq)); // Avoid corner squares

        // Randomly select the solution square (fork square)
        const randomForkSquare = validSquares[Math.floor(Math.random() * validSquares.length)];
        setForkSquare(randomForkSquare);

        // Get all valid knight moves from the solution square
        const knightMoves = getKnightMoves(randomForkSquare);

        // Ensure there are enough knight moves to place the pieces
        if (knightMoves.length < 3) {
            generateKnightPuzzle(); // If not enough moves, regenerate the puzzle
            return;
        }

        // Randomly choose 3 distinct squares from the knight's moves
        const shuffledMoves = knightMoves.sort(() => 0.5 - Math.random());
        const [randomKnightSquare, randomKingSquare, randomQueenSquare] = shuffledMoves.slice(0, 3);

        // Set the king, queen, and knight positions
        setKingSquare(randomKingSquare);
        setQueenSquare(randomQueenSquare);
        setKnightSquare(randomKnightSquare);
        setAnswerSquare('');
        setSquareStyles({}); // Clear previous square styles

        // Set up the board with the king, queen, and knight
        const newGame = new Chess();
        newGame.clear();
        newGame.put({ type: 'k', color: 'b' }, randomKingSquare); // White King
        newGame.put({ type: 'q', color: 'b' }, randomQueenSquare); // White Queen
        newGame.put({ type: 'n', color: 'w' }, randomKnightSquare); // Black Knight (one knight move away)
        setGame(newGame);
    };

    // Get valid knight moves for a square
    const getKnightMoves = (square) => {
        const file = square.charCodeAt(0);
        const rank = parseInt(square[1], 10);

        const potentialMoves = [
            { fileDiff: 1, rankDiff: 2 },
            { fileDiff: 1, rankDiff: -2 },
            { fileDiff: -1, rankDiff: 2 },
            { fileDiff: -1, rankDiff: -2 },
            { fileDiff: 2, rankDiff: 1 },
            { fileDiff: 2, rankDiff: -1 },
            { fileDiff: -2, rankDiff: 1 },
            { fileDiff: -2, rankDiff: -1 },
        ];

        return potentialMoves
            .map(move => String.fromCharCode(file + move.fileDiff) + (rank + move.rankDiff))
            .filter(move => /^[a-h][1-8]$/.test(move));
    };

const generateBishopPuzzle = () => {
    const files = 'abcdefgh';
    const ranks = '12345678';

    // Step 1: Filter for valid forking squares (not on outer rim)
    const validSquares = [...Array(64).keys()]
        .map(i => {
            const file = files[i % 8];
            const rank = ranks[Math.floor(i / 8)];
            return `${file}${rank}`;
        })
        .filter(sq => {
            // Exclude outer rim (a/h files and 1/8 ranks)
            return !sq.startsWith('a') && !sq.startsWith('h') && !sq.endsWith('1') && !sq.endsWith('8');
        });

    // Step 2: Select a random forking square
    const forkSquare = validSquares[Math.floor(Math.random() * validSquares.length)];
    setForkSquare(forkSquare);

    const forkFileIndex = files.indexOf(forkSquare[0]);
    const forkRankIndex = ranks.indexOf(forkSquare[1]);

    // Step 3: Identify diagonals from the forking square
    const getDiagonalSquares = (startFileIndex, startRankIndex, fileStep, rankStep) => {
        const diagonal = [];
        let fileIndex = startFileIndex;
        let rankIndex = startRankIndex;

        // Move backward along the diagonal
        while (fileIndex >= 0 && fileIndex < 8 && rankIndex >= 0 && rankIndex < 8) {
            diagonal.unshift(`${files[fileIndex]}${ranks[rankIndex]}`);
            fileIndex -= fileStep;
            rankIndex -= rankStep;
        }

        // Reset file and rank to start position
        fileIndex = startFileIndex + fileStep;
        rankIndex = startRankIndex + rankStep;

        // Move forward along the diagonal
        while (fileIndex >= 0 && fileIndex < 8 && rankIndex >= 0 && rankIndex < 8) {
            diagonal.push(`${files[fileIndex]}${ranks[rankIndex]}`);
            fileIndex += fileStep;
            rankIndex += rankStep;
        }

        return diagonal;
    };

    // Get two diagonals: primary (for king and other piece) and opposite (for bishop)
    const primaryDiagonal1 = getDiagonalSquares(forkFileIndex, forkRankIndex, 1, 1);
    const primaryDiagonal2 = getDiagonalSquares(forkFileIndex, forkRankIndex, -1, -1);
    const oppositeDiagonal1 = getDiagonalSquares(forkFileIndex, forkRankIndex, 1, -1);
    const oppositeDiagonal2 = getDiagonalSquares(forkFileIndex, forkRankIndex, -1, 1);

    // Choose a primary diagonal with at least two squares on each side
    let chosenPrimaryDiagonal;
    if (primaryDiagonal1.length >= 5) chosenPrimaryDiagonal = primaryDiagonal1;
    else if (primaryDiagonal2.length >= 5) chosenPrimaryDiagonal = primaryDiagonal2;

    if (!chosenPrimaryDiagonal) {
        generateBishopPuzzle(); // Retry if no valid configuration is found
        return;
    }


    // Step 4: Split the diagonal into squares below and above the fork square
    const forkIndex = chosenPrimaryDiagonal.indexOf(forkSquare);
    const squaresBelowFork = chosenPrimaryDiagonal.slice(0, forkIndex);
    const squaresAboveFork = chosenPrimaryDiagonal.slice(forkIndex + 1);

    if (squaresBelowFork.length === 0 || squaresAboveFork.length === 0) {
        generateBishopPuzzle(); // Retry if no valid configuration is found
        return;
    }

    // Step 5: Randomly select one square from each side for the king and other piece, ensuring they are on opposite sides
    const kingSquare = (Math.random() < 0.5 ? squaresBelowFork : squaresAboveFork)
        .filter(sq => !getAdjacentSquares(forkSquare).includes(sq)) // Ensure king is not adjacent
        .sort(() => 0.5 - Math.random())[0]; // Random selection

    // Ensure that the other piece is placed on the opposite side of the king
    const otherPieceSquare = (squaresBelowFork.includes(kingSquare) ? squaresAboveFork : squaresBelowFork)
        .sort(() => 0.5 - Math.random())[0]; // Opposite side random selection
    const otherPieceType = Math.random() < 0.5 ? 'n' : 'r';

    // Check for undefined values and retry if any are found
    if (!kingSquare || !otherPieceSquare) {
        generateBishopPuzzle();
        return;
    }

    // Step 6: Find the opposite diagonal for placing the bishop
    const oppositeDiagonal = (chosenPrimaryDiagonal === primaryDiagonal1) ? oppositeDiagonal1 : oppositeDiagonal2;

    // Step 7: Place the bishop on the opposite diagonal, not adjacent to forkSquare or king
    const bishopSquare = oppositeDiagonal.find(sq => {
        const bishopFileIndex = files.indexOf(sq[0]);
        const bishopRankIndex = ranks.indexOf(sq[1]);
        return Math.abs(bishopFileIndex - forkFileIndex) > 1 && Math.abs(bishopRankIndex - forkRankIndex) > 1;
    });

    if (!bishopSquare) {
        generateBishopPuzzle(); // Retry if no valid placement is found
        return;
    }

    // Step 8: Set the positions on the board
    setKingSquare(kingSquare);
    setOtherPieceSquare(otherPieceSquare);
    setBishopSquare(bishopSquare);
    setAnswerSquare('');
    setSquareStyles({});

    // Set up the board with the pieces
    const newGame = new Chess();
    newGame.clear();
    newGame.put({ type: 'k', color: 'b' }, kingSquare); // Black King
    newGame.put({ type: otherPieceType, color: 'b' }, otherPieceSquare); // Black Knight or Rook
    newGame.put({ type: 'b', color: 'w' }, bishopSquare); // White Bishop
    setGame(newGame);
};

// Helper function to find adjacent squares
const getAdjacentSquares = (square) => {
    const files = 'abcdefgh';
    const ranks = '12345678';
    const fileIndex = files.indexOf(square[0]);
    const rankIndex = ranks.indexOf(square[1]);
    const adjacentSquares = [];

    [-1, 0, 1].forEach(fileOffset => {
        [-1, 0, 1].forEach(rankOffset => {
            if (fileOffset === 0 && rankOffset === 0) return;
            const adjacentFile = files[fileIndex + fileOffset];
            const adjacentRank = ranks[rankIndex + rankOffset];
            if (adjacentFile && adjacentRank) {
                adjacentSquares.push(`${adjacentFile}${adjacentRank}`);
            }
        });
    });
    return adjacentSquares;
};

const generateRookPuzzle = () => {
    const files = 'abcdefgh';
    const ranks = '12345678';

    // Decide between file or rank fork
    const isFileFork = Math.random() < 0.5;

    // Filter valid squares for the forking square based on the fork type
    const validSquares = [...Array(64).keys()]
        .map(i => {
            const file = files[i % 8];
            const rank = ranks[Math.floor(i / 8)];
            return `${file}${rank}`;
        })

    // Select a random forking square
    const forkSquare = validSquares[Math.floor(Math.random() * validSquares.length)];
    setForkSquare(forkSquare);

    const forkFile = forkSquare[0];
    const forkRank = forkSquare[1];
    const forkFileIndex = files.indexOf(forkFile);
    const forkRankIndex = ranks.indexOf(forkRank);

    // Get squares adjacent to the forking square
    const adjacentSquares = [];
    const potentialOffsets = [
        [-1, -1], [0, -1], [1, -1], // Squares above
        [-1, 0], [1, 0],            // Left and right
        [-1, 1], [0, 1], [1, 1]     // Squares below
    ];
    potentialOffsets.forEach(offset => {
        const file = files[forkFileIndex + offset[0]];
        const rank = ranks[forkRankIndex + offset[1]];
        if (file && rank) {
            adjacentSquares.push(`${file}${rank}`);
        }
    });

    // Filter opposite side squares based on fork type
    let potentialKingSquares = [];
    let potentialOtherPieceSquares = [];

    if (isFileFork) {
        // Find squares on the same file (forkFile) and ensure they are opposite to forkSquare
        potentialKingSquares = validSquares.filter(sq =>
            sq[0] === forkFile && ranks.indexOf(sq[1]) < forkRankIndex - 1 && !adjacentSquares.includes(sq)
        );
        potentialOtherPieceSquares = validSquares.filter(sq =>
            sq[0] === forkFile && ranks.indexOf(sq[1]) > forkRankIndex
        );
    } else {
        // Find squares on the same rank (forkRank) and ensure they are opposite to forkSquare
        potentialKingSquares = validSquares.filter(sq =>
            sq[1] === forkRank && files.indexOf(sq[0]) < forkFileIndex - 1 && !adjacentSquares.includes(sq)
        );
        potentialOtherPieceSquares = validSquares.filter(sq =>
            sq[1] === forkRank && files.indexOf(sq[0]) > forkFileIndex
        );
    }

    // Ensure we have at least one valid pair of squares
    if (potentialKingSquares.length === 0 || potentialOtherPieceSquares.length === 0) {
        generateRookPuzzle(); // Retry if no valid configuration is found
        return;
    }

    // Randomly select squares for king and other piece from opposite sides
    const kingSquare = potentialKingSquares[Math.floor(Math.random() * potentialKingSquares.length)];
    const otherPieceSquare = potentialOtherPieceSquares[Math.floor(Math.random() * potentialOtherPieceSquares.length)];
    const otherPieceType = Math.random() < 0.5 ? 'n' : 'b'; // Randomly select knight or bishop

    // Select a square for the rook that is perpendicular to the fork square
    const rookSquares = validSquares.filter(sq => {
        if (isFileFork) {
            return sq[1] === forkRank && sq !== forkSquare;
        } else {
            return sq[0] === forkFile && sq !== forkSquare;
        }
    });
    const rookSquare = rookSquares[Math.floor(Math.random() * rookSquares.length)];

    // Set the positions
    setKingSquare(kingSquare);
    setOtherPieceSquare(otherPieceSquare);
    setRookSquare(rookSquare);
    setAnswerSquare('');
    setSquareStyles({});

    // Set up the board with the pieces
    const newGame = new Chess();
    newGame.clear();
    newGame.put({ type: 'k', color: 'b' }, kingSquare); // Black King
    newGame.put({ type: otherPieceType, color: 'b' }, otherPieceSquare); // Black Knight or Bishop
    newGame.put({ type: 'r', color: 'w' }, rookSquare); // White Rook
    setGame(newGame);
};

const generateQueenPuzzle = async () => {
    try {
        // Fetch the puzzles JSON file
        const response = await fetch('/queen_puzzles.json');
        const puzzles = await response.json();

        // Choose a random puzzle from the array
        const randomIndex = Math.floor(Math.random() * puzzles.length);
        const puzzle = puzzles[randomIndex];

        // Extract piece positions and types from the puzzle
        const { queen_square, king_square, other_piece_square, fork_square, other_piece_type } = puzzle;
        const otherPieceTypeCode = other_piece_type === 'knight' ? 'n'
                                  : other_piece_type === 'rook' ? 'r'
                                  : 'b'; // Choose appropriate piece type (knight, rook, or bishop)

        // Update state with puzzle squares
        setForkSquare(fork_square);
        setQueenSquare(queen_square);
        setKingSquare(king_square);
        setOtherPieceSquare(other_piece_square);
        setAnswerSquare('');
        setSquareStyles({});

        // Initialize the Chess instance with puzzle pieces
        const newGame = new Chess();
        newGame.clear();

        // Place pieces on the board as per the puzzle's configuration
        newGame.put({ type: 'q', color: 'w' }, queen_square); // White Queen
        newGame.put({ type: 'k', color: 'b' }, king_square);  // Black King
        newGame.put({ type: otherPieceTypeCode, color: 'b' }, other_piece_square); // Black Knight or Rook

        // Set the game state with the new configuration
        setGame(newGame);
    } catch (error) {
        console.error("Error loading or parsing queen puzzle JSON:", error);
    }
};

const generateKingPuzzle = () => {
    const files = 'abcdefgh';
    const ranks = '12345678';

    // Select a random forking square
    const validSquares = [...Array(64).keys()]
        .map(i => {
            const file = files[i % 8];
            const rank = ranks[Math.floor(i / 8)];
            return `${file}${rank}`;
        });
    const forkSquare = validSquares[Math.floor(Math.random() * validSquares.length)];
    setForkSquare(forkSquare);

    const forkFileIndex = files.indexOf(forkSquare[0]);
    const forkRankIndex = ranks.indexOf(forkSquare[1]);

    // Get adjacent squares for potential placements of the King and other pieces
    const adjacentSquares = [];
    const potentialOffsets = [
        [-1, -1], [0, -1], [1, -1], // Above
        [-1, 0],           [1, 0],  // Left and Right
        [-1, 1],  [0, 1],  [1, 1]   // Below
    ];
    potentialOffsets.forEach(offset => {
        const file = files[forkFileIndex + offset[0]];
        const rank = ranks[forkRankIndex + offset[1]];
        if (file && rank) {
            adjacentSquares.push(`${file}${rank}`);
        }
    });

    // Select random adjacent squares for the Black pieces
    const otherPieceSquares = [...adjacentSquares];
    const knightSquare = otherPieceSquares.splice(Math.floor(Math.random() * otherPieceSquares.length), 1)[0];
    const otherPieceSquare = otherPieceSquares.splice(Math.floor(Math.random() * otherPieceSquares.length), 1)[0];
    const otherPieceType = Math.random() < 0.5 ? 'n' : 'b'; // Randomly select knight or bishop

    // Place the White King on a remaining adjacent square
    const kingSquare = otherPieceSquares.splice(Math.floor(Math.random() * otherPieceSquares.length), 1)[0];
    setKingSquare(kingSquare);
    setOtherPieceSquare(otherPieceSquare);

    // Ensure the King is not adjacent to any Black pieces in the starting position
    const getAdjacentSquares = (square) => {
        const fileIndex = files.indexOf(square[0]);
        const rankIndex = parseInt(square[1], 10) - 1;
        const adjSquares = [];

        potentialOffsets.forEach(offset => {
            const adjFile = files[fileIndex + offset[0]];
            const adjRank = ranks[rankIndex + offset[1]];
            if (adjFile && adjRank) {
                adjSquares.push(`${adjFile}${adjRank}`);
            }
        });

        return adjSquares;
    };

    const kingAdjacentSquares = getAdjacentSquares(kingSquare);
    if (kingAdjacentSquares.includes(knightSquare) || kingAdjacentSquares.includes(otherPieceSquare)) {
        // Retry if the King is adjacent to any Black piece
        generateKingPuzzle();
        return;
    }

    // Set up the board and initialize the game
    const newGame = new Chess();
    newGame.clear();
    newGame.put({ type: 'k', color: 'w' }, kingSquare); // White King
    newGame.put({ type: 'n', color: 'b' }, knightSquare); // Black Knight
    newGame.put({ type: otherPieceType, color: 'b' }, otherPieceSquare); // Black Knight or Bishop

    // Calculate reachable squares for each black piece manually
    const getKnightMoves = (square) => {
        const file = square[0];
        const rank = parseInt(square[1], 10);
        const knightOffsets = [
            [2, 1], [2, -1], [-2, 1], [-2, -1],
            [1, 2], [1, -2], [-1, 2], [-1, -2]
        ];

        return knightOffsets
            .map(([df, dr]) => `${files[files.indexOf(file) + df]}${rank + dr}`)
            .filter(move => /^[a-h][1-8]$/.test(move)); // Ensure the move is within bounds
    };

    const getBishopMoves = (square) => {
        const fileIndex = files.indexOf(square[0]);
        const rankIndex = parseInt(square[1], 10) - 1;
        const directions = [
            [1, 1], [1, -1], [-1, 1], [-1, -1]
        ];

        const moves = [];
        directions.forEach(([df, dr]) => {
            let f = fileIndex + df;
            let r = rankIndex + dr;

            while (f >= 0 && f < 8 && r >= 0 && r < 8) {
                moves.push(`${files[f]}${r + 1}`);
                f += df;
                r += dr;
            }
        });

        return moves;
    };

    const knightMoves = getKnightMoves(knightSquare);
    const otherPieceMoves = otherPieceType === 'n' ? getKnightMoves(otherPieceSquare) : getBishopMoves(otherPieceSquare);

    console.log(`Knight at ${knightSquare} can move to:`, knightMoves);
    console.log(`Other piece (${otherPieceType === 'n' ? 'Knight' : 'Bishop'}) at ${otherPieceSquare} can move to:`, otherPieceMoves);

    // Only check for common moves if both pieces are knights
    if (otherPieceType === 'n') {
        const commonMoves = knightMoves.filter(move => otherPieceMoves.includes(move));
        if (commonMoves.length > 0) {
            console.log(`Overlap detected: Both pieces can move to these squares: ${commonMoves.join(', ')}. Regenerating puzzle.`);
            generateKingPuzzle();
            return;
        }
    }

    // Check that Black pieces don't attack the King on the starting or forking square
    if (newGame.isAttacked(kingSquare, 'b') || newGame.isAttacked(forkSquare, 'b')) {
        console.log("King is under attack. Regenerating puzzle.");
        generateKingPuzzle();
        return;
    }

    // Set up the board state
    setAnswerSquare('');
    setSquareStyles({});
    setGame(newGame);
};


const generatePawnPuzzle = () => {
    const files = 'abcdefgh';
    const ranks = '12345678';

    // Filter valid squares for the forking square, excluding the first, second, and last rank
    const validForkRanks = ranks.slice(2, 7).split(''); // Convert the sliced string to an array
    const validForkSquares = [];

    validForkRanks.forEach(rank => {
        files.split('').forEach(file => {
            validForkSquares.push(`${file}${rank}`);
        });
    });

    // Select a random forking square from the valid options
    const forkSquare = validForkSquares[Math.floor(Math.random() * validForkSquares.length)];
    setForkSquare(forkSquare);

    const forkFile = forkSquare[0];
    const forkRank = forkSquare[1];
    const forkFileIndex = files.indexOf(forkFile);
    const forkRankIndex = ranks.indexOf(forkRank);

    // Determine the pawn's position with special handling if the fork square is on the fourth rank
    let pawnSquare;
    if (forkRank === '4' && Math.random() < 0.5) {
        // 50% chance to place the pawn two squares below the fork square if on the fourth rank
        pawnSquare = `${forkFile}${ranks[forkRankIndex - 2]}`;
    } else {
        // Place the pawn one square below the forking square
        pawnSquare = `${forkFile}${ranks[forkRankIndex - 1]}`;
    }
    setPawnSquare(pawnSquare);

    // Calculate potential squares for the Black pieces
    const potentialBlackSquares = [
        `${files[forkFileIndex - 1]}${ranks[forkRankIndex + 1]}`, // Top left of fork square
        `${files[forkFileIndex + 1]}${ranks[forkRankIndex + 1]}`  // Top right of fork square
    ];

    // Ensure the squares are within board boundaries
    const blackPieceSquares = potentialBlackSquares.filter(square => {
        const file = square[0];
        const rank = square[1];
        return files.includes(file) && ranks.includes(rank);
    });

    // Place two Black pieces on the top-left and top-right squares relative to the forking square
    if (blackPieceSquares.length !== 2) {
        generatePawnPuzzle();
        return;
    }

    const [firstBlackSquare, secondBlackSquare] = blackPieceSquares;
    const firstPieceType = Math.random() < 0.5 ? 'n' : 'r'; // Randomly select knight or rook
    const secondPieceType = Math.random() < 0.5 ? 'n' : 'r'; // Randomly select knight or rook

    // Set the positions on the board
    setGame(null);
    setForkSquare(forkSquare);
    setPawnSquare(pawnSquare);
    setSquareStyles({});

    // Set up the board with the pieces
    const newGame = new Chess();
    newGame.clear();
    newGame.put({ type: 'p', color: 'w' }, pawnSquare); // White Pawn
    newGame.put({ type: firstPieceType, color: 'b' }, firstBlackSquare); // Black Knight or Rook
    newGame.put({ type: secondPieceType, color: 'b' }, secondBlackSquare); // Black Knight or Rook

    // Ensure the Black pieces are on squares of the same rank and have space between them horizontally
    if (firstBlackSquare[1] !== secondBlackSquare[1] || Math.abs(files.indexOf(firstBlackSquare[0]) - files.indexOf(secondBlackSquare[0])) < 2) {
        generatePawnPuzzle();
        return;
    }

    setGame(newGame);
};

// Define probabilities and names for each puzzle type
const puzzleGenerators = [
    { func: generateKnightPuzzle, probability: 0.3, name: "Knight Puzzle" },
    { func: generateBishopPuzzle, probability: 0.1, name: "Bishop Puzzle" },
    { func: generateRookPuzzle, probability: 0.05, name: "Rook Puzzle" },
    { func: generateQueenPuzzle, probability: 0.35, name: "Queen Puzzle" },
    { func: generateKingPuzzle, probability: 0.05, name: "King Puzzle" },
    { func: generatePawnPuzzle, probability: 0.05, name: "Pawn Puzzle" },
];

//const puzzleGenerators = [
//    { func: generateKingPuzzle, probability: 1, name: "Queen Puzzle" },
//];

// Ensure probabilities sum to 1 by adding a fallback for any remaining probability
const totalProbability = puzzleGenerators.reduce((sum, { probability }) => sum + probability, 0);
if (totalProbability < 1) {
    puzzleGenerators.push({ func: generateKnightPuzzle, probability: 1 - totalProbability, name: "Default Knight Puzzle" });
}

// Function to randomly choose a puzzle generator based on set probabilities
const generatePuzzle = () => {
    const random = Math.random();
    let cumulativeProbability = 0;

    for (const { func, probability, name } of puzzleGenerators) {
        cumulativeProbability += probability;
        if (random < cumulativeProbability) {
            console.log(`Puzzle chosen: ${name}`);  // Log the puzzle type
            func();
            return;
        }
    }

    // Fallback log in case no puzzle was generated (shouldn't occur with the fallback)
    console.error("No puzzle was generated despite calling generatePuzzle.");
};


    // Handle square selection (user clicking on the board)
    const handleSquareClick = async (square) => {
        if (square === forkSquare) {
            await flashBoardGreen(); // Flash green on correct
            if (isGameActive) {
                setScore(prevScore => prevScore + 1); // Increment score only if in game mode
            }
            setTimeout(generatePuzzle, 50); // Generate a new puzzle
        } else {
            await flashBoardRed(); // Flash red on incorrect
        }
        setAnswerSquare(square); // Highlight the clicked square
    };

    // Flash the board green for correct answers
    const flashBoardGreen = async () => {
        const flashStyle = {
            ...generateFlashStyle("rgba(0, 255, 0, 0.5)"), // Green flash
        };

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 200)); // Flash duration
        setSquareStyles({});
    };

    // Flash the board red for incorrect answers
    const flashBoardRed = async () => {
        const flashStyle = {
            ...generateFlashStyle("rgba(255, 0, 0, 0.5)"), // Red flash
        };

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 200)); // Flash duration
        setSquareStyles({});
    };

    // Generate flash style for all squares
    const generateFlashStyle = (color) => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }
        return allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: color };
            return styles;
        }, {});
    };

    // Start a new game
    const startGame = () => {
        setScore(0);
        setTimeLeft(60); // Reset the timer
        setIsGameActive(true); // Activate game mode
        generatePuzzle(); // Start with a new puzzle
    };

    // End the game and check if the score is a new record
    const endGame = async () => {
        setIsGameActive(false);
        if (score > bestScore) {
            setBestScore(score);
            setGameOverMessage(`New record! Your new best score is ${score}.`);
            if (user) {
                const userRef = doc(db, "users", user.uid);
                await setDoc(userRef, { bestFindForkScore: score }, { merge: true });
            }
        } else if (score === bestScore) {
            setGameOverMessage(`You tied your best score of ${bestScore}!`);
        } else {
            setGameOverMessage(`Your score was ${score}. Best score: ${bestScore}. Keep trying!`);
        }

        await saveScoreToLeaderboard(score); // Save the score to the leaderboard
        setIsModalOpen(true); // Open modal when game ends
    };

    // Timer countdown logic
    useEffect(() => {
        if (isGameActive && timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (isGameActive && timeLeft === 0) {
            endGame(); // End the game when the timer reaches zero
        }
    }, [timeLeft, isGameActive]);

    // Load the user's best score from Firebase
    useEffect(() => {
        const fetchBestScore = async () => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setBestScore(userDoc.data().bestFindForkScore || 0);
                } else {
                    setBestScore(0);
                }
            }
        };

        fetchBestScore();
    }, [user]);

    // Set up the chessboard and generate the first puzzle
    useEffect(() => {
        generatePuzzle(); // Generate first puzzle on load
    }, []);

    const handleCountdownComplete = () => {
        setShowCountdown(false);
        startGame(); // Start the game after countdown
    };

    return (
        <div>
            {showLoginMessage && (
                <div className="login-message">
                    <button className="close-button" onClick={handleCloseMessage}>×</button>
                    <p><a href="/login">Log in</a> to participate in the leaderboard and save your scores.</p>
                </div>
            )}
            <JoyrideWrapper
                steps={findForkRide}
                run={isTourOpen}
                onFinish={() => setIsTourOpen(false)} // Close tour when finished
            />
        <div className="game-layout">
          {isGameActive && (
            <div className="chess-clock">
              <div className="time-display">
                <span className="clock-value">{timeLeft !== null ? `${timeLeft}` : '∞'}</span>
              </div>
            </div>
          )}
            <div className="chessboard-container">
                <ChessboardCustom
                    position={game.fen()}
                    onSquareClick={handleSquareClick}
                    squareStyles={squareStyles} // Apply square styles for flash effects
                    degreeOfBlindness="Normal"
                />
            </div>
            <div className='move-history-container'>
            <div className="feedback-container">
                <button onClick={() => setIsTourOpen(true)} className="help-link">
                    Help
                </button>
                <p style={{ marginTop: '30px' }}>
                    {isGameActive ? `Time Left: ${timeLeft}s` : `Your Best Score: ${bestScore || 'N/A'}`}
                </p>
                {!isGameActive && !showCountdown && (
                    <button onClick={() => setShowCountdown(true)} className="puzzle-button">
                        Start Game
                    </button>
                )}
                {/* Conditionally render the leaderboard based on the current view */}
                <Leaderboard
                    leaderboardType={leaderboardView}
                    toggleLeaderboardView={toggleLeaderboardView}
                    pathBase="leaderboard/findthefork"
                />
            </div>
            </div>
        </div>
            {showCountdown && <Countdown onCountdownComplete={handleCountdownComplete} />}
            {isModalOpen && (
                <Modal
                    message={gameOverMessage}
                    onClose={closeModal} // Pass closeModal function as onClose handler
                />
            )}
        </div>
    );
}

export default FindFork;
